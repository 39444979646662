import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { FlatList, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'

import InputContainer from '../components/InputContainer'
import InputDateTime from '../components/InputDateTime'
import PressableTextIcon from '../components/PressableTextIcon'
import Screen from '../components/Screen'
import type { Collaborator, Department } from '../types'

export type Filter = {
  departments: Department[]
  collaborators: Collaborator[]
  dateFrom: Date | undefined
}

function defaultDay(date: Date, days: number) {
  const result = new Date(date)
  result.setDate(result.getDate() - days)
  return result
}

export default function FilterModal({
  visible,
  onClose,
  filter,
  filterOptions,
  onFilterChange,
  isHistory,
}: {
  visible: boolean
  onClose: () => void
  filter: Filter
  filterOptions: Filter
  onFilterChange: (filters: Filter) => void
  isHistory: boolean
}) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const [internalFilter, setInternalFilter] = useState(filter)

  const showClearFilter = useMemo(() => {
    return internalFilter.collaborators.length > 0 || internalFilter.departments.length > 0 || internalFilter.dateFrom !== undefined
  }, [internalFilter, visible])

  return (
    // @ts-ignore fix
    <IM.ModalBottom
      isVisible={visible}
      style={styles.modalBottom}
      swipeDirection={[]}
      propagateSwipe
      close={() => {
        onClose()
      }}
      children={
        <>
          <View style={[styles.container, { backgroundColor: theme.primary }]}>
            <IM.Text style={styles.text}>{i18n.t('FILTER')}</IM.Text>

            <View style={styles.row}>
              <IM.PressableIcon onPress={() => onClose()} size={20} color="#fff" icon={['fal', 'x']} style={styles.center} />
              <IM.PressableIcon
                onPress={() => {
                  onFilterChange(internalFilter)
                  onClose()
                }}
                size={23}
                color="#fff"
                icon={['fal', 'check']}
                style={styles.center}
              />
            </View>
          </View>
          <Screen hasRoundCorners>
            <IM.View spacing={'all'}>
              <IM.View spacing={'all'}>
                <ScrollView>
                  {showClearFilter && (
                    <PressableTextIcon
                      style={[
                        styles.padding,
                        {
                          backgroundColor: theme.primary,
                          borderRadius: IMLayout.borderRadius,
                        },
                      ]}
                      leftText={i18n.t('CLEAR')}
                      icon={['fal', 'filter-circle-xmark']}
                      size={24}
                      onPress={() => {
                        setInternalFilter({
                          departments: [],
                          collaborators: [],
                          dateFrom: undefined,
                        })
                        onFilterChange({
                          departments: [],
                          collaborators: [],
                          dateFrom: undefined,
                        })
                        onClose()
                      }}
                      textStyle={styles.textWhite}
                      color="#fff"
                    />
                  )}

                  {isHistory && (
                    <>
                      <IM.View spacing={'all'} />
                      <IM.Text>{i18n.t('DATE_FROM')}</IM.Text>
                      <InputContainer style={styles.marginBottom}>
                        <InputDateTime
                          mode="date"
                          value={internalFilter.dateFrom === undefined ? defaultDay(new Date(), 14) : internalFilter.dateFrom}
                          onConfirm={date =>
                            setInternalFilter({
                              ...internalFilter,
                              dateFrom: date,
                            })
                          }
                          onDelete={() =>
                            setInternalFilter({
                              ...internalFilter,
                              dateFrom: undefined,
                            })
                          }
                        />
                      </InputContainer>
                    </>
                  )}

                  <IM.Text>{i18n.t('DEPARTMENTS')}</IM.Text>
                  <FlatList
                    data={filterOptions.departments}
                    contentContainerStyle={styles.flatlist}
                    renderItem={({ item }) => (
                      <TouchableOpacity
                        onPress={() => {
                          const index = internalFilter.departments.findIndex(e => e.id === item.id)
                          if (index === -1) {
                            internalFilter.departments.push(item)
                          } else {
                            internalFilter.departments.splice(index, 1)
                          }
                          setInternalFilter({
                            ...internalFilter,
                            departments: internalFilter.departments,
                          })
                        }}>
                        <IM.View
                          style={[
                            styles.filterContainer,
                            {
                              backgroundColor: internalFilter.departments.find(e => e.id === item.id) ? theme.secondary : theme.background,
                              borderColor: theme.primary,
                            },
                          ]}>
                          <IM.View style={styles.filterWrapper}>
                            <IM.Text
                              style={[
                                styles.paddingLeftStyle,
                                // eslint-disable-next-line react-native/no-inline-styles
                                {
                                  color: internalFilter.departments.find(e => e.id === item.id) ? '#fff' : theme.textDetail,
                                },
                              ]}>
                              {item.name}
                            </IM.Text>
                            <IM.View style={styles.emptyView} />
                          </IM.View>
                        </IM.View>
                      </TouchableOpacity>
                    )}
                  />
                  <IM.View spacing={'all'} />
                  <IM.Text>{i18n.t('COLLABORATORS')}</IM.Text>
                  <FlatList
                    // horizontal
                    data={filterOptions.collaborators}
                    contentContainerStyle={styles.flatlist}
                    renderItem={({ item }) => (
                      <TouchableOpacity
                        onPress={() => {
                          const index = internalFilter.collaborators.findIndex(e => e.id === item.id)
                          if (index === -1) {
                            internalFilter.collaborators.push(item)
                          } else {
                            internalFilter.collaborators.splice(index, 1)
                          }
                          setInternalFilter({
                            ...internalFilter,
                            collaborators: internalFilter.collaborators,
                          })
                        }}>
                        <IM.View
                          style={[
                            styles.filterContainer,
                            {
                              backgroundColor: internalFilter.departments.find(e => e.id === item.id) ? theme.secondary : theme.background,
                              borderColor: theme.primary,
                            },
                          ]}>
                          <IM.View style={styles.filterWrapper}>
                            <IM.Text
                              style={[
                                styles.paddingLeftStyle,
                                // eslint-disable-next-line react-native/no-inline-styles
                                {
                                  color: internalFilter.collaborators.find(e => e.id === item.id) ? '#fff' : theme.textDetail,
                                },
                              ]}>
                              {item.name}
                            </IM.Text>
                            <IM.View style={styles.emptyView} />
                          </IM.View>
                        </IM.View>
                      </TouchableOpacity>
                    )}
                  />
                </ScrollView>
              </IM.View>
            </IM.View>
          </Screen>
        </>
      }
    />
  )
}

const styles = StyleSheet.create({
  modalBottom: { padding: 0, height: '80%' },
  container: {
    height: 50,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    padding: 10,
    flexDirection: 'row',
    paddingHorizontal: 20,
    justifyContent: 'space-between',
  },
  text: { color: '#fff', fontSize: 20, alignSelf: 'center' },
  row: { flexDirection: 'row' },
  center: { alignSelf: 'center' },
  flatlist: {
    flexWrap: 'wrap',
  },
  filterContainer: {
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
    marginRight: 5,
    flexDirection: 'row',
    marginTop: 5,
  },
  filterWrapper: { padding: 3, paddingLeft: 6, flexDirection: 'row' },
  emptyView: { width: 8 },
  paddingLeftStyle: { paddingLeft: 4 },
  padding: { padding: 5 },
  textWhite: { color: '#fff' },
  marginBottom: { marginBottom: 10 },
})
