import type { Filter } from '../modals/FilterModal'
import type { Collaborator, Department, Task } from '../types'
import type { TaskListItem } from '../views/TasksList'
import DateUtils from './DateUtils'

const FilterUtils = {
  filterData(filter: Filter, data: TaskListItem[]) {
    // dont filter data if no filters are active
    if (filter?.departments.length === 0 && filter?.collaborators.length === 0 && filter?.dateFrom === undefined) {
      return data
    }
    // filter data based on filters
    else {
      const filteredTasksLists: TaskListItem[] = []
      const copy = JSON.parse(JSON.stringify(data)) as TaskListItem[]

      const depIds = filter?.departments?.map(d => d.id)
      const collabIds = filter?.collaborators?.map(d => d.id)

      // filter for every task list
      for (const tasklist of copy) {
        let newTasks: Task[] = []

        // filter for departments
        if (filter?.departments.length > 0) {
          newTasks = newTasks.concat(tasklist.data.filter(t => t.departments.filter(d => depIds.includes(d.id)).length > 0))
        }

        // filter for collaborators
        if (filter?.collaborators.length > 0) {
          newTasks = newTasks.concat(tasklist.data.filter(t => t.collaborators.filter(c => collabIds.includes(c.id)).length > 0))
        }

        // filter for dateFrom
        if (filter?.dateFrom) {
          if (newTasks.length === 0) {
            newTasks = JSON.parse(JSON.stringify(tasklist.data)) as Task[]
          }

          newTasks = newTasks.filter(t => {
            if (filter?.dateFrom) {
              // convert date to same UTC date and filter
              const date = new Date(t.closedDate)
              return DateUtils.dateToUTCFormat(date) >= DateUtils.dateToUTCFormat(filter?.dateFrom)
            } else {
              return true
            }
          })
        }
        // add filtered tasklist to filteredTasksLists
        if (newTasks.length > 0) {
          filteredTasksLists.push({
            title: tasklist.title,
            data: newTasks,
          })
        }
      }
      return filteredTasksLists
    }
  },

  findFilterOptions(allTasks: Task[]) {
    const departments: Department[] = []
    allTasks.forEach(item => {
      item.departments.forEach(dep => {
        const temp = departments.find(e => e.code === dep.code)
        if (!temp) departments.push(dep)
      })
    })

    const collaborators: Collaborator[] = []
    allTasks.forEach(item => {
      item.collaborators.forEach(col => {
        const temp = collaborators.find(e => e.code === col.code)
        if (!temp) collaborators.push(col)
      })
    })

    return { departments: departments, collaborators: collaborators, dateFrom: undefined }
  },
}

export default FilterUtils
