import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { FlatList, Keyboard, StyleSheet, View } from 'react-native'

import MultiSelectInput, { removeItemOnce } from './MultiSelectInput'
import Screen from './Screen'

interface SelectInputType<T> {
  value?: T
  values?: T[]
  onChangeValues?: (values: T[]) => void
  onChangeValue?: (value: T) => void
  data: T[] | undefined
  title: string
  icon: IconProp
  renderString: (item: T) => string
  keyExtractor: (item: T) => string
  readonly?: boolean
}

export default function SelectInput<T>({
  value,
  values,
  onChangeValues,
  onChangeValue,
  data,
  title,
  icon,
  renderString,
  keyExtractor,
  readonly,
}: SelectInputType<T>) {
  const [showModal, setShowModal] = useState(false)

  const { theme } = useTheme()

  return (
    <>
      <MultiSelectInput
        readonly={readonly}
        value={value}
        values={values}
        onChangeValues={newValues => onChangeValues && onChangeValues(newValues)}
        onChangeValue={item => {
          onChangeValue && onChangeValue(item)
          setShowModal(false)
        }}
        onPressIn={() => {
          Keyboard.dismiss()
          setShowModal(true)
        }}
        renderString={item => renderString(item)}
        keyExtractor={item => keyExtractor(item)}
      />
      {!readonly && (
        // @ts-ignore fix
        <IM.ModalBottom
          isVisible={showModal}
          style={styles.modalBottom}
          swipeDirection={[]}
          propagateSwipe
          close={() => {
            setShowModal(false)
          }}
          children={
            <>
              <View style={[styles.container, { backgroundColor: theme.primary }]}>
                <IM.Text style={styles.text}>{title}</IM.Text>
                <View style={styles.row}>
                  <IM.PressableIcon onPress={() => setShowModal(false)} size={23} color="#fff" icon={['fal', 'check']} style={styles.center} />
                </View>
              </View>
              <Screen hasRoundCorners>
                <FlatList
                  style={styles.flatlist}
                  renderItem={({ item }) => (
                    <IM.Card
                      key={keyExtractor(item)}
                      spacing={'bottom'}
                      //   head={{  icon: ['fal', 'hat-wizard'],  backGroundColor: themeUtils.getRandomColorFromTheme(item.name) }}
                      // @ts-ignore ignore
                      head={{ icon: icon, backGroundColor: theme.secondary }}
                      defaultContent={{
                        texts: [{ text: renderString(item), primary: true }],
                      }}
                      selector={values?.map(element => renderString(element)).includes(renderString(item)) ? { icon: ['fal', 'check'] } : undefined}
                      // buttons={{ icon: ['fal', 'star'] }}
                      // selector={selector}
                      onPress={() => {
                        if (values?.map(renderString).includes(renderString(item))) {
                          const selected = removeItemOnce(renderString, values, item)
                          if (onChangeValues !== undefined) {
                            onChangeValues([...selected])
                          } else if (onChangeValue !== undefined) {
                            onChangeValue(item)
                            setShowModal(false)
                          }
                        } else {
                          if (values !== undefined) {
                            if (onChangeValues !== undefined) {
                              onChangeValues([...values, item])
                            } else if (onChangeValue !== undefined) {
                              onChangeValue(item)
                              setShowModal(false)
                            }
                          } else {
                            if (onChangeValues !== undefined) {
                              onChangeValues([item])
                            } else if (onChangeValue !== undefined) {
                              onChangeValue(item)
                              setShowModal(false)
                            }
                          }
                        }
                      }}
                    />
                  )}
                  data={data ?? []}
                />
              </Screen>
            </>
          }
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  modalBottom: { padding: 0, height: '80%' },
  container: {
    height: 50,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    padding: 10,
    flexDirection: 'row',
    paddingHorizontal: 20,
    justifyContent: 'space-between',
  },
  text: { color: '#fff', fontSize: 20, alignSelf: 'center' },
  row: { flexDirection: 'row' },
  center: { alignSelf: 'center' },
  flatlist: { paddingHorizontal: 10, marginTop: 10 },
})
