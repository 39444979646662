import InfomindsAnalytics from '@infominds/react-native-analytics'
import { IM, ThemeType } from '@infominds/react-native-components'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { LogBox, Platform, StatusBar, StyleSheet, View } from 'react-native'
import BootSplash from 'react-native-bootsplash'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { ToastProvider } from 'react-native-toast-notifications'

import './InitStructures'

import api from './apis/apiCalls'
import { languageResources } from './assets/languages/LanguageResources'
import { DefaultColors, FamiliamusColors } from './constants/Colors'
import CustomTheme from './constants/CustomTheme'
import { firebaseConfig } from './constants/FirebaseConfigWeb'
import { AnimationProvider } from './contexts/AnimationContext'
import { TenantContext } from './contexts/TenantContext'
import { UserContext } from './contexts/UserContext'
import Navigator from './navigation/Navigator'
import type { Tenant, ThemeColorExpanded } from './types'
import ColorUtils from './utils/ColorUtils'

type Token = { token: string; setToken: Dispatch<SetStateAction<string>> }
export const ApiTokenContext = React.createContext<Token>({
  token: '',
  setToken: function (): void {
    throw new Error('Function not implemented.')
  },
})

export default function App() {
  const [apiToken, setApiToken] = useState<string>('')
  const [theme, setTheme] = useState<ThemeType<ThemeColorExpanded>>(CustomTheme)
  const [user, setUser] = useState()
  const [isAdmin, setIsAdmin] = useState(false)
  const [tenant, setTenant] = useState<Tenant | undefined>()

  useEffect(() => {
    InfomindsAnalytics.init(firebaseConfig)

    if (Platform.OS !== 'web') {
      LogBox.ignoreAllLogs()
      BootSplash.hide().catch(console.error)
    }
  }, [])

  const apiTokenValue = useMemo(() => {
    return { token: apiToken, setToken: setApiToken }
  }, [apiToken])

  useEffect(() => {
    if (apiToken) {
      api
        .getTenants()
        .then(res => {
          if (res && res.length > 0 && res[0] && res[0].color) {
            setTenant(res[0])
            const primaryColor = res[0].color.primary || FamiliamusColors.primary
            const secondaryColor = res[0].color.secondary || FamiliamusColors.secondary

            //deep copy custom theme
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const tmpTheme: ThemeType<ThemeColorExpanded> = JSON.parse(JSON.stringify(CustomTheme))

            tmpTheme.light.primary = primaryColor
            tmpTheme.light.secondary = secondaryColor
            tmpTheme.light.tertiary = secondaryColor
            tmpTheme.light.card.highlight = primaryColor
            tmpTheme.light.skeleton.highlight = primaryColor
            tmpTheme.light.chip.highlightedBackground = primaryColor
            tmpTheme.light.switch.trackColor.true = primaryColor
            tmpTheme.light.checkbox.tint = primaryColor
            tmpTheme.light.tabNavigator.focused.icon = Platform.OS === 'web' ? primaryColor : '#ffffff'
            tmpTheme.light.tabNavigator.focused.text = '#ffffff'
            tmpTheme.light.tabNavigator.focused.marker = primaryColor
            tmpTheme.light.tabNavigator.focused.background = primaryColor
            tmpTheme.light.tabNavigator.focused.iconBackground = ColorUtils.brightenColor(primaryColor, 0.5)

            tmpTheme.dark.primary = primaryColor
            tmpTheme.dark.secondary = secondaryColor
            tmpTheme.dark.tertiary = secondaryColor
            tmpTheme.dark.card.highlight = primaryColor
            tmpTheme.dark.skeleton.highlight = primaryColor
            tmpTheme.dark.chip.highlightedBackground = primaryColor
            tmpTheme.dark.switch.trackColor.true = secondaryColor
            tmpTheme.dark.checkbox.tint = primaryColor
            tmpTheme.dark.tabNavigator.focused.icon = Platform.OS === 'web' ? primaryColor : '#ffffff'
            tmpTheme.dark.tabNavigator.focused.text = '#ffffff'
            tmpTheme.dark.tabNavigator.focused.marker = primaryColor
            tmpTheme.dark.tabNavigator.focused.background = primaryColor
            tmpTheme.dark.tabNavigator.focused.iconBackground = ColorUtils.brightenColor(primaryColor, 0.5)

            setTheme({ ...tmpTheme })
          }
        })
        .catch(error => {
          console.error('tenants error', error)
        })
    }
  }, [apiToken])

  useEffect(() => {
    if (tenant === undefined && user === undefined) {
      setTheme({ ...CustomTheme })
    }
  }, [tenant, user])

  return (
    <AnimationProvider>
      <ApiTokenContext.Provider value={apiTokenValue}>
        <IM.ThemeProvider theme={theme}>
          <IM.LanguageProvider resources={languageResources}>
            <TenantContext.Provider value={{ tenant: tenant, setTenant: setTenant }}>
              <UserContext.Provider value={{ user: user, setUser: setUser, isAdmin: isAdmin, setIsAdmin: setIsAdmin }}>
                <SafeAreaProvider>
                  <ToastProvider>
                    <IM.AlertProvider>
                      <View style={[styles.screen]}>
                        {/* {Platform.OS !== 'web' && <OfflineNotice text={langContext.i18n.t('NO_INTERNET')} />} */}
                        <StatusBar translucent backgroundColor={tenant?.color?.primary ?? DefaultColors.statusBar} barStyle="light-content" />
                        <Navigator />
                      </View>
                    </IM.AlertProvider>
                  </ToastProvider>
                </SafeAreaProvider>
              </UserContext.Provider>
            </TenantContext.Provider>
          </IM.LanguageProvider>
        </IM.ThemeProvider>
      </ApiTokenContext.Provider>
    </AnimationProvider>
  )
}

const styles = StyleSheet.create({
  screen: {
    height: '100%',
    justifyContent: 'center',
  },
})
