import React from 'react'
import { StyleSheet } from 'react-native'

import ButtonBadge from './ButtonBadge'

export type NewTimeButtonProps = {
  visible: boolean
  onPress: () => void
}

export default function NewButton({ visible, onPress }: NewTimeButtonProps) {
  if (!visible) return <></>
  return (
    <ButtonBadge icon={['fal', 'plus']} size={50} iconSize={30} spacing={'all'} badgeStyle={styles.badge} style={styles.button} onPress={onPress} />
  )
}

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 10,
  },
  badge: {
    borderRadius: 100,
  },
})
