import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import type { MenuItem } from '../views/ManagementMenu'

export default function MenuCard({ item, onPress }: { item: MenuItem; onPress: () => void }) {
  const { themeUtils } = useTheme()

  return (
    <IM.Card
      spacing={'bottom'}
      head={{
        icon: item.icon ?? ['fal', 'hat-wizard'],
        backGroundColor: themeUtils.getRandomColorFromTheme(item.name),
      }}
      defaultContent={{
        texts: [{ text: item.name, primary: true }],
      }}
      onPress={onPress}
    />
  )
}
