import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import React, { useContext } from 'react'

import { UserContext } from '../../contexts/UserContext'
import type { Task } from '../../types'
import DateUtils from '../../utils/DateUtils'
import TaskHead from './TaskHeadSytles'

export default function TaskCard({ item, onPress }: { item: Task; onPress: () => void }) {
  const { i18n, language } = useLanguage()
  const { user } = useContext(UserContext)

  const blocked = item.blocked === 1 && item.blockedCollaborator !== undefined && item.blockedCollaborator?.id !== user?.collaborator?.id
  const opacity = blocked ? 0.8 : 1

  return (
    <IM.Card
      disabled={blocked}
      spacing={'bottom'}
      head={TaskHead(item.priority)}
      style={{ marginHorizontal: IMStyle.layout.horizontalMargin, opacity: opacity }}
      defaultContent={{
        texts: [
          { text: item.title, primary: true, style: { marginBottom: 10 } },
          !!item.description && { text: item.description, secondary: true, style: { marginBottom: 1 } },
          !!item.room && { text: item.room, secondary: true, icon: ['fal', 'bed'] },
          !!(item.blockedCollaborator && item.blockedDate) && {
            text: `${i18n.t('BLOCKED_BY')} ${item.blockedCollaborator.name} @ ${DateUtils.formatDate(
              new Date(item.blockedDate),
              'dd/MM/yyyy HH:mm'
            )}`,
            secondary: true,
            icon: ['fal', 'user'],
          },

          item.closedDate !== undefined &&
            DateUtils.toString(item.deliveryDate, language) !== '' && {
              text: DateUtils.toString(item.deliveryDate, language),
              secondary: true,
              icon: ['fal', 'calendar-days'],
            },

          !!item.creationDate && {
            text: `${item.creationCollaborator?.name ?? 'ADMIN'} - ${DateUtils.formatDate(new Date(item.creationDate), 'dd/MM/yyyy HH:mm')}`,
            secondary: true,
            icon: ['fal', 'file-circle-plus'],
          },

          !!item.modifiedDate && {
            text: `${item.modifiedCollaborator?.name ?? 'ADMIN'} - ${DateUtils.formatDate(new Date(item.modifiedDate), 'dd/MM/yyyy HH:mm')}`,
            secondary: true,
            icon: ['fal', 'edit'],
          },
        ],
      }}
      selector={item.taskTemplate?.repetition ? { icon: ['fal', 'repeat'] } : undefined}
      onPress={onPress}
    />
  )
}
