import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'

import type { User } from '../types'

export default function UserCard({ item, onPress }: { item: User; onPress: (item: User) => void }) {
  const { themeUtils } = useTheme()

  const selector =
    item.inactive === 0
      ? {
          icon: ['fal', 'check'] as IconProp,
        }
      : undefined

  return (
    <IM.Card
      spacing={'bottom'}
      style={{ marginHorizontal: IMStyle.layout.horizontalMargin }}
      head={{
        icon: ['fal', 'user'],
        backGroundColor: themeUtils.getRandomColorFromTheme(item.username),
      }}
      defaultContent={{
        texts: [
          { text: item.username, primary: true },
          !!item.role && { text: item.role?.description, secondary: true, icon: ['fal', 'user-group-crown'] },
        ],
      }}
      selector={selector}
      onPress={() => onPress(item)}
    />
  )
}
