import React from 'react'

import Header from '../../components/Header'
import Screen from '../../components/Screen'
import ManagementMenu from '../../views/ManagementMenu'

export default function ManagementScreen() {
  return (
    <Screen spacing="none" screenHeader={<Header />} hasRoundCorners>
      <ManagementMenu />
    </Screen>
  )
}
