import { createContext } from 'react'

import type { User } from '../types'

export const UserContext = createContext<{
  user: User | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUser: (user: any) => void
  isAdmin: boolean
  setIsAdmin: (isAdmin: boolean) => void
}>({
  user: undefined,
  setUser: function (): void {
    throw new Error('setUser Function not implemented.')
  },
  isAdmin: false,
  setIsAdmin: function (): void {
    throw new Error('setIsAdmin Function not implemented.')
  },
})
