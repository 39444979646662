import React, { useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { StyleSheet, useWindowDimensions } from 'react-native'
import Modal from 'react-native-modal'

import 'react-day-picker/dist/style.css'

import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { Button, createTheme, DialogActions, Theme, ThemeProvider } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker'
import Color from 'color'
import type { Dispatch, SetStateAction } from 'react'

import TimeUtils from '../utils/TimeUtils'

export interface DateTimePickerProps {
  date: Date
  show: boolean
  mode: 'date' | 'time' | 'datetime'
  minimumDate?: Date
  maximumDate?: Date
  setDate: (newDate: Date) => void
  setShow: Dispatch<SetStateAction<boolean>>
}

export default function DateTimePicker({
  date,
  show,
  mode,
  minimumDate = new Date(1899, 0, 1),
  maximumDate = new Date(2199, 0, 1),
  setDate,
  setShow,
}: DateTimePickerProps) {
  const { i18n, language } = useLanguage()
  const { theme, colorScheme } = useTheme()
  const { width, height } = useWindowDimensions()
  const muiTheme: Theme = createTheme({
    palette: {
      mode: colorScheme,
      primary: { main: IMStyle.palette.tint, contrastText: 'white' },
    },
  })
  const [newDate, setNewDate] = useState<Date | undefined>(date)

  const css = `
    .my-selected:not([disabled]) { 
      background-color: ${IMStyle.palette.tint};
      color: ${IMStyle.palette.white}
    }
    .my-selected:hover:not([disabled]) { 
      background-color: ${IMStyle.palette.tint};
      color: ${IMStyle.palette.white}
  
    }
    .my-selected:hover:not([disabled]):not(.rdp-day_selected) { 
      background-color: ${Color(IMStyle.palette.tint)
        .alpha(colorScheme === 'dark' ? 0.5 : 0.8)
        .toString()};
      color: ${colorScheme === 'dark' ? theme.text : IMStyle.palette.white}
  
    }
   .my-today { 
      color: ${IMStyle.palette.tint};
    }
  `

  const onAccept = () => {
    newDate && setDate(newDate)
    onClose()
  }

  const onClose = () => {
    setShow(false)
  }

  const actions = () => (
    <DialogActions style={styles.dialogActions}>
      <Button onClick={onClose} style={{ color: IMStyle.palette.tint }}>
        {i18n.t('CANCEL')}
      </Button>
      <Button disabled={newDate === undefined} onClick={onAccept} style={{ color: IMStyle.palette.tint }}>
        OK
      </Button>
    </DialogActions>
  )

  return (
    <Modal
      isVisible={show}
      onBackdropPress={onClose}
      backdropOpacity={colorScheme === 'light' ? 0.45 : 0.65}
      style={styles.modal}
      deviceHeight={height}
      deviceWidth={width}>
      <IM.View style={[styles.modalView, { backgroundColor: muiTheme.palette.background.default }]}>
        {mode === 'date' ? (
          <>
            <style>{css}</style>
            <DayPicker
              mode="single"
              selected={newDate}
              onSelect={setNewDate}
              modifiersClassNames={{
                selected: 'my-selected',
                today: 'my-today',
              }}
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                color: theme.text,
                fontFamily: 'Roboto',
                // @ts-ignore web
                '--rdp-background-color': Color(IMStyle.palette.tint).alpha(0.15).toString(),
                '--rdp-background-color-dark': Color(IMStyle.palette.tint).alpha(0.15).toString(),
              }}
              locale={TimeUtils.languageToLocale(language)}
              disabled={{ after: maximumDate, before: minimumDate }}
            />
            {actions()}
          </>
        ) : (
          <ThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={TimeUtils.languageToLocale(language)}>
              <StaticTimePicker
                value={newDate}
                onChange={val => setNewDate(val ?? undefined)}
                autoFocus
                localeText={{
                  toolbarTitle: 'Select',
                  okButtonLabel: 'OK',
                  cancelButtonLabel: i18n.t('CANCEL'),
                }}
                componentsProps={{ actionBar: { actions: [] } }}
              />
              {actions()}
            </LocalizationProvider>
          </ThemeProvider>
        )}
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
  modalView: {
    borderRadius: 20,
    padding: 13,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: 350,
    alignSelf: 'center',
  },
  dialogActions: {
    justifyContent: 'flex-end',
    display: 'flex',
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 25,
  },
})
