import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'

import api from '../../apis/apiCalls'
import Styles from '../../constants/Styles'
import type { Collaborator } from '../../types'
import InputContainer from '../InputContainer'
import Label from '../Label'
import SelectInput from '../SelectInput'

export function CollaboratorSelector({
  values,
  onChange,
  readonly,
}: {
  values: Collaborator[] | undefined
  onChange?: (value: Collaborator[]) => void
  readonly?: boolean
}) {
  const [collaborators, loadCollaborators] = useApi(api.getCollaborators, [])
  const { i18n } = useLanguage()

  useEffect(() => {
    loadCollaborators().catch(console.error)
  }, [])

  return (
    <>
      <Label>{i18n.t('COLLABORATOR')}</Label>
      <InputContainer style={Styles.selectorContainer}>
        <SelectInput
          readonly={readonly}
          icon={['fal', 'people-group']}
          data={collaborators}
          values={values}
          onChangeValues={onChange}
          title={i18n.t('SELECT_COLLABORATOR')}
          renderString={(col: Collaborator) => col.name}
          keyExtractor={(col: Collaborator) => `${col.id}_${col.name}`}
        />
      </InputContainer>
    </>
  )
}
