import { DateUtils, Language } from '@infominds/react-native-components'
import { format, formatDuration, intervalToDuration } from 'date-fns'
import { de, enGB, it } from 'date-fns/locale'

export enum TimeFormat {
  TIME = 'time',
  TIME_WITH_DIMENSIONS = 'timeWithDimensions',
  COUNTER = 'counter',
}

const timeElem = ['m', 'h']

const TimeUtils = {
  languageToLocale(language: Language): Locale {
    switch (language) {
      case 'de':
        return de
      case 'it':
        return it
      case 'en':
        return enGB
      default:
        return enGB
    }
  },
  format(data: string, language: Language) {
    return format(new Date(data), 'P', { locale: TimeUtils.languageToLocale(language) })
  },
  formatTime(time: Date, seconds?: boolean) {
    if (!time) return ''
    return DateUtils.formatDate(time, seconds ? 'HH:mm:ss' : 'HH:mm')
  },
  getMinutesOfDay(date: Date) {
    return date.getMinutes() + date.getHours() * 60
  },
  getSecondsOfDay(date: Date) {
    return date.getSeconds() + date.getMinutes() * 60 + date.getHours() * 3600
  },
  getCurrentMinutes() {
    return this.getMinutesOfDay(new Date())
  },
  getCurrentSeconds() {
    return this.getSecondsOfDay(new Date())
  },
  revertMinutesToDate(minutes: number, useDate?: Date) {
    const date = useDate ?? new Date()
    date.setHours(Math.floor(minutes / 60))
    date.setMinutes(minutes % 60)
    date.setSeconds(0)
    return date
  },
  revertSecondsToDate(seconds: number, useDate?: Date) {
    const date = useDate ?? new Date()
    date.setHours(Math.floor(seconds / 3600))
    const minutes = seconds % 3600
    date.setMinutes(Math.floor(minutes / 60))
    date.setSeconds(minutes % 60)
    return date
  },
  formatMinutes(minutes: number, useDate?: Date) {
    return this.formatTime(this.revertMinutesToDate(minutes, useDate))
  },
  formatSeconds(seconds: number, useDate?: Date, hideSeconds?: boolean) {
    return this.formatTime(this.revertSecondsToDate(seconds, useDate), !hideSeconds)
  },
  subtractMinutes(from: number | undefined, until: number | undefined) {
    if (!Number.isInteger(from) || !Number.isInteger(until)) return 0
    //@ts-ignore from and until are checked by Number.isInteger
    if (from > until) return 24 * 60 - (from - until)
    //@ts-ignore from and until are checked by Number.isInteger
    return until - from
  },
  subtractSeconds(from: number | undefined, until: number | undefined) {
    if (!Number.isInteger(from) || !Number.isInteger(until)) return 0
    //@ts-ignore from and until are checked by Number.isInteger
    if (from > until) return 24 * 3600 - (from - until)
    //@ts-ignore from and until are checked by Number.isInteger
    return until - from
  },
  roundSecondsToMinutes(seconds: number) {
    if (!seconds) return 0
    return Math.round(seconds / 60)
  },
  /**
   * @returns input seconds rounded to whole minutes. f.ex 63 will return 60
   */
  roundSecondsToWholeMinute(seconds: number) {
    return this.roundSecondsToMinutes(seconds) * 60
  },
  // resetTimeOfISOString(date: string, seconds?: number) {
  //   return date.split('T')[0] + `T${this.secondsToTime(seconds ?? 0, TimeFormat.TIME, true)}:00.000Z`
  // },
  // isoStringToSeconds(date: string) {
  //   const splitted = date.split('T')[1].split(':')

  //   if (splitted.length > 1) {
  //     return parseInt(splitted[1], 10) * 60 + parseInt(splitted[0], 10) * 60 * 60
  //   }

  //   return 0
  // },
  secondsToTime(seconds: number, outputFormat = TimeFormat.COUNTER, disableRadixRounding = false) {
    if (seconds > 24 * 60 * 60) {
      return 'NaN'
    }

    if (outputFormat === TimeFormat.TIME_WITH_DIMENSIONS && seconds < 60) {
      return '< 1m'
    }

    //To Display same time as radix the seconds have to be rounded to whole minutes
    if (outputFormat === TimeFormat.TIME) {
      if (!disableRadixRounding) {
        seconds = this.roundSecondsToWholeMinute(seconds)
      }

      if (seconds === 0) {
        return '00:00'
      }
    }

    const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

    const zeroPad = (num: number | undefined) => String(num).padStart(2, '0')

    const formats: string[] = []

    if (duration.hours !== 0) {
      formats.push('hours')
      formats.push('minutes')
    } else {
      if (duration.minutes !== 0) {
        formats.push('minutes')
      }
    }

    const formatted = formatDuration(duration, {
      format: formats,
      zero: true,
      delimiter: ':',
      locale: {
        formatDistance: (_test, count: number, ..._pros) => zeroPad(count),
      },
    })

    if (outputFormat === TimeFormat.TIME_WITH_DIMENSIONS) {
      return formatted
        .split(':')
        .map(elem => {
          return elem.replace(/0(\d+)/, '$1')
        })
        .reverse()
        .reduce((acc, elem, index) => {
          return `${elem}${timeElem[index] ?? ''}${acc === '' ? '' : ' '}` + acc
        }, '')
    }

    if (formatted.indexOf('0') === 0 && outputFormat === TimeFormat.COUNTER) {
      return formatted.replace('0', '')
    }

    if (outputFormat === TimeFormat.TIME) {
      if (format.length === 0) {
        return `00:00`
      } else if (formatted.length === 2) {
        return `00:${formatted}`
      } else if (formatted.length === 3) {
        return `0:${formatted}`
      }
    }

    return formatted
  },
  sortDate: <T>(obj: T[], key: keyof T, type: 'asc' | 'desc') => {
    let toRet: T[] = []

    try {
      const undefDate = obj.filter(el => el[key] === undefined)
      const withDate = obj.filter(el => el[key] !== undefined)

      withDate.sort((a, b) => {
        const aDate = new Date(a[key] as string)
        const bDate = new Date(b[key] as string)

        if (type === 'asc') {
          return aDate.getTime() - bDate.getTime()
        } else {
          return bDate.getTime() - aDate.getTime()
        }
      })

      if (type === 'asc') {
        toRet = [...undefDate, ...withDate]
      } else {
        toRet = [...withDate, ...undefDate]
      }
    } catch (err) {
      console.error('Error on sortDate', err)
    }

    return toRet
  },
  manageDateLimit: (date: Date, dateLimit: Date, type: 'min' | 'max') => {
    if (type === 'max') {
      if (date) {
        return date > dateLimit ? dateLimit : date
      } else {
        return dateLimit
      }
    } else {
      if (date) {
        return date < dateLimit ? dateLimit : date
      } else {
        return dateLimit
      }
    }
  },
}

export default TimeUtils
