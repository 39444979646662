export type RGB = {
  r: number
  g: number
  b: number
}

function hexToRgb(hex: string): RGB | null {
  // remove the hash if present
  hex = hex.replace(/^#/, '')

  // parse the RGB values
  const bigint = parseInt(hex, 16)
  // eslint-disable-next-line no-bitwise
  const r = (bigint >> 16) & 255
  // eslint-disable-next-line no-bitwise
  const g = (bigint >> 8) & 255
  // eslint-disable-next-line no-bitwise
  const b = bigint & 255

  return { r, g, b }
}

function rgbToHex(r: number, g: number, b: number): string {
  return (
    '#' +
    [r, g, b]
      .map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
      })
      .join('')
  )
}

const ColorUtils = {
  darkenColor(hex: string, amount: number): string {
    // convert hex to rgb
    const rgb = hexToRgb(hex)
    // if invalid rgb conversion, return the original hex
    if (!rgb) return hex

    // darken each channel by the given amount
    let { r, g, b } = rgb
    r = Math.max(0, Math.min(255, Math.floor(r * (1 - amount))))
    g = Math.max(0, Math.min(255, Math.floor(g * (1 - amount))))
    b = Math.max(0, Math.min(255, Math.floor(b * (1 - amount))))

    // convert rgb back to hex
    return rgbToHex(r, g, b)
  },
  brightenColor(hex: string, amount: number): string {
    // convert hex to rgb
    const rgb = hexToRgb(hex)
    // if invalid rgb conversion, return the original hex
    if (!rgb) return hex

    // brighten each channel by the given amount
    let { r, g, b } = rgb
    r = Math.max(0, Math.min(255, Math.floor(r * (1 + amount))))
    g = Math.max(0, Math.min(255, Math.floor(g * (1 + amount))))
    b = Math.max(0, Math.min(255, Math.floor(b * (1 + amount))))

    // convert rgb back to hex
    return rgbToHex(r, g, b)
  },
}

export default ColorUtils
