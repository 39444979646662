import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

export enum EPriority {
  HIGH = 0,
  NORMAL = 1,
  LOW = 2,
}

export default function PrioritySelector({ value, onPress }: { value: EPriority; onPress?: (value: EPriority) => void }) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  const priorityLowColor = value === EPriority.LOW ? '#fff' : theme.text
  const priorityNormalColor = value === EPriority.NORMAL ? '#fff' : theme.text
  const priorityHighColor = value === EPriority.HIGH ? '#fff' : theme.text

  return (
    <IM.View style={styles.container} spacing={'bottom'}>
      <IM.CardBasic
        onPress={() => onPress && onPress(2)}
        style={[
          { backgroundColor: theme.input.background },
          styles.priorityItem,
          value === EPriority.LOW && {
            ...styles.priorotySelected,
            borderColor: theme.primary,
            backgroundColor: theme.secondary,
          },
        ]}>
        <IM.View style={styles.priorityIcon}>
          <IM.Text style={{ color: priorityLowColor }}>{i18n.t('PRIORITY_LOW')}</IM.Text>
        </IM.View>
      </IM.CardBasic>
      <IM.CardBasic
        onPress={() => onPress && onPress(1)}
        style={[
          { backgroundColor: theme.input.background },
          styles.priorityItem,
          value === EPriority.NORMAL && {
            ...styles.priorotySelected,
            borderColor: theme.primary,
            backgroundColor: theme.secondary,
          },
        ]}>
        <IM.View style={styles.priorityIcon}>
          <IM.Text style={{ color: priorityNormalColor }}>{i18n.t('PRIORITY_NORMAL')}</IM.Text>
        </IM.View>
      </IM.CardBasic>
      <IM.CardBasic
        onPress={() => onPress && onPress(0)}
        style={[
          { backgroundColor: theme.input.background },
          styles.priorityItem,
          styles.marginRight,
          value === EPriority.HIGH && {
            ...styles.priorotySelected,
            borderColor: theme.primary,
            backgroundColor: theme.secondary,
          },
        ]}>
        <IM.View style={styles.priorityIcon}>
          <IM.Text style={{ color: priorityHighColor }}>{i18n.t('PRIORITY_HIGH')}</IM.Text>
        </IM.View>
      </IM.CardBasic>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', justifyContent: 'space-between' },
  priorityItem: {
    flex: 1,
    justifyContent: 'center',
    marginRight: 20,
    borderWidth: 1,
    flexDirection: 'row',
  },
  priorityIcon: { padding: IMStyle.layout.horizontalMargin + 4, alignSelf: 'center' },
  priorotySelected: {
    borderWidth: 2,
  },
  marginRight: { marginRight: 0 },
})
