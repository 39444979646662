/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { useAlert } from '@infominds/react-native-components'
import { Platform } from 'react-native'

export default function DeleteAlert({ onPress, i18n }: { onPress: () => void; i18n: any }) {
  const { alert } = useAlert()
  if (Platform.OS === 'web') {
    onPress()
  } else {
    const title = i18n.t('DELETE')
    const body = i18n.t('SURE_TO_DELETE')
    const noText = i18n.t('NO')
    const yesText = i18n.t('YES')

    alert(title, body, [
      {
        text: noText,
        onPress: () => console.debug('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: yesText,
        onPress: () => {
          onPress()
        },
      },
    ])
  }
}
