import { IM, IMLayout, IMStyle, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { LiteAsset } from '@infominds/react-native-media-lite'
import { useNavigation, useRoute } from '@react-navigation/core'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useToast } from 'react-native-toast-notifications'

import api from '../../apis/apiCalls'
import Header from '../../components/Header'
import InputContainer from '../../components/InputContainer'
import Label from '../../components/Label'
import MediaButtons from '../../components/media/MediaButtons'
import MediaSkeleton from '../../components/media/MediaSkeleton'
import MediaView from '../../components/media/MediaView'
import ModifyRepetitionPopup from '../../components/Repetition/ModifyRepetitionPopup'
import RepetitionInput from '../../components/Repetition/RepetitionInput'
import Screen from '../../components/Screen'
import { CollaboratorSelector } from '../../components/Selectors/CollaboratorSelector'
import { DepartmentSelector } from '../../components/Selectors/DepartmentSelector'
import Styles from '../../constants/Styles'
import Toast from '../../constants/Taost'
import { UserContext } from '../../contexts/UserContext'
import DisplayAssetModal from '../../modals/media/DisplayAssetModal'
import type { Collaborator, Department, PatchTask, Task, TaskMedia } from '../../types'
import { AppMediaUtils } from '../../utils/AppMediaUtils'
import DateUtils from '../../utils/DateUtils'

// TODO make readonly for history

export default function TaskDetailScreen() {
  const navigation = useNavigation()
  const { i18n, language } = useLanguage()
  const { theme } = useTheme()
  const toast = useToast()
  const alert = useAlert()

  const deleteController = useModalController()

  // @ts-ignore ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const init: Task = useRoute()?.params?.detail
  const [detail, setDetail] = useState<Task>(init)

  const [mediaLoading, setMediaLoading] = useState<boolean>(true)

  const [loading, setLoading] = useState(false)

  const [newAssets, setNewAssets] = useState<LiteAsset[]>([])
  const [assets, setAssets] = useState<LiteAsset[]>([])
  const assetsToShow = useMemo(() => [...newAssets, ...assets], [assets, newAssets])
  const [deletingMedia, setDeletingMedia] = useState<string[]>([])
  const showAssetModal = useModalController<LiteAsset>()

  const [note, setNote] = useState<string>(detail?.note ?? '')
  const [blocked, setBlocked] = useState<boolean>(detail?.blocked === 1)
  const [selectedDepartments, setSelectedDepartments] = useState<Department[]>(detail?.departments || [])
  const [collaborators, setCollaborators] = useState<Collaborator[]>(detail?.collaborators || [])

  const hasRepetition: boolean = useMemo(() => detail?.taskTemplate !== null && detail?.taskTemplate.repetition !== null, [detail])

  const { user } = useContext(UserContext)

  // const isAdmin = user ? user?.role?.is_Admin === 1 : false

  function refresh() {
    setMediaLoading(true)
    Promise.all([api.getTasksById(detail.id), loadMedias()])
      .then(([res, _]) => {
        if (res && res.length > 0 && res[0]) {
          setDetail(res[0])
          setBlocked(res[0].blocked === 1)
        }
      })
      .catch(console.error)
      .finally(() => setMediaLoading(false))
  }

  // refresh info when updating task
  //TODO: handle repetition deletion
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refresh()
    })

    return unsubscribe
  }, [navigation])

  async function loadMedias() {
    const apiMedia = await api.getMedias(detail.id)
    const convertedMedia = await AppMediaUtils.convertMediaToLiteAssets(apiMedia)
    setAssets(convertedMedia)
  }

  useEffect(() => {
    loadMedias()
      .catch(console.error)
      .finally(() => setMediaLoading(false))
  }, [detail])

  async function update() {
    setLoading(true)
    //get only new medias
    let medias: TaskMedia[] | undefined = []
    try {
      medias = await AppMediaUtils.createTaskMedia(newAssets)
    } catch (error) {
      console.error('createTaskMedia failed:', error)
      toast.show(i18n.t('ERROR_MEDIA_UPLOAD'), Toast.error)
    }

    const req: PatchTask = {
      id: detail.id,
      title: detail.title,
      note: note.length !== 0 ? note : undefined,
      priority: detail.priority,
      description: detail.description,
      room: detail.room,
      deliveryDate: detail.deliveryDate,
      blocked: blocked ? 1 : 0,
      year: detail.year,
      collaboratorsIds: collaborators.map(item => item.id),
      departmentsIds: selectedDepartments?.map(item => item.id),
      NotDeleteMedia: false,
      NewMedias: [] as TaskMedia[],
    }

    // @ts-ignore ignore
    if (medias && medias.length > 0) {
      req.NewMedias = medias
    } else {
      req.NotDeleteMedia = true
    }
    await api
      .patchTasks(req)
      .then(
        //set all uploaded newAssets as assets
        () => {
          // find all not created media and therefore not uploaded, normally (when no error occurred) this should be an empty array
          const notUploadedMedia = newAssets.filter(a => !medias?.some(m => m.filename === a.fileName))
          setNewAssets([...notUploadedMedia])
        }
      )
      .catch(error => {
        toast.show(i18n.t('ERROR_SENDING_REQUEST'), Toast.error)
        console.error(error)
      })
      .finally(() => setLoading(false))
  }

  async function start() {
    await update()
    if (user?.collaborator?.id) {
      await api.taskStart({ id: detail.id, collaboratorId: user?.collaborator?.id }).catch(console.error)

      refresh()
    } else {
      console.error('collaborator missing, users without assign collaborators cannot start tasks')
    }
  }
  function complete() {
    // await update()
    if (user?.collaborator?.id) {
      api
        .taskComplete({ id: detail.id, collaboratorId: user?.collaborator?.id })
        .then(() => navigation.goBack())
        .catch(console.error)
    } else {
      console.error('collaborator missing, users without assign collaborators cannot start tasks')
    }
  }

  function deleteTask(deleteAllTasks?: boolean) {
    deleteController.close()
    setLoading(true)
    const apiRequest = deleteAllTasks ? api.deleteTaskTemplate({ id: detail.taskTemplate.id ?? '' }) : api.deleteTask({ id: detail.id })

    apiRequest
      .then(() => navigation.goBack())
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  function handleAssetPressed(asset: LiteAsset) {
    if (deletingMedia.includes(asset.id)) return
    if (asset.type === 'file') {
      if (newAssets.find(a => a.id === asset.id)) return
      AppMediaUtils.shareFile(asset).catch(console.error)
    } else {
      showAssetModal.show(asset)
    }
  }

  function handleDeleteAsset(assetsToDelete: LiteAsset[]) {
    const newAssetsToDelete = assetsToDelete.filter(a => newAssets.some(na => na.id === a.id))
    const backEndAssetsToDelete = assetsToDelete.filter(a => assets.some(na => na.id === a.id))

    alert.alert(i18n.t('DELETE_MEDIA'), i18n.t('DELETE_MEDIA_ALERT'), [
      {
        text: i18n.t('REMOVE'),
        onPress: () => deleteMedia(),
        style: 'destructive',
      },
      {
        text: i18n.t('CANCEL'),
        style: 'cancel',
      },
    ])

    function deleteMedia() {
      if (newAssetsToDelete.length) {
        setNewAssets(prev => prev.filter(q => !newAssetsToDelete.some(a => a.id === q.id)))
      }
      if (backEndAssetsToDelete.length) {
        setDeletingMedia(prev => [...prev, ...assetsToDelete.map(q => q.id)])

        Promise.allSettled(assetsToDelete.map(m => (m ? api.deleteMedias({ id: m.id }) : Promise.resolve())))
          .then(() => {
            setAssets(prev => prev.filter(prevAssets => !assetsToDelete.some(assetToDelete => assetToDelete.id === prevAssets.id)))
          })
          .catch(error => {
            toast.show(i18n.t('ERROR_MEDIA_DELETE'), Toast.error)
            console.error(error)
            refresh()
          })
          .finally(() => {
            setDeletingMedia(prev =>
              prev.filter(prevDeletingAssets => !assetsToDelete.some(assetToDelete => assetToDelete.id === prevDeletingAssets))
            )
          })
      }
    }
  }

  // function stop() {
  //   // await update()
  //   if (user?.collaborator?.id) {
  //     api
  //       .taskStop({ id: detail.id, collaboratorId: user?.collaborator?.id })
  //       .then(res => refresh())
  //       .catch(console.error)
  //   } else {
  //     console.error('collaborator missing')
  //   }
  // }

  return (
    <Screen
      spacing="none"
      screenHeader={
        <Header
          title={i18n.t('TASK')}
          showEdit={user?.role?.is_Admin === 1}
          onEditPress={() => {
            navigation.navigate('NewTask', detail)
          }}
          onBackPress={() => {
            navigation.goBack()
          }}
          onDeletePress={() => {
            deleteController.show()
          }}
        />
      }
      hasRoundCorners>
      <IM.LoadingSpinnerModal isVisible={loading} />
      <IM.View spacing={'none'} style={Styles.detailContainer}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          style={{
            padding: IMStyle.layout.horizontalMargin * 2,
          }}>
          <IM.View style={styles.infoContainer}>
            <View style={styles.height10} />
            {/* <Label>Title</Label> */}
            <IM.Text style={styles.bold}>{detail?.title}</IM.Text>

            {/* <FormSeparator /> */}
            <View style={styles.height20} />

            {/* <Label>Description</Label> */}
            <IM.Text>{detail?.description}</IM.Text>

            <View style={styles.height20} />

            {hasRepetition && <RepetitionInput value={detail?.taskTemplate.repetition} readOnly />}

            <View style={styles.row}>
              {detail.deliveryDate && DateUtils.toString(detail?.deliveryDate, language) !== '' && (
                <View style={IMLayout.flex.f1}>
                  <Label>{i18n.t('DELIVERY_DATE')}</Label>
                  <View style={[IMStyle.layout.shadow, styles.marginBottom]}>
                    <IM.Text>{detail?.deliveryDate ? DateUtils.toString(detail?.deliveryDate, language) : ''}</IM.Text>
                  </View>
                  <View style={styles.height20} />
                </View>
              )}

              {/* <FormSeparator /> */}

              {detail.room && (
                <View style={IMLayout.flex.f1}>
                  <Label>{i18n.t('ROOM')}</Label>
                  <IM.Text>{detail?.room}</IM.Text>
                  <View style={styles.height20} />
                </View>
              )}

              {/* <FormSeparator /> */}
            </View>

            {/* <FormSeparator /> */}

            <IM.View spacing={'vertical'}>
              <Label>{i18n.t('MEDIA')}</Label>
              <MediaView
                spacing={'top'}
                assets={assetsToShow}
                onAssetPressed={handleAssetPressed}
                align={'left'}
                loading={mediaLoading}
                loadingSkeleton={<MediaSkeleton mediaCount={assetsToShow.length > 0 ? assetsToShow.length : 1} align={'left'} />}
                editMode={true}
                onDeleteAsset={handleDeleteAsset}
                assetsBeingDeleted={deletingMedia}
                mediaBackgroundColor={theme.card.background}
              />
              <MediaButtons round assets={newAssets} setAssets={setNewAssets} />
              <View style={styles.height30} />
            </IM.View>

            <Label>{i18n.t('NOTE')}</Label>
            <InputContainer>
              <IM.Input multiline spacing={'bottom'} value={note} onChangeText={setNote} style={styles.paddingTop} />
            </InputContainer>
            <View style={styles.height20} />
            <DepartmentSelector values={selectedDepartments} onChange={setSelectedDepartments} readonly={false} />
            <View style={styles.height20} />
            <CollaboratorSelector values={collaborators} onChange={setCollaborators} readonly={false} />
            <View style={styles.height20} />

            {!!detail.creationDate && (
              <>
                <Label>{i18n.t('CREATED')}</Label>
                <IM.View spacing={'bottom'} style={[IMStyle.layout.shadow, styles.input]}>
                  <IM.Text>{`${detail.creationCollaborator?.name ?? 'ADMIN'} - ${DateUtils.formatDate(
                    new Date(detail?.creationDate),
                    'dd/MM/yyyy HH:mm'
                  )}`}</IM.Text>
                </IM.View>
              </>
            )}

            {!!detail.modifiedDate && (
              <>
                <Label>{i18n.t('MODIFIED')}</Label>
                <IM.View spacing={'bottom'} style={[IMStyle.layout.shadow, styles.input]}>
                  <IM.Text>{`${detail.modifiedCollaborator?.name ?? 'ADMIN'} - ${DateUtils.formatDate(
                    new Date(detail?.modifiedDate),
                    'dd/MM/yyyy HH:mm'
                  )}`}</IM.Text>
                </IM.View>
              </>
            )}

            {detail.closed === 0 && detail.blocked === 1 && (
              <>
                <Label>{i18n.t('BLOCKED')}</Label>
                <View style={[IMStyle.layout.shadow, styles.input]}>
                  <IM.Checkbox value={blocked} onValueChange={setBlocked} />
                </View>
              </>
            )}

            {detail.closed === 1 && !!detail.closedDate && !!detail.closedCollaborator && (
              <>
                <Label>{i18n.t('CLOSED')}</Label>
                <IM.View spacing={'bottom'} style={[IMStyle.layout.shadow, styles.input]}>
                  <IM.Text>{`${detail.closedCollaborator?.name} - ${DateUtils.formatDate(
                    new Date(detail?.closedDate),
                    'dd/MM/yyyy HH:mm'
                  )}`}</IM.Text>
                </IM.View>
              </>
            )}
          </IM.View>
        </ScrollView>

        {/* Popup for delete handling of a task */}
        <ModifyRepetitionPopup
          controller={deleteController}
          handleSubmit={deleteTask}
          message={i18n.t('SURE_TO_DELETE')}
          isRepetition={hasRepetition}
        />

        {/* <View style={{ backgroundColor: theme.secondary, paddingTop: 10 }}> */}
        {/* {isAdmin ? (
          <>
            <IM.Text>Admins can not start/stop tasks</IM.Text>
            <IM.Button
              style={[styles.button, { backgroundColor: theme.primary }]}
              onPress={() => {
                setLoading(true)
                update()
                  .then(() => refresh())
                  .catch(console.error)
                  .finally(() => setLoading(false))
              }}
              title={'Save'}
            />
          </>
        ) : (
          <> */}
        {detail.blocked !== 1 && detail.closed === 0 ? (
          <View style={styles.row}>
            <IM.Button
              style={[styles.button, { backgroundColor: theme.primary }]}
              onPress={() => {
                setLoading(true)
                start()
                  .catch(console.error)
                  .finally(() => setLoading(false))
              }}
              title={i18n.t('START')}
            />
            <IM.Button
              style={[styles.button, { backgroundColor: theme.secondary }]}
              onPress={() => {
                setLoading(true)
                update()
                  .then(() => {
                    refresh()
                  })
                  .catch(console.error)
                  .finally(() => setLoading(false))
                // stop() //.catch(console.error)
              }}
              title={i18n.t('SAVE')}
            />
          </View>
        ) : (
          <>
            {detail.closed === 0 && (
              <View style={styles.row}>
                <IM.Button
                  style={[styles.button, { backgroundColor: theme.error }]}
                  onPress={() => {
                    setLoading(true)
                    complete() //.catch(console.error)
                  }}
                  title={i18n.t('COMPLETE')}
                />
                <IM.Button
                  style={[styles.button, { backgroundColor: theme.primary }]}
                  onPress={() => {
                    setLoading(true)
                    update()
                      .then(() => refresh())
                      .catch(console.error)
                      .finally(() => setLoading(false))
                    // stop() //.catch(console.error)
                  }}
                  title={i18n.t('SAVE')}
                />
              </View>
            )}
          </>
        )}
        <DisplayAssetModal
          assets={assetsToShow}
          controller={showAssetModal}
          onDeleteAsset={assetsToDelete => handleDeleteAsset(assetsToDelete)}
          allowAssetDeletion={true}
        />
      </IM.View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  button: {
    justifyContent: 'flex-end',
    marginHorizontal: 10,
    marginBottom: 10,
    padding: 20,
    borderRadius: 40,
    flex: 1,
  },
  input: {
    marginBottom: 10,
  },
  height10: { height: 10 },
  height20: { height: 20 },
  height30: { height: 30 },
  infoContainer: { marginBottom: 20 },
  row: { flexDirection: 'row' },
  bold: { fontWeight: 'bold' },
  marginBottom: { marginBottom: 10 },
  paddingTop: { paddingTop: 12 },
})
