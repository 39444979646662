import { IM, useLanguage } from '@infominds/react-native-components'
import { useNavigation, useRoute } from '@react-navigation/core'
import React, { useContext } from 'react'
import { ScrollView } from 'react-native'

import api from '../../../apis/apiCalls'
import DeleteAlert from '../../../components/DeleteAlert'
import DetailText from '../../../components/DetailText'
import FormSeparator from '../../../components/FormSeparator'
import Header from '../../../components/Header'
import Label from '../../../components/Label'
import Screen from '../../../components/Screen'
import { RoleSelector } from '../../../components/Selectors/RoleSelector'
import Styles from '../../../constants/Styles'
import { UserContext } from '../../../contexts/UserContext'
import type { User } from '../../../types'

export default function UsersDetailScreen() {
  const navigation = useNavigation()
  const { i18n } = useLanguage()

  const detail: User | undefined = useRoute()?.params as User

  const { user } = useContext(UserContext)

  function deleteUser() {
    DeleteAlert({
      onPress: () => {
        api
          .deleteUser({ id: detail?.id ?? '' })
          .then(() => navigation.goBack())
          .catch(console.error)
      },
      i18n,
    })
  }

  return (
    <Screen
      spacing="none"
      screenHeader={
        <Header
          title={i18n.t('USER_DETAIL')}
          onBackPress={() => {
            navigation.goBack()
          }}
          showEdit={user?.role?.is_Admin === 1}
          onEditPress={() => {
            navigation.navigate('UsersNew', detail)
          }}
          onDeletePress={deleteUser}
        />
      }
      hasRoundCorners>
      <IM.View spacing={'all'} style={Styles.detailContainer}>
        <ScrollView>
          <IM.View spacing={'all'} spacingType={'padding'}>
            <Label>{i18n.t('USERNAME')}</Label>
            <DetailText>{detail?.username}</DetailText>

            <FormSeparator />

            <Label>{i18n.t('PASSWORD')}</Label>
            <DetailText>****</DetailText>

            <FormSeparator />

            <RoleSelector value={detail.role} readonly />

            <FormSeparator />

            <Label>{i18n.t('INACTIVE')}</Label>
            <IM.Checkbox value={detail?.inactive === 1} disabled />
          </IM.View>
        </ScrollView>

        {/* <DeleteButton onPress={deleteUser} /> */}
      </IM.View>
    </Screen>
  )
}
