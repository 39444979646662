import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'

import api from '../../apis/apiCalls'
import Styles from '../../constants/Styles'
import type { Role } from '../../types'
import InputContainer from '../InputContainer'
import Label from '../Label'
import SelectInput from '../SelectInput'

export function RoleSelector({ value, onChange, readonly }: { value: Role | undefined; onChange?: (value: Role) => void; readonly?: boolean }) {
  const { i18n } = useLanguage()

  const [roles, loadRoles] = useApi(api.getRoles, [])

  useEffect(() => {
    loadRoles().catch(console.error)
  }, [])

  return (
    <>
      <Label>{i18n.t('ROLE')}</Label>
      <InputContainer style={Styles.selectorContainer}>
        <SelectInput
          readonly={readonly}
          icon={['fal', 'people-group']}
          data={roles}
          value={value}
          onChangeValue={item => {
            if (onChange) onChange(item)
          }}
          title={i18n.t('SELECT_ROLE')}
          renderString={(dep: Role) => dep.description}
          keyExtractor={(dep: Role) => `${dep.id}_${dep.description}`}
        />
      </InputContainer>
    </>
  )
}
