/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { DateUtils } from '@infominds/react-native-components'

import { EMonthTime, ERepetitionCode, Repetition, TaskRepetition, WeekDayRepetition } from '../types/RepetitionTypes'

export const RepetitionUtils = {
  getWeekDayRepetition(values?: boolean[]): WeekDayRepetition[] {
    // sunday needs to be first, since its the default first day of week and required for api
    return [
      { key: 'SUNDAY', value: !!values && !!values[0] },
      { key: 'MONDAY', value: !!values && !!values[1] },
      { key: 'TUESDAY', value: !!values && !!values[2] },
      { key: 'WEDNESDAY', value: !!values && !!values[3] },
      { key: 'THURSDAY', value: !!values && !!values[4] },
      { key: 'FRIDAY', value: !!values && !!values[5] },
      { key: 'SATURDAY', value: !!values && !!values[6] },
    ]
  },
  sortWeekDayRepetition(repetition: WeekDayRepetition[]) {
    let weekDays = this.getWeekDayRepetition()
    const firstDay = weekDays.shift()
    if (firstDay) weekDays = [...weekDays, firstDay] // shift sunday to last pos in array
    return weekDays.map<WeekDayRepetition>(r => ({
      key: r.key,
      value: repetition.find(rep => rep.key === r.key)?.value ?? false,
    }))
  },

  mapRepetitionWeekDaysForRequest(repetition: WeekDayRepetition[]) {
    const weekDays = this.getWeekDayRepetition()
    return weekDays.map<boolean>(r => repetition.find(rep => rep.key === r.key)?.value ?? false)
  },

  getIntervalFromScheduleCode(scheduleCode: ERepetitionCode, i18n: any): string {
    switch (scheduleCode) {
      case ERepetitionCode.DAILY:
        return i18n.t('REPETITION_DAYS')
      case ERepetitionCode.WEEKLY:
        return i18n.t('REPETITION_WEEKS')
      case ERepetitionCode.MONTHLY:
        return i18n.t('REPETITION_MONTHS')
      case ERepetitionCode.YEARLY:
        return i18n.t('REPETITION_YEARS')
      default:
        return ''
    }
  },

  getMonthTimeFromEnum(monthTime: EMonthTime, i18n: any): string {
    switch (monthTime) {
      case EMonthTime.START:
        return i18n.t('REPETITION_MONTH_START')
      case EMonthTime.END:
        return i18n.t('REPETITION_MONTH_END')
      default:
        return ''
    }
  },

  /**
   * Replaces ${value1}, ${value2},... in text with given values.
   * Unused keys will be replaced with an empty string
   */
  stringValueReplacer(text: string | undefined, ...values: any[]) {
    if (!text) return ''
    if (!values) return text
    const token = '${value{n}}'
    const valueModifier = (value: any) => (value ?? '').toString().trim()
    for (let i = 0; i < values.length; i++) {
      if (i === 0) {
        text = text.replace(token.replace('{n}', ''), valueModifier(values[0])) //may also be ${value} (without number)
      }
      text = text.replace(token.replace('{n}', (i + 1).toString()), valueModifier(values[i]))
    }

    return text.replace(/\$\{value.*\}/g, '')
  },

  getRepetitionSummary(repetition: Repetition, i18n: any): string {
    if (repetition.repetitioncode === ERepetitionCode.NONE) return ''

    if (!repetition.startdate || !repetition.enddate) return ''

    const startDateString = DateUtils.formatDate(repetition.startdate, repetition.repetitioncode === ERepetitionCode.YEARLY ? 'dd.MM.' : 'dd.MM.yyyy')
    const endDateString = DateUtils.formatDate(repetition.enddate, 'dd.MM.yyyy')

    switch (repetition.repetitioncode) {
      case ERepetitionCode.DAILY: {
        return this.stringValueReplacer(i18n.t('REPETITION_SUMMARY_DAILY'), repetition.interval, startDateString, endDateString)
      }
      case ERepetitionCode.WEEKLY: {
        if (!repetition.weekdays) return ''

        const filteredWeekdays = repetition.weekdays.filter(x => x.value === true)
        const lastValue = filteredWeekdays.pop()

        if (!lastValue) return ''

        const weekDays = filteredWeekdays.length
          ? `${filteredWeekdays.map(x => i18n.t(x.key)).join(', ')} ${i18n.t('AND')} ${i18n.t(lastValue.key)}`
          : i18n.t(lastValue.key)
        return this.stringValueReplacer(i18n.t('REPETITION_SUMMARY_WEEKLY'), weekDays, repetition.interval, startDateString, endDateString)
      }
      case ERepetitionCode.MONTHLY: {
        if (repetition.monthtime === null) return 'no monthtime'

        return this.stringValueReplacer(
          i18n.t('REPETITION_SUMMARY_MONTHLY'),
          this.getMonthTimeFromEnum(repetition.monthtime, i18n),
          repetition.interval,
          startDateString,
          endDateString
        )
      }
      case ERepetitionCode.YEARLY: {
        const startYear = DateUtils.formatDate(repetition.startdate, 'yyyy')
        const endYear = DateUtils.formatDate(repetition.enddate, 'yyyy')
        return this.stringValueReplacer(i18n.t('REPETITION_SUMMARY_YEARLY'), repetition.numyears, startDateString, startYear, endYear)
      }
      default:
        return ''
    }
  },

  convertToRepetition(taskRepetition: TaskRepetition): Repetition {
    const startdate = taskRepetition.startdate
      ? typeof taskRepetition.startdate === 'string'
        ? new Date(taskRepetition.startdate)
        : taskRepetition.startdate
      : undefined
    const enddate = taskRepetition.enddate
      ? typeof taskRepetition.enddate === 'string'
        ? new Date(taskRepetition.enddate)
        : taskRepetition.enddate
      : undefined

    return {
      ...taskRepetition,
      startdate: startdate,
      enddate: enddate,
      weekdays: this.sortWeekDayRepetition(this.getWeekDayRepetition(taskRepetition.weekdays)),
    }
  },

  convertToTaskRepetition(repetition: Repetition): TaskRepetition {
    return {
      ...repetition,
      weekdays: this.mapRepetitionWeekDaysForRequest(repetition.weekdays ?? []),
    }
  },

  validateRepetition(repetitionToValidate: TaskRepetition, i18n: any): { valid: boolean; error: string } {
    const repetition = this.convertToRepetition(repetitionToValidate)
    let error = i18n.t('REPETITION_ERROR')
    let valid = true
    const errorStrings: string[] = []

    switch (repetition.repetitioncode) {
      case ERepetitionCode.DAILY: {
        if (repetition.interval <= 0) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_INTERVAL')}`)
        }
        if (repetition.startdate === undefined) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_STARTDATE')}`)
        }
        if (repetition.enddate === undefined) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_ENDDATE')}`)
        }
        if (repetition.startdate && repetition.enddate && repetition.startdate >= repetition.enddate) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETIION_ERROR_DATES')}`)
        }

        break
      }
      case ERepetitionCode.WEEKLY: {
        const filteredWeekdays: WeekDayRepetition[] = repetition.weekdays.filter(day => day.value === true)

        if (filteredWeekdays.length === 0) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_WEEKDAYS')}`)
        }
        if (repetition.interval <= 0) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_INTERVAL')}`)
        }
        if (repetition.startdate === undefined) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_STARTDATE')}`)
        }
        if (repetition.enddate === undefined) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_ENDDATE')}`)
        }
        if (repetition.startdate && repetition.enddate && repetition.startdate >= repetition.enddate) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETIION_ERROR_DATES')}`)
        }

        break
      }
      case ERepetitionCode.MONTHLY: {
        if (repetition.monthtime === null) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_MONTHTIME')}`)
        }
        if (repetition.interval <= 0) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_INTERVAL')}`)
        }
        if (repetition.startdate === undefined) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_STARTDATE')}`)
        }
        if (repetition.enddate === undefined) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_ENDDATE')}`)
        }
        if (repetition.startdate && repetition.enddate && repetition.startdate >= repetition.enddate) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETIION_ERROR_DATES')}`)
        }

        break
      }
      case ERepetitionCode.YEARLY: {
        if (repetition.numyears <= 0) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_NUMYEARS')}`)
        }
        if (repetition.startdate === undefined) {
          valid = false
          errorStrings.push(` ${i18n.t('REPETITION_ERROR_STARTDATE')}`)
        }

        break
      }
      case ERepetitionCode.NONE: {
        break
      }
      default:
        valid = false
        break
    }

    // if there are error strings, add them to the error message
    if (errorStrings.length > 0) {
      error += ' '
      error += errorStrings.length > 1 ? `${errorStrings.slice(0, -1).join(', ')}, ${errorStrings.slice(-1)}` : errorStrings.join('')
    }
    return { valid, error }
  },
}
