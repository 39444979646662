import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import type { ThemeColorExpanded } from '../types'

export default function DropdownArrowUp() {
  const { theme } = useTheme<ThemeColorExpanded>()

  return <IM.Icon icon={['far', 'chevron-up']} size={16} color={theme.dropdown.arrow} />
}
