import { StyleProp, ViewStyle } from 'react-native'

const Styles: {
  saveButton: StyleProp<ViewStyle>
  detailContainer: StyleProp<ViewStyle>
  flatListBorderRadius: StyleProp<ViewStyle>
  fullHeight: StyleProp<ViewStyle>
  selectorContainer: StyleProp<ViewStyle>
} = {
  saveButton: { justifyContent: 'flex-end', padding: 15 },
  detailContainer: { flex: 1, justifyContent: 'space-between' },
  flatListBorderRadius: { borderTopLeftRadius: 25, borderTopRightRadius: 25 },
  fullHeight: { height: '100%' },
  selectorContainer: { marginBottom: 8 },
}

export default Styles
