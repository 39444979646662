import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import DateUtils from '../../utils/DateUtils'
import InputContainer from '../InputContainer'
import InputDateTime from '../InputDateTime'
import Label from '../Label'
import IntervalInput from './components/IntervalInput'
import SchedulerButton from './components/SchedulerButton'
import WeekDayButtons from './components/WeekDayButtons'
import { EMonthTime, ERepetitionCode, TaskRepetition, WeekDayRepetition, type Repetition } from './types/RepetitionTypes'
import { RepetitionUtils } from './utils/RepetitionUtils'

export default function RepetitionInput({
  value,
  onChange,
  readOnly,
}: {
  value?: TaskRepetition
  onChange?: (value: TaskRepetition) => void
  readOnly?: boolean
}) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  const [monthTimeOpen, setMonthTimeOpen] = useState<boolean>(false)

  const [data, setData] = useState<Repetition>(
    value
      ? RepetitionUtils.convertToRepetition(value)
      : {
          repetitioncode: ERepetitionCode.NONE,
          startdate: undefined,
          enddate: undefined,
          weekdays: [],
          interval: 0,
          monthtime: null,
          numyears: 0,
        }
  )

  const [monthTime, setMonthTime] = useState<EMonthTime | null>(data.monthtime)

  const monthTimeOptions: { label: string; value: EMonthTime }[] = [
    { label: i18n.t('REPETITION_MONTH_START'), value: EMonthTime.START },
    { label: i18n.t('REPETITION_MONTH_END'), value: EMonthTime.END },
  ]

  const weekdays = RepetitionUtils.sortWeekDayRepetition(RepetitionUtils.getWeekDayRepetition(value?.weekdays || []))

  const hasRepetition = useMemo(() => data.repetitioncode !== ERepetitionCode.NONE, [data])

  const chooseWeekdays = useMemo(() => data.repetitioncode === ERepetitionCode.WEEKLY && hasRepetition, [data, hasRepetition])
  const chooseInterval = useMemo(() => data.repetitioncode !== ERepetitionCode.YEARLY && hasRepetition, [data, hasRepetition])

  const repetitionSummary: string = useMemo(
    () => (readOnly && hasRepetition ? RepetitionUtils.getRepetitionSummary(data, i18n) : ''),
    [data, hasRepetition, readOnly]
  )

  function onSchedulerButtonChange(schedulerValue: ERepetitionCode) {
    onChange?.(
      RepetitionUtils.convertToTaskRepetition({
        ...data,
        repetitioncode: schedulerValue,
      })
    )
    setData({ ...data, repetitioncode: schedulerValue })
  }

  function onWeekdayButtonPress(weekday: WeekDayRepetition) {
    const weekdayArray = data.weekdays
    const foundDay = weekdayArray?.find(d => d.key === weekday.key)
    if (foundDay) foundDay.value = !foundDay.value
    else weekdayArray?.push({ key: weekday.key, value: true })

    onChange?.(
      RepetitionUtils.convertToTaskRepetition({
        ...data,
        weekdays: weekdayArray,
      })
    )
    setData({ ...data, weekdays: weekdayArray })
  }

  function onIntervalChange(newInterval: string) {
    const interval = Number(newInterval)
    if (isNaN(interval) || interval < 0) return

    onChange?.(
      RepetitionUtils.convertToTaskRepetition({
        ...data,
        interval: interval,
      })
    )
    setData({ ...data, interval: interval })
  }

  function onMonthTimeChange(newMonthTime: EMonthTime | null) {
    onChange?.(
      RepetitionUtils.convertToTaskRepetition({
        ...data,
        monthtime: newMonthTime,
      })
    )
    setData({ ...data, monthtime: newMonthTime })
  }

  useEffect(() => {
    onMonthTimeChange(monthTime)
  }, [monthTime])

  function onNumYearsChange(newNumYears: string) {
    const years = Number(newNumYears)
    if (isNaN(years) || years < 0) return

    onChange?.(
      RepetitionUtils.convertToTaskRepetition({
        ...data,
        numyears: years,
      })
    )
    setData({ ...data, numyears: years })
  }

  function onStartDateChange(newDate: Date | undefined) {
    onChange?.(
      RepetitionUtils.convertToTaskRepetition({
        ...data,
        startdate: newDate ? DateUtils.dateToUTCFormat(newDate) : undefined,
      })
    )
    setData({
      ...data,
      startdate: newDate ? DateUtils.dateToUTCFormat(newDate) : undefined,
    })
  }

  function onEndDateChange(newDate: Date | undefined) {
    onChange?.(
      RepetitionUtils.convertToTaskRepetition({
        ...data,
        enddate: newDate ? DateUtils.dateToUTCFormat(newDate) : undefined,
      })
    )
    setData({
      ...data,
      enddate: newDate ? DateUtils.dateToUTCFormat(newDate) : undefined,
    })
  }

  //don't show anything if it's readOnly and has no repetition
  if (readOnly && !hasRepetition) return <></>

  return (
    <IM.View spacing={['vertical']}>
      <Label>{i18n.t('REPETITION')}</Label>
      <InputContainer style={[]}>
        <IM.View
          style={[
            (hasRepetition || readOnly) && {
              backgroundColor: theme.input.border,
              ...styles.container,
            },
          ]}>
          <IM.View style={[styles.repetitionTypes, { backgroundColor: readOnly ? theme.textDetail : theme.card.background }]}>
            {!readOnly && (
              <SchedulerButton
                selected={data.repetitioncode === ERepetitionCode.NONE}
                code={ERepetitionCode.NONE}
                onChange={onSchedulerButtonChange}
                title={i18n.t('REPETITION_NONE')}
              />
            )}
            <>
              <SchedulerButton
                selected={data.repetitioncode === ERepetitionCode.DAILY}
                code={ERepetitionCode.DAILY}
                onChange={onSchedulerButtonChange}
                title={i18n.t('REPETITION_DAILY')}
                readOnly={readOnly}
              />
            </>
            <>
              <SchedulerButton
                selected={data.repetitioncode === ERepetitionCode.WEEKLY}
                code={ERepetitionCode.WEEKLY}
                onChange={onSchedulerButtonChange}
                title={i18n.t('REPETITION_WEEKLY')}
                readOnly={readOnly}
              />
            </>
            <>
              <SchedulerButton
                selected={data.repetitioncode === ERepetitionCode.MONTHLY}
                code={ERepetitionCode.MONTHLY}
                onChange={onSchedulerButtonChange}
                title={i18n.t('REPETITION_MONTHLY')}
                readOnly={readOnly}
              />
            </>
            <>
              <SchedulerButton
                selected={data.repetitioncode === ERepetitionCode.YEARLY}
                code={ERepetitionCode.YEARLY}
                onChange={onSchedulerButtonChange}
                title={i18n.t('REPETITION_YEARLY')}
                readOnly={readOnly}
              />
            </>
          </IM.View>

          {hasRepetition && readOnly && (
            <IM.View spacing={['all']}>
              <IM.Text style={styles.labelStyle}>{repetitionSummary}</IM.Text>
            </IM.View>
          )}

          {hasRepetition && !readOnly && (
            <IM.View spacing={['all']}>
              {/* Weekday selection */}
              {chooseWeekdays && (
                <IM.View spacing={['top']}>
                  <Label textStyle={styles.labelStyle}>{i18n.t('REPETITION_SELECT_WEEKDAYS')}</Label>
                  <IM.View spacing={['vertical']} style={[IMLayout.flex.row, styles.chooseWeekdays]}>
                    {weekdays.map(day => (
                      <WeekDayButtons
                        key={`WeekDayButton${day.key}`}
                        title={i18n.t(day.key)}
                        onSetDay={() => {
                          onWeekdayButtonPress(day)
                        }}
                        disabled={false}
                        startValue={day.value}
                      />
                    ))}
                  </IM.View>
                </IM.View>
              )}

              {/* time of month selection for MONTHLY */}
              {data.repetitioncode === ERepetitionCode.MONTHLY && (
                <IM.View spacing={['top']} style={[styles.monthly]}>
                  <Label textStyle={styles.labelStyle}>{i18n.t('REPETITION')}</Label>
                  <IM.Dropdown
                    closeAfterSelecting
                    open={monthTimeOpen}
                    value={data.monthtime}
                    items={monthTimeOptions}
                    setOpen={setMonthTimeOpen}
                    setValue={setMonthTime}
                    listMode="SCROLLVIEW"
                  />
                </IM.View>
              )}

              {/* interval selection */}
              {chooseInterval && (
                <IM.View spacing={['top']}>
                  <Label textStyle={styles.labelStyle}>{i18n.t('REPETITION_INTERVAL')}</Label>
                  <IntervalInput
                    value={data.interval.toString()}
                    postFixText={RepetitionUtils.getIntervalFromScheduleCode(data.repetitioncode, i18n)}
                    containerStyle={[{ marginHorizontal: IMLayout.horizontalMargin * 5 }]}
                    onChangeText={onIntervalChange}
                  />
                </IM.View>
              )}

              {/* Number of years for YEARLY */}
              {data.repetitioncode === ERepetitionCode.YEARLY && (
                <IM.View spacing={['top']}>
                  <Label textStyle={styles.labelStyle}>{i18n.t('REPETITION_NUM_YEARS')}</Label>
                  <IM.View style={[{ marginHorizontal: IMLayout.horizontalMargin * 5 }]}>
                    <IM.Input keyboardType="numeric" value={data.numyears?.toString()} onChangeText={onNumYearsChange} />
                  </IM.View>
                </IM.View>
              )}

              {/* TODO: fix web date picker */}
              {/* date input */}
              <IM.View style={IMLayout.flex.row} spacing={['top']}>
                {/* single date for yearly */}
                {data.repetitioncode === ERepetitionCode.YEARLY ? (
                  <>
                    <IM.View spacing={['top']} style={[IMLayout.flex.f1, { marginHorizontal: IMLayout.horizontalMargin * 5 }]}>
                      <Label textStyle={styles.labelStyle}>{i18n.t('REPETITION_AT')}</Label>
                      <InputContainer style={styles.marginBottom}>
                        <InputDateTime
                          mode="date"
                          value={data?.startdate}
                          onConfirm={onStartDateChange}
                          onDelete={() => onStartDateChange(undefined)}
                          dontShowYear
                        />
                      </InputContainer>
                    </IM.View>
                  </>
                ) : (
                  <>
                    {/* start- and end-date for other*/}
                    <IM.View spacing={['right', 'top']} style={IMLayout.flex.f1}>
                      <Label textStyle={styles.labelStyle}>{i18n.t('REPETITION_START')}</Label>
                      <InputContainer style={styles.marginBottom}>
                        <InputDateTime
                          mode="date"
                          value={data?.startdate}
                          onConfirm={onStartDateChange}
                          onDelete={() => onStartDateChange(undefined)}
                        />
                      </InputContainer>
                    </IM.View>

                    <IM.View spacing={['left', 'top']} style={IMLayout.flex.f1}>
                      <Label textStyle={styles.labelStyle}>{i18n.t('REPETITION_END')}</Label>
                      <InputContainer style={styles.marginBottom}>
                        <InputDateTime mode="date" value={data?.enddate} onConfirm={onEndDateChange} onDelete={() => onEndDateChange(undefined)} />
                      </InputContainer>
                    </IM.View>
                  </>
                )}
              </IM.View>
            </IM.View>
          )}
        </IM.View>
      </InputContainer>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  labelStyle: {
    textAlign: 'center',
  },
  marginBottom: {
    marginBottom: 10,
  },
  chooseWeekdays: {
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5,
    alignSelf: 'center',
  },
  monthly: { marginHorizontal: IMLayout.horizontalMargin * 5, zIndex: 1000 },
  repetitionTypes: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
    borderRadius: 20,
  },
  container: {
    borderTopStartRadius: 21,
    borderTopEndRadius: 21,
    borderBottomStartRadius: 20,
    borderBottomEndRadius: 20,
  },
})
