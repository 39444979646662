import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet, type StyleProp, type TextStyle } from 'react-native'

export default function Label({ children, textStyle }: { children: string; textStyle?: StyleProp<TextStyle> }) {
  const { theme } = useTheme()
  return <IM.Text style={[styles.text, textStyle, { color: theme.textDetail }]}>{children}</IM.Text>
}

const styles = StyleSheet.create({ text: { marginBottom: 10 } })
