import { IM, IMStyle, TabNavigationScreen } from '@infominds/react-native-components'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React, { useContext, useMemo } from 'react'

import { UserContext } from '../contexts/UserContext'
import NotificationWrapper from '../notifications/NotificationWrapper'
import LoginScreen from '../screens/LoginScreen'
import CollaboratorsDetailScreen from '../screens/management/Collaborators/CollaboratorsDetailScreen'
import CollaboratorsNewScreen from '../screens/management/Collaborators/CollaboratorsNewScreen'
import CollaboratorsScreen from '../screens/management/Collaborators/CollaboratorsScreen'
import DepartmentsDetailScreen from '../screens/management/Departments/DepartmentsDetailScreen'
import DepartmentsNewScreen from '../screens/management/Departments/DepartmentsNewScreen'
import DepartmentScreen from '../screens/management/Departments/DepartmentsScreen'
import ManagementScreen from '../screens/management/ManagementScreen'
import RolesDetailScreen from '../screens/management/Roles/RolesDetailScreen'
import RolesNewScreen from '../screens/management/Roles/RolesNewScreen'
import RolesScreen from '../screens/management/Roles/RolesScreen'
import RoomsScreen from '../screens/management/Rooms/RoomsScreen'
import UsersDetailScreen from '../screens/management/Users/UsersDetailScreen'
import UsersNewScreen from '../screens/management/Users/UsersNewScreen'
import UsersScreen from '../screens/management/Users/UsersScreen'
import SettingsScreen from '../screens/SettingsScreen'
import TaskDetailScreen from '../screens/tasks/TaskDetailScreen'
import NewTask from '../screens/tasks/TaskNewScreen'
import HistoryScreen from '../screens/tasks/TasksHistoryScreen'
import HomeScreen from '../screens/tasks/TasksScreen'
import type { Collaborator, Department, Role, Task, User } from '../types'

type RootStackParamList = {
  Login: undefined
  Root: undefined
  RootManager: undefined
  Home: undefined
  NewTask: Task | undefined
  DetailTask: { detail: Task }
  Settings: undefined

  Users: undefined
  UsersNew: User | undefined
  UsersDetail: User

  Collaborators: undefined
  CollaboratorsNew: Collaborator | undefined
  CollaboratorsDetail: Collaborator

  Departments: undefined
  DepartmentDetail: Department
  DepartmentsNew: Department | undefined

  Roles: undefined
  RolesDetail: Role
  RolesNew: Role | undefined

  // Rooms: undefined
}

const bottomTabs: TabNavigationScreen[] = [
  {
    name: 'Home',
    component: HomeStackScreen,
    icon: ['fal', 'home'],
    title: 'Home',
  },
  { name: 'History', component: HistoryScreen, icon: ['fal', 'calendar'], title: 'History' },
  { name: 'Settings', component: SettingsStackScreen, icon: ['fal', 'gear'], title: 'Settings' },
]

const bottomTabsManagement: TabNavigationScreen[] = []
if (IMStyle.layout.isSmallDevice) {
  bottomTabsManagement.push({
    name: 'Management',
    component: ManagementStackScreen,
    icon: ['fal', 'toolbox'],
    title: 'Management',
  })
}

if (IMStyle.layout.isLargeDevice) {
  bottomTabsManagement.push({
    name: 'Users',
    component: UsersStackScreen,
    icon: ['fal', 'user'],
    title: 'Users',
  })
  bottomTabsManagement.push({
    name: 'Collaborators',
    component: CollaboratorsStackScreen,
    icon: ['fal', 'hard-hat'],
    title: 'Collaborators',
  })
  bottomTabsManagement.push({
    name: 'Departments',
    component: DepartmentsStackScreen,
    icon: ['fal', 'people-group'],
    title: 'Departments',
  })
  bottomTabsManagement.push({
    name: 'Roles',
    component: RolesStackScreen,
    icon: ['fal', 'user-group-crown'],
    title: 'Roles',
  })
  // bottomTabsManagement.push({
  //   name: 'Rooms',
  //   component: RoomsStackScreen,
  //   icon: ['fal', 'bed'],
  //   title: 'Rooms',
  // })
}

const Stack = createNativeStackNavigator<RootStackParamList>()
export default function Navigator() {
  const { isAdmin } = useContext(UserContext)
  const rootStack = useMemo(
    () => (isAdmin ? IM.TabNavigator([...bottomTabs, ...bottomTabsManagement], 'Home') : IM.TabNavigator(bottomTabs, 'Home')),
    [isAdmin]
  )

  return (
    <NavigationContainer>
      <NotificationWrapper>
        <Stack.Navigator initialRouteName="Login" screenOptions={{ headerShown: false }}>
          <Stack.Screen name="Login" component={LoginScreen} />
          <Stack.Screen name="Root" component={rootStack} />
        </Stack.Navigator>
      </NotificationWrapper>
    </NavigationContainer>
  )
}

const HomeStack = createNativeStackNavigator()
function HomeStackScreen() {
  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false }}>
      <HomeStack.Screen name="HomeScreen" component={HomeScreen} />
      <HomeStack.Screen name="NewTask" component={NewTask} />
      <HomeStack.Screen name="DetailTask" component={TaskDetailScreen} />
    </HomeStack.Navigator>
  )
}

const SettingsStack = createNativeStackNavigator()
function SettingsStackScreen() {
  return (
    <SettingsStack.Navigator screenOptions={{ headerShown: false }}>
      <SettingsStack.Screen name="SettingsScreen" component={SettingsScreen} />
    </SettingsStack.Navigator>
  )
}

const ManagementStack = createNativeStackNavigator()
function ManagementStackScreen() {
  return (
    <ManagementStack.Navigator screenOptions={{ headerShown: false }}>
      <ManagementStack.Screen name="ManagementScreen" component={ManagementScreen} />
      <ManagementStack.Screen name="Users" component={UsersStackScreen} />
      <ManagementStack.Screen name="Collaborators" component={CollaboratorsStackScreen} />
      <ManagementStack.Screen name="Departments" component={DepartmentsStackScreen} />
      <ManagementStack.Screen name="Roles" component={RolesStackScreen} />
      <ManagementStack.Screen name="Rooms" component={RoomsStackScreen} />
    </ManagementStack.Navigator>
  )
}

const UsersStack = createNativeStackNavigator()
function UsersStackScreen() {
  return (
    <UsersStack.Navigator screenOptions={{ headerShown: false }}>
      <UsersStack.Screen name="UsersScreen" component={UsersScreen} />
      <UsersStack.Screen name="UsersNew" component={UsersNewScreen} />
      <UsersStack.Screen name="UsersDetail" component={UsersDetailScreen} />
    </UsersStack.Navigator>
  )
}

const CollaboratorsStack = createNativeStackNavigator()
function CollaboratorsStackScreen() {
  return (
    <CollaboratorsStack.Navigator screenOptions={{ headerShown: false }}>
      <CollaboratorsStack.Screen name="CollaboratorsScreen" component={CollaboratorsScreen} />
      <CollaboratorsStack.Screen name="CollaboratorsNew" component={CollaboratorsNewScreen} />
      <CollaboratorsStack.Screen name="CollaboratorsDetail" component={CollaboratorsDetailScreen} />
    </CollaboratorsStack.Navigator>
  )
}

const DepartmentsStack = createNativeStackNavigator()
function DepartmentsStackScreen() {
  return (
    <DepartmentsStack.Navigator screenOptions={{ headerShown: false }}>
      <DepartmentsStack.Screen name="DepartmentsScreen" component={DepartmentScreen} />
      <DepartmentsStack.Screen name="DepartmentDetail" component={DepartmentsDetailScreen} />
      <DepartmentsStack.Screen name="DepartmentsNew" component={DepartmentsNewScreen} />
    </DepartmentsStack.Navigator>
  )
}

const RolesStack = createNativeStackNavigator()
function RolesStackScreen() {
  return (
    <RolesStack.Navigator screenOptions={{ headerShown: false }}>
      <RolesStack.Screen name="RolesScreen" component={RolesScreen} />
      <RolesStack.Screen name="RolesDetail" component={RolesDetailScreen} />
      <RolesStack.Screen name="RolesNew" component={RolesNewScreen} />
    </RolesStack.Navigator>
  )
}

const RoomsStack = createNativeStackNavigator()
function RoomsStackScreen() {
  return (
    <RoomsStack.Navigator screenOptions={{ headerShown: false }}>
      <RoomsStack.Screen name="Rooms" component={RoomsScreen} />
    </RoomsStack.Navigator>
  )
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}
