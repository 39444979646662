import { IM, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import { LiteAsset, MediaLitePreview } from '@infominds/react-native-media-lite'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import Modal from '../Modal'

export type DisplayAssetModalProps = {
  controller: ModalController<LiteAsset>
  onDeleteAsset: (deletedAssets: LiteAsset[]) => void
  assets?: LiteAsset[]
  allowAssetDeletion?: boolean
}

export default function DisplayAssetModal({ assets, controller, onDeleteAsset, allowAssetDeletion }: DisplayAssetModalProps) {
  const { language } = useLanguage()
  const { colorScheme } = useTheme()
  const navigation = useNavigation()

  useEffect(() => {
    if (!controller.isShown) return
    navigation.setOptions({ navigationBarColor: '#000' })

    return () => {
      navigation.setOptions({ navigationBarColor: undefined })
    }
  }, [controller.isShown])

  const assetsToShow = useMemo(() => {
    if (!controller.data) return assets ?? []
    if (!assets) return controller.data ? [controller.data] : []
    const foundAsset = assets.find(asset => asset.id === controller.data?.id)
    if (foundAsset) {
      return assets
    }
    return [controller.data, ...assets]
  }, [assets, controller.data])

  function handleModalClose(modifiedAssets: LiteAsset[] | undefined) {
    controller.close()

    if (!modifiedAssets || !assets) return
    const deletedAssets = assets?.filter(asset => !modifiedAssets?.find(modifiedAsset => modifiedAsset.id === asset.id))
    if (deletedAssets.length) onDeleteAsset(deletedAssets)
  }

  return (
    <Modal visible={controller.isShown} onClose={controller.close} fullWidth backdropOpacity={0} swipeDirection={null} propagateSwipe={false}>
      <IM.View style={styles.contentView}>
        <MediaLitePreview
          assets={assetsToShow}
          languageCode={language}
          userInterfaceStyle={colorScheme}
          onClose={handleModalClose}
          selectedAssetId={controller.data?.id}
          allowAssetDeletion={allowAssetDeletion}
        />
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  contentView: {
    height: '100%',
  },
})
