import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'

import api from '../../apis/apiCalls'
import Styles from '../../constants/Styles'
import type { User } from '../../types'
import InputContainer from '../InputContainer'
import Label from '../Label'
import SelectInput from '../SelectInput'

export function UserSelector({
  value,
  onChange,
  readonly = false,
}: {
  value: User | undefined
  onChange?: (value: User) => void
  readonly: boolean
}) {
  const { i18n } = useLanguage()

  const [users, loadUsers] = useApi(api.getUsers, [])

  useEffect(() => {
    loadUsers().catch(console.error)
  }, [])

  return (
    <>
      <Label>{i18n.t('USER')}</Label>
      <InputContainer style={Styles.selectorContainer}>
        <SelectInput
          readonly={readonly}
          icon={['fal', 'people-group']}
          data={users}
          value={value}
          onChangeValue={item => {
            if (onChange) onChange(item)
          }}
          title={i18n.t('SELECT_USER')}
          renderString={(dep: User) => dep.username}
          keyExtractor={(dep: User) => `${dep.id}_${dep.username}`}
        />
      </InputContainer>
    </>
  )
}
