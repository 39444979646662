import AsyncStorage from '@react-native-async-storage/async-storage'

export type LoginData = { username: string; password: string }

export default {
  save: async (username: string, password: string) => {
    await AsyncStorage.setItem('login', JSON.stringify({ username: username, password: password }))
  },
  load: async (): Promise<LoginData | null> => {
    const result = await AsyncStorage.getItem('login')
    if (result != null) return JSON.parse(result) as LoginData
    else return null
  },
  delete: async () => {
    await AsyncStorage.removeItem('login')
  },
}
