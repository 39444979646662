export const FamiliamusColors: {
  primary: string
  secondary: string
} = {
  primary: '#392739',
  secondary: '#655367',
}

export const DefaultColors: {
  primary: string
  secondary: string
  statusBar: string
} = {
  primary: '#1E1E1E',
  secondary: '#252526',
  statusBar: 'rgba(0, 0, 0, 0.2)',
}
