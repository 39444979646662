import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome'
import React from 'react'
import { StyleSheet } from 'react-native'

import BadgeBase, { BadgeBaseProps } from './BadgeBase'

export type BadgeIconProps = { icon: IconProp; iconSize?: number; iconStyle?: FontAwesomeIconStyle } & BadgeBaseProps

export default function BadgeIcon({ icon, iconSize = 20, iconStyle, style, children: _children, ...baseBadgeProps }: BadgeIconProps) {
  return (
    <BadgeBase style={[styles.badge, style]} {...baseBadgeProps}>
      <FontAwesomeIcon size={iconSize} icon={icon} style={[iconStyle]} />
    </BadgeBase>
  )
}

const styles = StyleSheet.create({
  badge: {
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
