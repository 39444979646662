import { useDimensions } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import ReactModal from 'react-modal'
import { useWindowDimensions } from 'react-native'

import { CONSTANTS } from '../constants/Constants'
import { ModalProps } from './types'

export default function Modal({ children, visible, backdropOpacity, fullWidth, onClose }: ModalProps) {
  const modalOpacity = backdropOpacity ?? 0.5

  const { isSmallDevice } = useDimensions()
  const { width } = useWindowDimensions()

  const modalWidth = useMemo(() => {
    if (fullWidth === true || (fullWidth === undefined && isSmallDevice)) return 0

    return width - CONSTANTS.modalWidthWeb
  }, [fullWidth, isSmallDevice, width])

  return (
    <ReactModal
      isOpen={visible}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: `#000000${modalOpacity * 100}`,
        },
        content: { top: 0, bottom: 0, left: modalWidth, right: 0, padding: 0, background: 'red', border: 0, position: 'absolute' },
      }}
      onRequestClose={onClose}>
      {children}
    </ReactModal>
  )
}
