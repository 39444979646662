import { IM, IMStyle, useLanguage, useTheme, View } from '@infominds/react-native-components'
import React, { useContext, useMemo } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { UserContext } from '../contexts/UserContext'

export default function Header({
  title,
  onBackPress,
  showEdit,
  onEditPress,
  onRefreshPress,
  onFilterPress,
  onDeletePress,
  onLogoutPress,
}: {
  title?: string
  onBackPress?: () => void
  showEdit?: boolean
  onEditPress?: () => void
  onRefreshPress?: () => void
  onFilterPress?: () => void
  onDeletePress?: () => void
  onLogoutPress?: () => void
}) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  const { user } = useContext(UserContext)

  const headerTitle = useMemo(() => `${i18n.t('HELLO')}, ${user?.username ?? ''}`, [user])

  return (
    <IM.View spacing={'all'} spacingType="padding" style={{ backgroundColor: theme.primary }}>
      <IM.View style={styles.container}>
        <View style={styles.innerContainer}>
          {onBackPress && <IM.PressableIcon size={24} color="#fff" icon={['fal', 'arrow-left']} onPress={onBackPress} />}

          <IM.Text numberOfLines={1} style={[styles.text, !onBackPress && { marginLeft: IMStyle.layout.horizontalMargin }]}>
            {title ?? headerTitle ?? 'Familiamus'}
          </IM.Text>
        </View>
        <View style={styles.row}>
          {showEdit && onDeletePress && (
            <IM.PressableIcon
              size={24}
              color="#fff"
              icon={['fal', 'trash']}
              onPress={() => {
                onDeletePress()
              }}
            />
          )}
          {onFilterPress && (
            <IM.PressableIcon
              size={24}
              color="#fff"
              icon={['fal', 'filter']}
              onPress={() => {
                onFilterPress()
              }}
            />
          )}
          {onRefreshPress && (
            <IM.PressableIcon
              size={24}
              color="#fff"
              icon={['fal', 'arrows-rotate']}
              onPress={() => {
                onRefreshPress()
              }}
            />
          )}
          {showEdit && (
            <IM.PressableIcon
              size={24}
              color="#fff"
              icon={['fal', 'edit']}
              onPress={() => {
                onEditPress && onEditPress()
              }}
            />
          )}

          {onLogoutPress && (
            <IM.View style={styles.logoutView}>
              <Pressable onPress={onLogoutPress} style={{ marginRight: IMStyle.layout.horizontalMargin }}>
                <IM.TextWithIcon
                  alignIcon="right"
                  icon={['fal', 'person-to-door']}
                  iconSize={25}
                  iconColor={theme.error}
                  viewStyle={styles.view}
                  style={[styles.logout, { color: theme.error }]}>
                  {i18n.t('LOGOUT')}
                </IM.TextWithIcon>
              </Pressable>
            </IM.View>
          )}
        </View>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' },
  innerContainer: { flex: 1, flexDirection: 'row', alignItems: 'center' },
  logoutView: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24 * 1.75,
  },
  view: {
    alignItems: 'center',
  },
  logout: { fontSize: IMStyle.typography.fontSizeRegular + 4 },
  text: { flex: 1, fontSize: 23, alignSelf: 'center', color: '#fff' },
  row: { flexDirection: 'row' },
})
