import type { Language } from '@infominds/react-native-components'
import { format as dateFormat } from 'date-fns'
import { de, enGB, it } from 'date-fns/locale'

type FormatOptions = {
  locale?: Locale | undefined
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined
  firstWeekContainsDate?: number | undefined
  useAdditionalWeekYearTokens?: boolean | undefined
  useAdditionalDayOfYearTokens?: boolean | undefined
}
const DateUtils = {
  getLocale(id: string) {
    switch (id.toLowerCase()) {
      case 'en':
        return enGB
      case 'de':
        return de
      case 'it':
        return it
      default:
        return enGB
    }
  },
  languageToLocale(language: Language): Locale {
    switch (language) {
      case 'de':
        return de
      case 'it':
        return it
      case 'en':
        return enGB
      default:
        return enGB
    }
  },
  dateify(date: Date | string | number) {
    if (typeof date === 'object') return date
    if (typeof date === 'string') {
      date = date.toUpperCase()
      if (date.includes('T') && !date.includes('Z')) date += 'Z'
      return new Date(date)
    }
    return new Date(date)
  },
  formatDate(date: Date | undefined, format: string, languageId?: string) {
    if (!date) return ''
    if (typeof date !== 'object') date = this.dateify(date)
    try {
      const options: FormatOptions = {}
      if (languageId) options.locale = this.getLocale(languageId)
      return dateFormat(date, format, options)
    } catch (exception) {
      console.error('formatDate Error', exception)
    }
    return ''
  },
  toString(date: Date, language: Language) {
    const tmp = new Date(date)
    if (tmp > new Date(2022)) {
      return dateFormat(new Date(date), 'dd.MM.yyyy', {
        locale: DateUtils.languageToLocale(language),
      })
    } else {
      return ''
    }
  },
  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0')
  },
  dateToRequestTime(date: Date) {
    const timezoneDiff = Math.round((date.getTimezoneOffset() - new Date().getTimezoneOffset()) / 60)
    return `${DateUtils.padTo2Digits(date.getUTCHours() - timezoneDiff)}:${DateUtils.padTo2Digits(date.getUTCMinutes())}`
  },
  dateToRequestDateOnly(date: Date) {
    return `${date.getFullYear()}-${DateUtils.padTo2Digits(date.getMonth() + 1)}-${DateUtils.padTo2Digits(date.getDate())}`
  },

  dateToUTCFormat(date: Date) {
    return DateUtils.dateify(DateUtils.dateToRequestDateOnly(date))
  },
}
export default DateUtils
