import { useLanguage } from '@infominds/react-native-components'
import { useNotification } from '@infominds/react-native-messaging'
import { useNavigation } from '@react-navigation/native'
import React, { useContext } from 'react'

import Header from '../components/Header'
import Screen from '../components/Screen'
import { LicenseGlobals } from '../constants/Api'
import { TenantContext } from '../contexts/TenantContext'
import { UserContext } from '../contexts/UserContext'
import LoginStorage from '../utils/LoginStorage'
import SettingsView from '../views/SettingsView'

export default function SettingsScreen() {
  const tenantContext = useContext(TenantContext)
  const userContext = useContext(UserContext)
  const navigation = useNavigation()
  const { i18n } = useLanguage()
  const { logoutDeRegister } = useNotification()

  const onLogoutClick = async () => {
    await LoginStorage.delete()
    logoutDeRegister()
    navigation.reset({ routes: [{ name: 'Login' }] })
    LicenseGlobals.license = ''
    LicenseGlobals.username = ''
    tenantContext.setTenant(undefined)
    userContext.setUser(undefined)
    userContext.setIsAdmin(false)
  }

  return (
    <Screen
      spacing="none"
      screenHeader={
        <Header
          title={i18n.t('SETTINGS', { defaultValue: 'Settings' })}
          onLogoutPress={() => {
            onLogoutClick().catch(console.error)
          }}
        />
      }
      hasRoundCorners>
      <SettingsView />
    </Screen>
  )
}
