import { IM, IMLayout, SpacingProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { CONSTANTS } from '../../constants/Constants'
import useMediaView from '../../hooks/media/useMediaView'
import useLayout from '../../hooks/useLayout'

interface Props {
  mediaCount: number
  width?: number | string
  height?: number | string
  spacing?: SpacingProps
  color?: string
  style?: StyleProp<ViewStyle>
  align?: 'left' | 'right'
  imageSize?: number
}

export default function MediaSkeleton({ mediaCount, spacing, color, style, align = 'left', imageSize: overwriteImageSize }: Props) {
  const { isSmallDevice } = useLayout()

  const { imageSize: mediaImageSize, onLayout } = useMediaView({ mediaCount, overwriteImageSize })

  const imageSize = mediaImageSize?.size

  const skeletonContainerWidth = useMemo(() => {
    if (!imageSize) return 0
    if (isSmallDevice) {
      if (mediaCount === 1) return imageSize
      if (mediaCount === 2) return imageSize * 2 + CONSTANTS.imageSpacing
      if (mediaCount > 2) return imageSize * 1.5 + CONSTANTS.imageSpacing
      return 0
    }
    return (imageSize + CONSTANTS.imageSpacing) * Math.min(mediaCount, mediaImageSize.itemsPerRow)
  }, [isSmallDevice, imageSize])

  return (
    <IM.View spacing={spacing} style={[style, IMLayout.flex.row, IMLayout.flex.f1]} onLayout={onLayout}>
      {align === 'right' && <IM.View style={[IMLayout.flex.f1]} />}
      {!!skeletonContainerWidth && !!imageSize && (
        <IM.SkeletonContainer
          height={imageSize + CONSTANTS.imageSpacing}
          width={skeletonContainerWidth}
          backgroundColor={color}
          style={{ borderRadius: IMLayout.borderRadius }}>
          {isSmallDevice && (
            <>
              <IM.Rect x="0" y="0" rx={IMLayout.borderRadius} ry={IMLayout.borderRadius} width={imageSize} height={imageSize} />
              {mediaCount > 2 && (
                <>
                  <IM.Rect
                    x={imageSize + CONSTANTS.imageSpacing}
                    y="0"
                    rx={IMLayout.borderRadius}
                    ry={IMLayout.borderRadius}
                    width={imageSize / 2}
                    height={imageSize / 2 - CONSTANTS.imageSpacing / 2}
                  />
                  <IM.Rect
                    x={imageSize + CONSTANTS.imageSpacing}
                    y={imageSize / 2 + CONSTANTS.imageSpacing / 2}
                    rx={IMLayout.borderRadius}
                    ry={IMLayout.borderRadius}
                    width={imageSize / 2}
                    height={imageSize / 2 - CONSTANTS.imageSpacing / 2}
                  />
                </>
              )}
              {mediaCount === 2 && (
                <IM.Rect
                  x={imageSize + CONSTANTS.imageSpacing / 2}
                  y="0"
                  rx={IMLayout.borderRadius}
                  ry={IMLayout.borderRadius}
                  width={imageSize}
                  height={imageSize}
                />
              )}
            </>
          )}
          {!isSmallDevice && (
            <>
              {Array(Math.min(mediaCount, mediaImageSize.itemsPerRow))
                .fill(0)
                .map((_, i) => i)
                .map(index => (
                  <IM.Rect
                    key={`Skeleton${index}`}
                    x={index * imageSize + index * CONSTANTS.imageSpacing}
                    y="0"
                    rx={IMLayout.borderRadius}
                    ry={IMLayout.borderRadius}
                    width={imageSize}
                    height={imageSize}
                  />
                ))}
            </>
          )}
        </IM.SkeletonContainer>
      )}
    </IM.View>
  )
}
