import { useApi } from '@infominds/react-api'
import { IM, IMLayout, IMStyle, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { FlatList } from 'react-native'

import api from '../../../apis/apiCalls'
import RoomCard from '../../../cards/RoomCard'
import Header from '../../../components/Header'
import Screen from '../../../components/Screen'
import Styles from '../../../constants/Styles'
import type { Room } from '../../../types'

export default function RoomsScreen() {
  const navigation = useNavigation()
  const { i18n } = useLanguage()

  const [rooms, loadRooms] = useApi(api.getRooms, [])

  useEffect(() => {
    const focusHandler = navigation.addListener('focus', () => {
      loadRooms().catch(console.error)
    })
    return focusHandler
  }, [navigation])

  function renderItem({ item }: { item: Room }) {
    return (
      <RoomCard
        item={item}
        onPress={() => {
          //empty
        }}
      />
    )
  }

  return (
    <Screen spacing="none" screenHeader={<Header onBackPress={() => navigation.goBack()} title={i18n.t('ROOMS')} />} hasRoundCorners>
      <IM.View style={IMLayout.flex.f1}>
        <FlatList style={[Styles.flatListBorderRadius, { padding: IMStyle.layout.horizontalMargin }]} data={rooms} renderItem={renderItem} />
      </IM.View>
    </Screen>
  )
}
