import { apiClient } from '@infominds/react-api'

import { baseUrl, LicenseGlobals } from '../constants/Api'
import type {
  Collaborator,
  CreateTask,
  Department,
  ID,
  Media,
  PatchTask,
  PostCollaborator,
  PostDepartment,
  PostRole,
  Role,
  Room,
  StartTask,
  Task,
  TaskHome,
  Tenant,
  User,
} from '../types'
import type { GetDocumentsRequest, TaskTemplatePatchRequest } from './types/apiRequestTypes'
import type { Document } from './types/apiResponseTypes'
import { reauthenticate } from './useLoginApi'

LicenseGlobals.baseUrl = baseUrl

const client = apiClient(LicenseGlobals, '/api/', { reAuthentication: reauthenticate }, true)

const api = {
  getDocuments: (request: GetDocumentsRequest) => client.GET<Document[]>('document', request),

  getCollaborators: () => client.GET<Collaborator[]>('collaborators'),
  getCollaborator: (id: string) => client.GET<Collaborator>('collaborators?Id=', id),
  postCollaborator: (collaborator: PostCollaborator) => client.POST('collaborators', collaborator),
  patchCollaborator: (collaborator: PostCollaborator) => client.PATCH('collaborators', collaborator),
  deleteCollaborator: (body: ID) => client.DELETE('collaborators', body),

  getUsers: () => client.GET<User[]>('users'),
  getUsersByName: (username: string) => client.GET<User[]>('users?username=' + username),
  postUser: (user: User) => client.POST('users', user),
  patchUser: (user: User) => client.PATCH('users', user),
  deleteUser: (body: ID) => client.DELETE('users', body),

  getDepartments: () => client.GET<Department[]>('departments'),
  getDepartment: (id: string) => client.GET<Department[]>('departments?Id=' + id),
  postDepartment: (department: PostDepartment) => client.POST('departments', department),
  patchDepartment: (department: PostDepartment) => client.PATCH('departments', department),
  deleteDepartment: (body: ID) => client.DELETE('departments', body),

  getRoles: () => client.GET<Role[]>('roles'),
  postRole: (role: PostRole) => client.POST('roles', role),
  patchRole: (role: PostRole) => client.PATCH('roles', role),
  deleteRole: (body: ID) => client.DELETE('roles', body),

  getRooms: () => client.GET<Room[]>('rooms'),

  //getTaskTemplate: () => client.GET<Template[]>('taskTemplates'),
  //postTaskTemplate: (template: Template) => client.POST('taskTemplates', template),
  patchTaskTemplate: (body: TaskTemplatePatchRequest) => client.PATCH('taskTemplates', body),
  deleteTaskTemplate: (body: ID) => client.DELETE('taskTemplates', body),

  getTasks: () => client.GET<Task[]>('tasks?closed=0'),
  getTasksById: (id: string) => client.GET<Task[]>('tasks?Id=' + id),
  getTasksForUser: (userId: string) => client.GET<Task[]>('tasks?closed=0&UserId=' + userId),
  getHomeTasks: (body: { userId: string; departmentsIds: string[] }) => {
    let dep = ''
    if (body.departmentsIds && body.departmentsIds.length > 0) {
      body.departmentsIds.forEach(depElem => {
        dep += '&DepartmentIds=' + depElem
      })
    }
    return client.GET<TaskHome>('tasks/home?UserId=' + body.userId + dep)
  },
  getHistoryTasks: (body: { userId: string; departmentsIds: string[]; dateFrom?: Date }) => {
    let dep = ''
    if (body.departmentsIds && body.departmentsIds.length > 0) {
      body.departmentsIds.forEach(depElem => {
        dep += '&DepartmentIds=' + depElem
      })
    }
    if (body.dateFrom !== undefined) {
      dep += '&DateFrom=' + body.dateFrom.toISOString()
    }
    return client.GET<TaskHome>('tasks/history?UserId=' + body.userId + dep)
  },
  getTasksForDepartment: (departmentId: string) => client.GET<Task[]>('tasks?blocked=0&closed=0&DepartmentId=' + departmentId),
  getTasksHistory: () => client.GET<Task[]>('tasks?closed=1'),
  getTasksHistoryByUser: (userId: string) => client.GET<Task[]>('tasks?closed=1&UserId=' + userId),
  postTask: (body: CreateTask) => client.POST('tasks', body),
  patchTasks: (body: PatchTask) => client.PATCH('tasks', body),
  deleteTask: (body: ID) => client.DELETE('tasks', body),

  getMedias: (taskId: string) => client.GET<Media[]>('medias?TaskId=' + taskId),
  deleteMedias: (body: ID) => client.DELETE('medias', body),

  taskStart: (body: StartTask) => client.POST('tasks/start', body),
  taskStop: (body: StartTask) => client.POST('tasks/stop', body),
  taskComplete: (body: StartTask) => client.POST('tasks/complete', body),

  getTenants: () => client.GET<Tenant[]>('tenants'),
}

export default api
