import { IM, IMLayout, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { KeyboardAvoidingView, Platform, StatusBar, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import Styles from '../constants/Styles'

export type ScreenProps = {
  children?: ReactNode
  spacing?: SpacingProps
  screenHeader?: JSX.Element
  hasRoundCorners?: boolean
}

export default function Screen({ spacing, children, screenHeader, hasRoundCorners }: ScreenProps) {
  const { theme } = useTheme()
  const insets = useSafeAreaInsets()

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={IMLayout.flex.f1}>
      <IM.View
        style={[
          IMLayout.flex.f1,

          screenHeader && {
            paddingTop: Platform.OS === 'android' ? Math.round(StatusBar.currentHeight ?? 0) : insets.top,
          },
          { backgroundColor: theme.primary },
        ]}>
        {screenHeader}
        <IM.View spacing={spacing} style={[IMLayout.flex.f1, { backgroundColor: theme.background }]}>
          {hasRoundCorners ? (
            <View
              style={[
                IMLayout.flex.f1,
                {
                  backgroundColor: theme.primary,
                },
              ]}>
              <View
                style={[
                  IMLayout.flex.f1,
                  Styles.flatListBorderRadius,
                  styles.overflowHidden,
                  {
                    backgroundColor: theme.background,
                  },
                  Platform.OS === 'web' && styles.borderTopLeft,
                ]}>
                {children}
              </View>
            </View>
          ) : (
            <IM.View style={[Platform.OS === 'web' && Styles.fullHeight]}>{children}</IM.View>
          )}
        </IM.View>
      </IM.View>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({ overflowHidden: { overflow: 'hidden' }, borderTopLeft: { borderTopLeftRadius: 0 } })
