import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useToast } from 'react-native-toast-notifications'

import api from '../../../apis/apiCalls'
import Header from '../../../components/Header'
import InputContainer from '../../../components/InputContainer'
import Label from '../../../components/Label'
import Screen from '../../../components/Screen'
import { CollaboratorSelector } from '../../../components/Selectors/CollaboratorSelector'
import { DepartmentSelector } from '../../../components/Selectors/DepartmentSelector'
import Styles from '../../../constants/Styles'
import type { Collaborator, Department, PostDepartment } from '../../../types'

export default function DepartmentsNewScreen() {
  const navigation = useNavigation()
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const toast = useToast()

  const detail: Department = useRoute()?.params as Department

  const [name, setName] = useState<string>(detail?.name)
  const [note, setNote] = useState<string>(detail?.note ?? '')
  const [collaborators, setCollaborators] = useState<Collaborator[]>([])

  const [parentDepartment, setParentDepartment] = useState<Department>()

  useEffect(() => {
    if (detail && detail.parentDepartmentId) {
      api
        .getDepartment(detail.parentDepartmentId)
        .then(res => {
          if (res && res.length > 0) setParentDepartment(res[0])
        })
        .catch(console.error)
    }
  }, [detail])

  function createNewDepartment() {
    if (name && note) {
      const newDepartment: PostDepartment = {
        name: name,
        note: note,
        collaboratorsIds: collaborators ? collaborators.map(item => item.id) : [],
        code: name,
      }

      if (parentDepartment) newDepartment.parentDepartmentId = parentDepartment.id

      if (detail) {
        newDepartment.id = detail.id
        api
          .patchDepartment(newDepartment)
          .then(_res => {
            navigation.goBack()
            if (detail) {
              navigation.goBack()
            }
          })
          .catch(console.error)
      } else {
        api
          .postDepartment(newDepartment)
          .then(_res => {
            navigation.goBack()
            if (detail) {
              navigation.goBack()
            }
          })
          .catch(console.error)
      }
    } else {
      console.error('Department New: not all fields set')
      toast.show(i18n.t('NOT_ALL_VALUES_SET'), { type: 'danger', placement: 'bottom', duration: 4000 })
    }
  }

  return (
    <Screen
      spacing="none"
      screenHeader={
        <Header
          title={detail ? i18n.t('EDIT_DEPARTMENT') : i18n.t('NEW_DEPARTMENT')}
          onBackPress={() => {
            navigation.goBack()
          }}
        />
      }
      hasRoundCorners>
      <IM.View spacing={'all'} style={Styles.detailContainer}>
        <ScrollView>
          <IM.View spacing={'all'} spacingType={'padding'}>
            <Label>{i18n.t('NAME')}</Label>
            <InputContainer style={[styles.input]}>
              <IM.Input spacing={'bottom'} value={name} onChangeText={setName} />
            </InputContainer>

            <Label>{i18n.t('NOTE')}</Label>
            <InputContainer style={[styles.input]}>
              <IM.Input spacing={'bottom'} value={note} onChangeText={setNote} />
            </InputContainer>

            <CollaboratorSelector values={collaborators} onChange={setCollaborators} />

            {/* TODO not working */}
            <View style={[styles.input]}>
              <DepartmentSelector isParent readonly={false} value={parentDepartment} onChangeValue={setParentDepartment} />
            </View>
          </IM.View>
        </ScrollView>

        <IM.View spacing={['horizontal', 'top']}>
          <IM.Button
            onPress={() => {
              createNewDepartment()
            }}
            style={[Styles.saveButton, { backgroundColor: theme.primary }]}
            title={detail ? i18n.t('UPDATE') : i18n.t('SAVE')}
          />
        </IM.View>
      </IM.View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 10,
  },
})
