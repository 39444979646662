export const baseUrl = __DEV__ ? 'https://tunneling.infominds.eu/nm/smarttodo' : 'https://smarttodo-api.apps.infominds.eu'

const LicenseGlobals: {
  baseUrl: string
  token: string
  mandantId: number
  requestTimeOut: number
  license: string
  username: string
  erp: string
  code: string
  employeeID: number
} = {
  baseUrl: '',
  token: '',
  mandantId: -1,
  requestTimeOut: 5000, // ms
  license: '',
  username: '',
  erp: '',
  code: '',
  employeeID: -1,
}

export { LicenseGlobals }
