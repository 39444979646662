import { apiClient } from '@infominds/react-api'
import { useAlert, useLanguage } from '@infominds/react-native-components'
import { NotificationDetails, NotificationProvider, NotificationsAppInfo } from '@infominds/react-native-messaging'
import { useNavigation } from '@react-navigation/native'
import React, { useContext, useMemo } from 'react'

import api from '../apis/apiCalls'
import { reauthenticate } from '../apis/useLoginApi'
import { ApiTokenContext } from '../App'
import { LicenseGlobals } from '../constants/Api'
import { APP_CONSTANTS } from '../constants/Constants'
import { UserContext } from '../contexts/UserContext'

type NotificationWrapperProps = {
  children: React.ReactNode
}

type NotificationResult = {
  actionType: 'message'
  taskId: string
}

const client = apiClient(LicenseGlobals, '/api/', { reAuthentication: reauthenticate })

export default function NotificationWrapper({ children }: NotificationWrapperProps) {
  const { token } = useContext(ApiTokenContext)
  const { user } = useContext(UserContext)
  const { language } = useLanguage()
  const navigation = useNavigation()
  const { alert } = useAlert()
  const { i18n } = useLanguage()

  const isLoggedIn = useMemo(() => {
    return token !== ''
  }, [token])

  async function getTaskFromNotification(taskId: string) {
    return await api
      .getTasksById(taskId)
      .then(task => {
        if (task.length === 0) {
          throw new Error('Task not found')
        }
        return task[0]
      })
      .catch(e => {
        console.error('Error while getting task:', e)
        alert(i18n.t('ERROR'), i18n.t('TASK_NOT_FOUND'))
        return null
      })
  }

  function navigateToTask(details: NotificationDetails<NotificationResult>) {
    getTaskFromNotification(details.data.taskId)
      .then(task => {
        if (task) {
          navigation.navigate('DetailTask', { detail: task })
        }
      })
      .catch(e => {
        console.error('Error while navigating to task:', e)
        alert(i18n.t('ERROR'), i18n.t('TASK_NOT_FOUND'))
        navigation.reset({
          routes: [
            {
              name: 'Root',
              state: {
                routes: [{ name: 'Home', state: { routes: [{ name: 'HomeScreen' }] } }],
              },
            },
          ],
        })
      })
  }

  function showNotificationAlert(details: NotificationDetails<NotificationResult>) {
    alert(details.title ?? '', details.body, [
      {
        text: i18n.t('SHOW_TASK'),
        onPress: () => {
          navigateToTask(details)
        },
      },
      { text: i18n.t('CANCEL') },
    ])
  }

  const handleInitialNotification = (details: NotificationDetails<NotificationResult>) => {
    navigateToTask(details)
  }

  const handleBackgroundNotification = (details: NotificationDetails<NotificationResult>) => {
    navigateToTask(details)
  }

  const handleForegroundNotification = (details: NotificationDetails<NotificationResult>) => {
    showNotificationAlert(details)
  }

  return (
    <NotificationProvider<NotificationResult>
      onNotificationAppClosed={handleInitialNotification}
      onNotificationPressAppBackground={handleBackgroundNotification}
      onNotificationAppOpen={handleForegroundNotification}
      appSettings={
        {
          userId: user?.id ?? '',
          firebaseProjectId: APP_CONSTANTS.projectCode,
          language: language,
          licenseId: APP_CONSTANTS.messagingLicenseId,
        } as NotificationsAppInfo
      }
      enabled={isLoggedIn}
      apiClient={client}>
      {children}
    </NotificationProvider>
  )
}
