import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

export default function DetailText({ children }: { children: string }) {
  const { theme } = useTheme()
  return <IM.Text style={[styles.marginBottom, { color: theme.text }]}>{children}</IM.Text>
}

const styles = StyleSheet.create({ marginBottom: { marginBottom: 10 } })
