import { useApi } from '@infominds/react-api'
import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { FlatList, RefreshControl } from 'react-native'

import api from '../../../apis/apiCalls'
import RoleCard from '../../../cards/RoleCard'
import Header from '../../../components/Header'
import NewButton from '../../../components/NewButton/NewButton'
import Screen from '../../../components/Screen'
import Styles from '../../../constants/Styles'
import type { Role } from '../../../types'

export default function RolesScreen() {
  const navigation = useNavigation()
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  const [roles, loadRoles, loadingRoles] = useApi(api.getRoles, [])

  useEffect(() => {
    const focusHandler = navigation.addListener('focus', () => {
      loadRoles().catch(console.error)
    })
    return focusHandler
  }, [navigation])

  function renderItem({ item }: { item: Role }) {
    return <RoleCard item={item} onPress={() => navigation.navigate('RolesDetail', item)} />
  }

  return (
    <Screen spacing="none" screenHeader={<Header onBackPress={() => navigation.goBack()} title={i18n.t('ROLES')} />} hasRoundCorners>
      <IM.View style={IMLayout.flex.f1} spacing="horizontal">
        <FlatList
          style={[Styles.flatListBorderRadius, Styles.fullHeight, { paddingTop: IMStyle.layout.horizontalMargin * 2 }]}
          data={roles || []}
          renderItem={renderItem}
          refreshing={loadingRoles}
          refreshControl={
            <RefreshControl
              colors={[theme.primary]}
              refreshing={loadingRoles}
              onRefresh={() => {
                loadRoles().catch(console.error)
              }}
            />
          }
        />
      </IM.View>
      <NewButton visible onPress={() => navigation.navigate('RolesNew')} />
    </Screen>
  )
}
