import { useCallback, useEffect, useState, type DependencyList } from 'react'

interface Props {
  key: string | string[] | undefined
  cb: (key: string) => void
}

export default function useKeyPress({ key, cb }: Props, deps?: DependencyList) {
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    setFocused(true)

    return () => {
      setFocused(false)
    }
  }, [deps])

  useEffect(() => {
    if (!focused || key === undefined) return

    window.addEventListener('keydown', keyPress)

    return () => {
      window.removeEventListener('keydown', keyPress)
    }
  }, [key, deps, focused])

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (Array.isArray(key) ? key.find(el => e.key === el) !== undefined : e.key === key) {
        cb(e.key)
      }
    },
    [key]
  )

  return false
}
