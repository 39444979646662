import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { RefreshControl, SectionList, StyleSheet, View } from 'react-native'

import TaskCard from '../cards/tasks/TaskCard'
import TaskHistoryCard from '../cards/tasks/TaskHistoryCard'
import type { Task } from '../types'

export type TaskListItem = {
  title: string
  data: Task[]
}

export default function TasksList({
  data,
  onPress,
  onRefresh,
  isLoading,
  isHistory = false,
}: {
  data: TaskListItem[]
  onPress: (item: Task) => void
  onRefresh: () => void
  isLoading: boolean
  isHistory?: boolean
}) {
  const [refreshing, setRefreshing] = useState(false)
  const { i18n } = useLanguage()

  function renderItem({ item }: { item: Task }) {
    if (isHistory) return <TaskHistoryCard item={item} onPress={() => onPress(item)} />
    else return <TaskCard item={item} onPress={() => onPress(item)} />
  }

  //  TODO show blocked

  return (
    <IM.View spacing="horizontal" style={styles.container}>
      <SectionList
        style={{
          paddingTop: IMStyle.layout.horizontalMargin * 2,
        }}
        sections={data}
        keyExtractor={(item, index) => item.id + index}
        stickySectionHeadersEnabled={false}
        renderItem={renderItem}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              onRefresh && onRefresh()
              setRefreshing(false)
            }}
          />
        }
        renderSectionFooter={() => <View style={styles.footer} />}
        renderSectionHeader={({ section: { title } }) => <IM.Text style={styles.header}>{title}</IM.Text>}
        ListEmptyComponent={
          isLoading ? null : <IM.Text style={styles.noTaskHistory}>{isHistory ? i18n.t('NO_TASKS_HISTORY') : i18n.t('NO_TASKS')}</IM.Text>
        }
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  header: {
    fontSize: 20,
    marginBottom: IMStyle.layout.horizontalMargin,
    marginHorizontal: IMStyle.layout.horizontalMargin,
  },
  noTaskHistory: { textAlign: 'center', marginTop: IMStyle.layout.horizontalMargin, fontSize: 20 },
  footer: { height: 20 },
  container: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    overflow: 'hidden',
    flex: 1,
  },
})
