import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useToast } from 'react-native-toast-notifications'

import api from '../../../apis/apiCalls'
import Header from '../../../components/Header'
import InputContainer from '../../../components/InputContainer'
import Label from '../../../components/Label'
import Screen from '../../../components/Screen'
import { RoleSelector } from '../../../components/Selectors/RoleSelector'
import Styles from '../../../constants/Styles'
import type { Role, User } from '../../../types'

export default function UsersNewScreen() {
  const navigation = useNavigation()
  const toast = useToast()
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  const detail: User = useRoute()?.params as User

  const [username, setUsername] = useState<string>(detail?.username)
  const [inactive, setInactive] = useState<boolean>(detail?.inactive === 1 || false)
  const [password, setPassword] = useState<string>(detail?.password ?? '')
  const [role, setRole] = useState<Role | undefined>(detail?.role)

  function createUser() {
    if (username && password && role) {
      const newUser: User = {
        username: username,
        password: password,
        roleId: role?.id || detail.roleId,
        inactive: inactive ? 1 : 0,
        language: 'de',
      }

      api
        .postUser(newUser)
        .then(_res => {
          navigation.goBack()
          if (detail) {
            navigation.goBack()
          }
        })
        .catch(console.error)
    } else {
      toast.show(i18n.t('NOT_ALL_VALUES_SET'), { type: 'danger', placement: 'bottom', duration: 4000 })
    }
  }

  function updateUser() {
    const newUser: User = {
      id: detail.id,
      username: username,
      roleId: role?.id || detail.roleId,
      inactive: inactive ? 1 : 0,
      language: 'de',
    }
    if (password && password !== '') newUser.password = password

    if (detail) {
      api
        .patchUser(newUser)
        .then(_res => {
          navigation.goBack()
          if (detail) {
            navigation.goBack()
          }
        })
        .catch(console.error)
    }
  }

  return (
    <Screen
      spacing="none"
      screenHeader={
        <Header
          title={detail ? i18n.t('EDIT_USER') : i18n.t('NEW_USER')}
          onBackPress={() => {
            navigation.goBack()
          }}
        />
      }
      hasRoundCorners>
      <IM.View spacing={'all'} style={Styles.detailContainer}>
        <ScrollView>
          <IM.View spacing={'all'} spacingType={'padding'}>
            <Label>{i18n.t('USERNAME')}</Label>
            <InputContainer style={[styles.input]}>
              <IM.Input spacing={'bottom'} value={username} onChangeText={setUsername} />
            </InputContainer>

            <Label>{i18n.t('PASSWORD')}</Label>
            <InputContainer style={[styles.input]}>
              <IM.Input spacing={'bottom'} value={password} onChangeText={setPassword} />
            </InputContainer>

            <View style={[styles.input]}>
              <RoleSelector value={role} onChange={setRole} />
            </View>

            <Label>{i18n.t('INACTIVE')}</Label>
            <InputContainer style={[styles.input]}>
              <IM.Checkbox value={inactive} onValueChange={setInactive} />
            </InputContainer>
          </IM.View>
        </ScrollView>

        <IM.View spacing={['horizontal', 'top']}>
          <IM.Button
            onPress={() => {
              if (detail) updateUser()
              else createUser()
            }}
            style={[Styles.saveButton, { backgroundColor: theme.primary }]}
            title={detail ? i18n.t('UPDATE') : i18n.t('SAVE')}
          />
        </IM.View>
      </IM.View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 10,
  },
})
