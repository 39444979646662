export type WeekDayRepetition = {
  key: 'SUNDAY' | 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY'
  value: boolean
}

export interface RepetitionValidation {
  valid: boolean
  error: string
}

export enum ERepetitionCode {
  NONE = 0,
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
  YEARLY = 4,
}

export enum EMonthTime {
  START = 0,
  END = 1,
}

export type RepetitionBase = {
  repetitioncode: ERepetitionCode
  startdate: Date | undefined
  enddate: Date | undefined
  interval: number
  monthtime: EMonthTime | null
  numyears: number
}

export type Repetition = RepetitionBase & { weekdays: WeekDayRepetition[] }
export type TaskRepetition = RepetitionBase & { weekdays: boolean[] }
