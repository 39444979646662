import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome'
import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { LayoutChangeEvent, Platform, Pressable, PressableProps, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import LoadingIcon from './LoadingIcon'
import Text from './Text'

type Props = {
  icon: IconProp
  size?: number
  color?: string
  shadow?: boolean
  style?: StyleProp<ViewStyle>
  pressableRatio?: number
  pressableStyle?: StyleProp<ViewStyle>
  opacityColor?: string
  disabledColor?: string
  iconStyle?: FontAwesomeIconStyle
  badgeLoading?: boolean
  badgeNumber?: number
  badgeColor?: string
  onViewLayout?: (event: LayoutChangeEvent) => void
}

export type PressableIconProps = PressableProps & Props

const PressableIcon = memo(function PressableIcon({
  icon,
  size = 30,
  color,
  shadow,
  style,
  pressableStyle,
  pressableRatio = 0.5,
  iconStyle,
  opacityColor,
  disabledColor,
  badgeLoading,
  badgeNumber,
  badgeColor,
  onViewLayout,
  ...pressableProps
}: PressableIconProps) {
  const { theme } = useTheme()

  return (
    <IM.View style={[shadow && IMLayout.shadow, style]} onLayout={onViewLayout}>
      <Pressable
        // hitSlop={
        //   pressableProps.hitSlop ?? {
        //     bottom: 18,
        //     top: 18,
        //   }
        //}
        // @ts-ignore hovered works on web
        style={({ pressed, hovered }) => [
          styles.pressable,
          {
            padding: 18 - size * pressableRatio,
            backgroundColor:
              (Platform.OS === 'web' ? hovered : pressed) && !pressableProps.android_ripple
                ? (opacityColor ?? theme.button.pressedOpacity)
                : 'transparent',
          },
          pressableStyle,
        ]}
        {...pressableProps}>
        <IM.View>
          <IM.Icon
            size={size}
            icon={icon}
            color={pressableProps.disabled ? (disabledColor ?? theme.pressable.disabled) : (color ?? theme.text)}
            iconStyle={iconStyle}
          />
          {badgeNumber !== undefined && (
            <IM.View
              style={[
                styles.badge,
                IMLayout.shadow,
                { backgroundColor: badgeColor ?? IMStyle.palette.tint },
                pressableProps.disabled && { backgroundColor: disabledColor ?? theme.pressable.disabled },
              ]}
              pointerEvents="none">
              {badgeLoading ? (
                <LoadingIcon size={10} color={IMStyle.palette.white} />
              ) : (
                <>
                  <Text style={styles.badgeText}>{badgeNumber}</Text>
                </>
              )}
            </IM.View>
          )}
        </IM.View>
      </Pressable>
    </IM.View>
  )
})

export default PressableIcon

const styles = StyleSheet.create({
  badge: {
    top: -11,
    right: -12,
    borderRadius: IMLayout.iconRadius,
    minWidth: 20,
    height: 20,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  badgeText: {
    color: 'white',
    fontSize: 11,
    fontWeight: '700',
    width: '100%',
    textAlign: 'center',
    paddingHorizontal: 1,
  },
  pressable: {
    borderRadius: IMLayout.iconRadius,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
