import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Keyboard, Pressable, StyleSheet } from 'react-native'

import type { ERepetitionCode } from '../types/RepetitionTypes'

export default function SchedulerButton({
  title,
  code,
  selected,
  onChange,
  readOnly,
}: {
  title: string
  code: ERepetitionCode
  selected: boolean
  onChange: (value: ERepetitionCode) => void
  readOnly?: boolean
}) {
  const { theme } = useTheme()

  return (
    <Pressable
      style={[selected && { backgroundColor: theme.primary }, styles.button]}
      disabled={readOnly}
      onPress={() => {
        Keyboard.dismiss()
        onChange(code)
      }}>
      <IM.Text style={[selected && styles.selected]}>{title}</IM.Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  button: {
    padding: 10,
  },
  selected: {
    color: 'white',
  },
})
