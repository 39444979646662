import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'

import type { Department } from '../types'

export default function DepartmentCard({ item, onPress }: { item: Department; onPress: (item: Department) => void }) {
  const { themeUtils } = useTheme()

  return (
    <IM.Card
      spacing={'bottom'}
      style={{ marginHorizontal: IMStyle.layout.horizontalMargin }}
      head={{
        icon: ['fal', 'people-group'],
        backGroundColor: themeUtils.getRandomColorFromTheme(item.name),
      }}
      defaultContent={{
        texts: [{ text: item.name, primary: true }],
      }}
      onPress={() => onPress(item)}
    />
  )
}
