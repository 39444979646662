import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import Text from '../Text'
import { MediaViewAssetProps } from './MediaViewAsset'

export type FilePreviewProps = {
  icon?: IconProp
} & Pick<MediaViewAssetProps, 'asset' | 'backgroundColor' | 'size' | 'showLoadingSpinner'>

const MAX_ICON_SIZE = 36
const MAX_FONTSIZE = 14
const MAX_BOTTOM_MARGIN_TEXT = 10
export default function FilePreview({ backgroundColor, size, showLoadingSpinner, asset, icon = ['fal', 'file'] }: FilePreviewProps) {
  const { theme } = useTheme()
  return (
    <>
      <IM.View
        style={[
          styles.center,
          {
            width: size,
            height: size,
            backgroundColor: backgroundColor ?? theme.background,
            borderRadius: IMLayout.borderRadius,
          },
        ]}>
        {!showLoadingSpinner && <IM.Icon icon={icon} size={Math.min(size / 3, MAX_ICON_SIZE)} color={theme.textDetail} />}
      </IM.View>
      <IM.View
        style={[
          styles.filenameView,
          {
            bottom: Math.min(size / 10, MAX_BOTTOM_MARGIN_TEXT),
          },
        ]}>
        <Text
          style={[styles.fileNameText, { color: theme.textDetail, fontSize: Math.min(size / 6, MAX_FONTSIZE) }]}
          numberOfLines={1}
          ellipsizeMode="middle">
          {asset.fileName}
        </Text>
      </IM.View>
    </>
  )
}

const styles = StyleSheet.create({
  fileNameText: {
    overflow: 'hidden',
    flex: 1,
    textAlign: 'center',
  },
  filenameView: {
    position: 'absolute',
    left: 0,
    right: 0,
    paddingHorizontal: 3,
    alignSelf: 'center',
  },
  center: { alignItems: 'center', justifyContent: 'center' },
})
