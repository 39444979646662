import { AssetUtils, LiteAsset, LiteAssetType } from '@infominds/react-native-media-lite'
import { Platform } from 'react-native'
import ReactNativeBlobUtil from 'react-native-blob-util'

import { Media, MediaType, TaskMedia } from '../types'
import { shareUtils } from './ShareUtils'

function convertLiteAssetTypeToMediaType(type: LiteAssetType): MediaType {
  switch (type) {
    case 'photo':
      return 'image'
    case 'video':
      return 'video'
    case 'audio':
      return 'audio'
    default:
      return 'document/pdf'
  }
}

function convertMediaTypeToLiteAssetType(type: MediaType): LiteAssetType {
  switch (type) {
    case 'image':
      return 'photo'
    case 'video':
      return 'video'
    case 'audio':
      return 'audio'
    default:
      return 'file'
  }
}

export const AppMediaUtils = {
  getAssetTypeByFileName(fileName: string | undefined) {
    if (!fileName) return 'file'
    if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg') || fileName.endsWith('.png')) return 'photo'
    if (fileName.endsWith('.mp4')) return 'video'
    if (fileName.endsWith('.mp3')) return 'audio'
    return 'file'
  },
  getSize(asset: LiteAsset) {
    return ReactNativeBlobUtil.fs
      .stat(getProcessedAssetPath(asset))
      .then(stats => {
        return stats.size
      })
      .catch(err => {
        console.error('Failed getSize()', err)
        return 0
      })
  },
  getFileName(asset: LiteAsset) {
    return asset.path?.replace('\\', '/').split('/').pop()
  },
  shareFile(asset: LiteAsset) {
    const data = Platform.OS === 'web' ? asset.base64 : asset.path
    if (!data) {
      throw new Error('No data to share')
    }
    const type = AssetUtils.getAssetMime(asset)
    return shareUtils.file(data, asset.fileName, type)
  },

  createTaskMedia: async (assetsToSend: LiteAsset[]) => {
    if (!assetsToSend?.length) return

    const media: TaskMedia[] = []

    for (const asset of assetsToSend) {
      const newMedia: TaskMedia = {
        filename: asset.fileName,
        type: convertLiteAssetTypeToMediaType(asset.type),
        data: '',
        filesize: 0,
      }

      if (Platform.OS === 'web') {
        newMedia.data = asset.base64 ?? ''
      } else {
        const compressedAsset = await AssetUtils.compressAsset(asset)
          .then(compressionResult => {
            return compressionResult
          })
          .catch(err => {
            console.error('compressAsset() failed:', err, 'taking uncompressed asset')
            return asset
          })
        newMedia.data = await AssetUtils.assetToBase64(compressedAsset)
        newMedia.filesize = Number(await AppMediaUtils.getSize(compressedAsset))
      }
      media.push(newMedia)
    }
    return media
  },
  async convertMediaToLiteAssets(media: Media[] | undefined) {
    return Promise.all(
      media
        ?.filter(q => !!q.data && !!q.filename)
        .map(element =>
          AssetUtils.base64ToAsset(
            element.data ?? '',
            element.id ?? '',
            element.filename ?? '',
            element.type ? convertMediaTypeToLiteAssetType(element.type) : this.getAssetTypeByFileName(element.filename)
          )
        ) ?? []
    )
  },
}

function getProcessedAssetPath(asset: LiteAsset, path?: string) {
  const pathToProcess = path ?? asset.path ?? ''

  if (Platform.OS === 'ios') {
    return pathToProcess.replace('file://', '')
  } else {
    //Android
    let toRet = pathToProcess.replace('content://', 'file:///')

    if (asset.type === 'photo') {
      // Images coming from react-native-camera do not have the complete path
      toRet = 'file://' + toRet
    }

    return toRet
  }
}
