import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { useToast } from 'react-native-toast-notifications'

import api from '../../../apis/apiCalls'
import Header from '../../../components/Header'
import InputContainer from '../../../components/InputContainer'
import Label from '../../../components/Label'
import Screen from '../../../components/Screen'
import Styles from '../../../constants/Styles'
import type { PostRole, Role } from '../../../types'

export default function RolesNewScreen() {
  const navigation = useNavigation()
  const toast = useToast()
  const { i18n } = useLanguage()

  const { theme } = useTheme()

  const detail: Role = useRoute()?.params as Role

  const [code, setCode] = useState<string>(detail?.code)
  const [description, setDescription] = useState<string>(detail?.description)
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(detail?.is_Admin === 1) // TODO change to bool from api

  function createNewRole() {
    if (code) {
      const newRole: PostRole = {
        code: code,
        description: description,
        is_Admin: isAdmin ? 1 : 0,
      }

      if (detail) {
        newRole.id = detail.id
        api
          .patchRole(newRole)
          .then(_res => {
            navigation.goBack()
            if (detail) {
              navigation.goBack()
            }
          })
          .catch(console.error)
      } else {
        api
          .postRole(newRole)
          .then(_res => {
            navigation.goBack()
            if (detail) {
              navigation.goBack()
            }
          })
          .catch(console.error)
      }
    } else {
      toast.show(i18n.t('NOT_ALL_VALUES_SET'), { type: 'danger', placement: 'bottom', duration: 4000 })
    }
  }

  return (
    <Screen
      spacing="none"
      screenHeader={
        <Header
          title={detail ? i18n.t('EDIT_ROLE') : i18n.t('NEW_ROLE')}
          onBackPress={() => {
            navigation.goBack()
          }}
        />
      }
      hasRoundCorners>
      <IM.View spacing={'all'} style={Styles.detailContainer}>
        <ScrollView>
          <IM.View spacing={'all'} spacingType={'padding'}>
            {!detail && (
              <>
                <Label>{i18n.t('CODE')}</Label>
                <InputContainer style={[styles.input]}>
                  <IM.Input spacing={'bottom'} value={code} onChangeText={setCode} />
                </InputContainer>
              </>
            )}

            <Label>{i18n.t('DESCRIPTION')}</Label>
            <InputContainer style={[styles.input]}>
              <IM.Input spacing={'bottom'} value={description} onChangeText={setDescription} />
            </InputContainer>

            <Label>{i18n.t('IS_ADMIN')}</Label>
            <InputContainer style={[styles.input]}>
              <IM.Checkbox value={isAdmin} onValueChange={setIsAdmin} />
            </InputContainer>
          </IM.View>
        </ScrollView>

        <IM.View spacing={['horizontal', 'top']}>
          <IM.Button
            onPress={() => {
              createNewRole()
            }}
            style={[Styles.saveButton, { backgroundColor: theme.primary }]}
            title={detail ? i18n.t('UPDATE') : i18n.t('SAVE')}
          />
        </IM.View>
      </IM.View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 10,
  },
})
