import { CardBasicProps, IM } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

export type BadgeBaseProps = {
  backgroundColor?: string
  size?: number
} & CardBasicProps

export default function BadgeBase({ size = 65, backgroundColor, style, children, ...cardProps }: BadgeBaseProps) {
  return (
    <IM.CardBasic style={[{ width: size, height: size }, !!backgroundColor && { backgroundColor }, styles.badge, style]} {...cardProps}>
      {children}
    </IM.CardBasic>
  )
}

const styles = StyleSheet.create({
  badge: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})
