import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'

import api from '../../apis/apiCalls'
import Styles from '../../constants/Styles'
import type { Department } from '../../types'
import InputContainer from '../InputContainer'
import Label from '../Label'
import SelectInput from '../SelectInput'

export function DepartmentSelector({
  values,
  value,
  onChange,
  onChangeValue,
  readonly,
  isParent = false,
}: {
  value?: Department
  values?: Department[]
  onChange?: (value: Department[]) => void
  onChangeValue?: (value: Department) => void
  readonly?: boolean
  isParent?: boolean
}) {
  const [departments, loadDepartments] = useApi(api.getDepartments, [])
  const { i18n } = useLanguage()

  useEffect(() => {
    loadDepartments().catch(console.error)
  }, [])

  return (
    <>
      {isParent ? <Label>{i18n.t('PARENT_DEPARTMENT')}</Label> : <Label>{i18n.t('DEPARTMENT')}</Label>}
      <InputContainer style={Styles.selectorContainer}>
        {values ? (
          <SelectInput
            readonly={readonly}
            icon={['fal', 'people-group']}
            data={departments}
            values={values}
            onChangeValues={onChange}
            title={i18n.t('SELECT_DEPARTMENTS')}
            renderString={(dep: Department) => dep.name}
            keyExtractor={(dep: Department) => `${dep.id}_${dep.name}`}
          />
        ) : (
          <SelectInput
            readonly={readonly}
            icon={['fal', 'people-group']}
            data={departments}
            value={value}
            onChangeValue={onChangeValue}
            title={i18n.t('SELECT_DEPARTMENTS')}
            renderString={(dep: Department) => dep.name}
            keyExtractor={(dep: Department) => `${dep.id}_${dep.name}`}
          />
        )}
      </InputContainer>
    </>
  )
}
