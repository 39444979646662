import { IM, IMStyle } from '@infominds/react-native-components'
import React from 'react'

import type { Task } from '../../types'
import DateUtils from '../../utils/DateUtils'
import TaskHead from './TaskHeadSytles'

export default function TaskHistoryCard({ item, onPress }: { item: Task; onPress: () => void }) {
  return (
    <IM.Card
      spacing={'bottom'}
      style={{ marginHorizontal: IMStyle.layout.horizontalMargin }}
      head={TaskHead(item.priority)}
      defaultContent={{
        texts: [
          { text: item.title, primary: true, style: { marginBottom: 10 } },
          !!item.description && { text: item.description, secondary: true, style: { marginBottom: 1 } },

          !!item.creationDate && {
            text: `${item.creationCollaborator?.name ?? 'ADMIN'} - ${DateUtils.formatDate(new Date(item.creationDate), 'dd/MM/yyyy HH:mm')}`,
            secondary: true,
            icon: ['fal', 'file-circle-plus'],
          },

          !!item.modifiedDate && {
            text: `${item.modifiedCollaborator?.name ?? 'ADMIN'} - ${DateUtils.formatDate(new Date(item.modifiedDate), 'dd/MM/yyyy HH:mm')}`,
            secondary: true,
            icon: ['fal', 'edit'],
          },

          !!item.closedDate &&
            !!item.closedCollaborator && {
              text: `${item.closedCollaborator.name} - ${DateUtils.formatDate(new Date(item.closedDate), 'dd/MM/yyyy HH:mm')}`,
              secondary: true,
              icon: ['fal', 'circle-check'],
            },
        ],
      }}
      selector={item.taskTemplate?.repetition ? { icon: ['fal', 'repeat'] } : undefined}
      onPress={onPress}
    />
  )
}
