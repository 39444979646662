import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IMStyle, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import { FlatList } from 'react-native'

import MenuCard from '../cards/MenuCard'
import Styles from '../constants/Styles'

export type MenuItem = {
  name: string
  icon: IconProp | undefined
  screen: string
}

export default function ManagementMenu() {
  const navigation = useNavigation()
  const { i18n } = useLanguage()

  const Menu: MenuItem[] = useMemo(
    () => [
      { name: i18n.t('USERS'), icon: ['fal', 'user'], screen: 'Users' },
      { name: i18n.t('COLLABORATORS'), icon: ['fal', 'helmet-safety'], screen: 'Collaborators' },
      { name: i18n.t('ROLES'), icon: ['fal', 'user-group-crown'], screen: 'Roles' },
      { name: i18n.t('DEPARTMENTS'), icon: ['fal', 'people-group'], screen: 'Departments' },
    ],
    [i18n]
  )

  function renderItem({ item }: { item: MenuItem }) {
    //@ts-ignore item.screen is used dynamically here
    return <MenuCard item={item} onPress={() => navigation.navigate(item.screen)} />
  }

  return (
    <FlatList
      style={[
        Styles.flatListBorderRadius,
        {
          padding: IMStyle.layout.horizontalMargin * 2,
        },
      ]}
      data={Menu}
      keyExtractor={item => item.name}
      renderItem={renderItem}
    />
  )
}
