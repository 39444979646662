import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useToast } from 'react-native-toast-notifications'

import api from '../../../apis/apiCalls'
import Header from '../../../components/Header'
import InputContainer from '../../../components/InputContainer'
import Label from '../../../components/Label'
import Screen from '../../../components/Screen'
import { DepartmentSelector } from '../../../components/Selectors/DepartmentSelector'
import { UserSelector } from '../../../components/Selectors/UserSelector'
import Styles from '../../../constants/Styles'
import type { Collaborator, Department, PostCollaborator, User } from '../../../types'

export default function CollaboratorsNewScreen() {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const toast = useToast()

  const detail: Collaborator | undefined = useRoute()?.params as Collaborator

  const [code, setCode] = useState<string>(detail?.code)
  const [name, setName] = useState<string>(detail?.name)
  const [note, setNote] = useState<string>(detail?.note ?? '')
  const [user, setUser] = useState<User | undefined>(detail?.user)
  const [departments, setDepartments] = useState<Department[]>(detail?.departments || [])

  function createNewCollaborator() {
    if (code && name && user && user.id && departments) {
      const newCollaborator: PostCollaborator = {
        code: code,
        name: name,
        note: note ? note : undefined,
        userId: user.id,
        departmentsID: departments.map(item => item.id),
      }

      let call = api.postCollaborator
      if (detail) {
        newCollaborator.id = detail.id
        call = api.patchCollaborator
      }

      call(newCollaborator)
        .then(_res => {
          navigation.goBack()
          if (detail) {
            navigation.goBack()
          }
        })
        .catch(error => {
          toast.show(i18n.t('ERROR_COLLABORATOR_CREATION'), {
            type: 'danger',
            placement: 'bottom',
            duration: 4000,
          })
          console.error(error)
        })
    } else {
      toast.show(i18n.t('NOT_ALL_VALUES_SET'), { type: 'danger', placement: 'bottom', duration: 4000 })
    }
  }

  return (
    <Screen
      spacing="none"
      screenHeader={
        <Header
          title={detail ? i18n.t('EDIT_COLLABORATOR') : i18n.t('NEW_COLLABORATOR')}
          onBackPress={() => {
            navigation.goBack()
          }}
        />
      }
      hasRoundCorners>
      <IM.View spacing={'all'} style={styles.container}>
        <ScrollView>
          <IM.View spacing={'all'} spacingType={'padding'}>
            {!detail && (
              <>
                <Label>{i18n.t('CODE')}</Label>
                <InputContainer style={[styles.input]}>
                  <IM.Input spacing={'bottom'} value={code} onChangeText={setCode} />
                </InputContainer>
              </>
            )}

            <Label>{i18n.t('NAME')}</Label>
            <InputContainer style={[styles.input]}>
              <IM.Input spacing={'bottom'} value={name} onChangeText={setName} />
            </InputContainer>

            <Label>{i18n.t('NOTE')}</Label>
            <InputContainer style={[styles.input]}>
              <IM.Input spacing={'bottom'} value={note} onChangeText={setNote} />
            </InputContainer>

            <InputContainer style={[styles.input]}>
              <UserSelector readonly={false} value={user} onChange={setUser} />
            </InputContainer>

            <View style={[styles.input]}>
              <DepartmentSelector readonly={false} values={departments} onChange={setDepartments} />
            </View>
          </IM.View>
        </ScrollView>

        <IM.View spacing={['horizontal', 'top']}>
          <IM.Button
            onPress={() => {
              createNewCollaborator()
            }}
            style={[Styles.saveButton, { backgroundColor: theme.primary }]}
            title={detail ? i18n.t('UPDATE') : i18n.t('SAVE')}
          />
        </IM.View>
      </IM.View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 10,
  },
  container: { flex: 1, justifyContent: 'space-between', marginTop: 10 },
})
