import { IM, IMStyle, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { LiteAsset } from '@infominds/react-native-media-lite'
import { useNavigation, useRoute } from '@react-navigation/core'
import React, { useEffect, useMemo, useState } from 'react'
import { Keyboard, Platform, ScrollView, StyleSheet, View } from 'react-native'
import { useToast } from 'react-native-toast-notifications'

import api from '../../apis/apiCalls'
import Header from '../../components/Header'
import InputContainer from '../../components/InputContainer'
import InputDateTime from '../../components/InputDateTime'
import Label from '../../components/Label'
import MediaButtons from '../../components/media/MediaButtons'
import MediaSkeleton from '../../components/media/MediaSkeleton'
import MediaView from '../../components/media/MediaView'
import ModifyRepetitionPopup from '../../components/Repetition/ModifyRepetitionPopup'
import RepetitionInput from '../../components/Repetition/RepetitionInput'
import { ERepetitionCode, RepetitionValidation, type TaskRepetition } from '../../components/Repetition/types/RepetitionTypes'
import { RepetitionUtils } from '../../components/Repetition/utils/RepetitionUtils'
import Screen from '../../components/Screen'
import { CollaboratorSelector } from '../../components/Selectors/CollaboratorSelector'
import { DepartmentSelector } from '../../components/Selectors/DepartmentSelector'
import PrioritySelector, { EPriority } from '../../components/Selectors/PrioritySelector'
import Styles from '../../constants/Styles'
import Toast from '../../constants/Taost'
import DisplayAssetModal from '../../modals/media/DisplayAssetModal'
import type { Collaborator, CreateTask, Department, PatchTask, Task, TaskMedia } from '../../types'
import { AppMediaUtils } from '../../utils/AppMediaUtils'

export default function TaskNewScreen() {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const toast = useToast()
  const alert = useAlert()
  const navigation = useNavigation()

  const modifyController = useModalController()

  const detail: Task | undefined = useRoute()?.params as Task
  const showAssetModal = useModalController<LiteAsset>()

  const [loading, setLoading] = useState<boolean>(false)
  const [deletingMedia, setDeletingMedia] = useState<string[]>([])
  const [name, setName] = useState<string>(detail?.title)
  const [room, setRoom] = useState<string>(detail?.room)
  const [description, setDescription] = useState<string>(detail?.description)
  const [deliveryDate, setDeliveryDate] = useState<Date | undefined>(undefined)
  const [collaborators, setCollaborators] = useState<Collaborator[]>(detail?.collaborators || [])
  const [selectedDepartments, setSelectedDepartments] = useState<Department[]>(detail?.departments || [])
  const [priority, setPriority] = useState<EPriority>(detail?.priority || EPriority.NORMAL)
  const [newAssets, setNewAssets] = useState<LiteAsset[]>([])
  const [assets, setAssets] = useState<LiteAsset[]>([])
  const [mediaLoading, setMediaLoading] = useState<boolean>(false)
  const [repetition, setRepetition] = useState<TaskRepetition>(detail?.taskTemplate?.repetition)
  const assetsToShow = useMemo(() => [...newAssets, ...assets], [assets, newAssets])

  async function loadMedias() {
    if (!detail) return
    const apiMedia = await api.getMedias(detail.id)
    const convertedMedia = await AppMediaUtils.convertMediaToLiteAssets(apiMedia)
    setAssets(convertedMedia)
  }

  useEffect(() => {
    if (!detail) return
    setMediaLoading(true)
    loadMedias()
      .catch(console.error)
      .finally(() => setMediaLoading(false))
    setPriority(detail.priority)
    if (!detail.deliveryDate) return
    setDeliveryDate(new Date(detail.deliveryDate))
  }, [detail])

  function onPriorityPress(value: EPriority) {
    Keyboard.dismiss()
    setPriority(value)
  }

  const editMode: boolean = useMemo(() => detail !== undefined, [detail])
  const showDeliveryDateInput: boolean = useMemo(
    () => editMode || (repetition && repetition.repetitioncode === ERepetitionCode.NONE),
    [editMode, repetition]
  )
  const hasRepetition: boolean = useMemo(() => detail?.taskTemplate !== null && detail?.taskTemplate.repetition !== null, [detail])
  const showRepetitionInfo: boolean = useMemo(
    () => (editMode && repetition !== null && repetition !== undefined && hasRepetition) || !editMode,
    [editMode, detail]
  )

  async function createTask(modifyAllTasks?: boolean) {
    setLoading(true)
    let medias: TaskMedia[] | undefined = []
    try {
      medias = await AppMediaUtils.createTaskMedia(newAssets)
    } catch (error) {
      console.error('createTaskMedia failed:', error)
      toast.show(i18n.t('ERROR_MEDIA_UPLOAD'), Toast.error)
    }
    const repetitionCheck: RepetitionValidation = repetition ? RepetitionUtils.validateRepetition(repetition, i18n) : { valid: true, error: '' }

    if (name && repetitionCheck.valid) {
      //edit task
      if (detail) {
        const patchTask: PatchTask = {
          collaboratorsIds: collaborators.map(item => item.id),
          //taskTemplateId: '',
          title: name,
          description: description,
          departmentsIds: selectedDepartments?.map(item => item.id),
          priority: priority,
          // note: description,
          deliveryDate: deliveryDate,
          // closedDate: undefined,
          year: new Date().getFullYear(),
          room: room,
          repetition: repetition,
          id: detail.id,
          blocked: detail.blocked,
          NotDeleteMedia: false,
          NewMedias: [] as TaskMedia[],
        }
        // @ts-ignore ignore
        if (medias && medias.length > 0) {
          patchTask.NewMedias = medias
        } else {
          patchTask.NotDeleteMedia = true
        }

        const patchRequest = modifyAllTasks
          ? api.patchTaskTemplate({
              id: detail.taskTemplate.id,
              repetition: repetition,
              taskPatch: patchTask,
            })
          : api.patchTasks(patchTask)

        patchRequest
          .then(_res => {
            navigation.goBack()
            setLoading(false)
          })
          .catch(error => {
            toast.show(i18n.t('ERROR_SENDING_REQUEST'), Toast.error)
            console.error(error)
            setLoading(false)
          })
      }
      // create new task
      else {
        const newTask: CreateTask = {
          collaboratorsIds: collaborators.map(item => item.id),
          medias: medias,
          //taskTemplateId: '',
          title: name,
          description: description,
          departmentsIds: selectedDepartments?.map(item => item.id),
          priority: priority,
          // note: description,
          deliveryDate: deliveryDate,
          // closedDate: undefined,
          year: new Date().getFullYear(),
          room: room,
          repetition: repetition,
        }

        if (collaborators.length > 0 || selectedDepartments.length > 0) {
          api
            .postTask(newTask)
            .then(_res => {
              navigation.goBack()
              setLoading(false)
            })
            .catch(error => {
              toast.show(i18n.t('ERROR_SENDING_REQUEST'), Toast.error)
              console.error(error)
              setLoading(false)
            })
        } else {
          toast.show(i18n.t('MUST_SELECT_COLLABORATOR_DEPARTMENT'), Toast.error)
          setLoading(false)
        }
      }
    } else {
      toast.show(`${i18n.t('NOT_ALL_VALUES_SET')}${!repetitionCheck.valid ? '\n' + repetitionCheck.error : ''}`, Toast.error)
      console.error('not all values set', repetitionCheck.error)
      setLoading(false)
    }
  }

  function handleAssetPressed(asset: LiteAsset) {
    if (deletingMedia.includes(asset.id)) return
    if (asset.type === 'file') {
      if (newAssets.find(a => a.id === asset.id)) return
      AppMediaUtils.shareFile(asset).catch(console.error)
    } else {
      showAssetModal.show(asset)
    }
  }

  function handleDeleteAsset(assetsToDelete: LiteAsset[]) {
    const newAssetsToDelete = assetsToDelete.filter(a => newAssets.some(na => na.id === a.id))
    const backEndAssetsToDelete = assetsToDelete.filter(a => assets.some(na => na.id === a.id))

    alert.alert(i18n.t('DELETE_MEDIA'), i18n.t('DELETE_MEDIA_ALERT'), [
      {
        text: i18n.t('REMOVE'),
        onPress: () => deleteMedia(),
        style: 'destructive',
      },
      {
        text: i18n.t('CANCEL'),
        style: 'cancel',
      },
    ])

    function deleteMedia() {
      if (newAssetsToDelete.length) {
        setNewAssets(prev => prev.filter(q => !newAssetsToDelete.some(a => a.id === q.id)))
      }
      if (backEndAssetsToDelete.length) {
        setDeletingMedia(prev => [...prev, ...assetsToDelete.map(q => q.id)])

        Promise.allSettled(assetsToDelete.map(m => (m ? api.deleteMedias({ id: m.id }) : Promise.resolve())))
          .then(() => {
            setAssets(prev => prev.filter(prevAssets => !assetsToDelete.some(assetToDelete => assetToDelete.id === prevAssets.id)))
          })
          .catch(error => {
            toast.show(i18n.t('ERROR_MEDIA_DELETE'), Toast.error)
            console.error(error)
          })
          .finally(() => {
            setDeletingMedia(prev =>
              prev.filter(prevDeletingAssets => !assetsToDelete.some(assetToDelete => assetToDelete.id === prevDeletingAssets))
            )
          })
      }
    }
  }

  return (
    <Screen
      spacing="none"
      screenHeader={
        <Header
          title={detail ? i18n.t('EDIT_TASK') : i18n.t('NEW_TASK')}
          onBackPress={() => {
            navigation.goBack()
          }}
        />
      }
      hasRoundCorners>
      <IM.View spacing={'none'} style={Styles.detailContainer}>
        <ScrollView keyboardShouldPersistTaps="handled" style={{ padding: IMStyle.layout.horizontalMargin * 2 }}>
          <IM.View style={styles.inputFields}>
            <Label>{i18n.t('TITLE')}</Label>
            <View style={Platform.OS !== 'web' && IMStyle.layout.shadow}>
              <IM.Input spacing={'bottom'} value={name} onChangeText={setName} />
            </View>

            {/* TODO  multiline */}
            <Label>{i18n.t('DESCRIPTION')}</Label>
            <InputContainer>
              <IM.Input multiline spacing={'bottom'} value={description} onChangeText={setDescription} style={styles.description} />
            </InputContainer>

            <Label>{i18n.t('PRIORITY')}</Label>
            <PrioritySelector value={priority} onPress={onPriorityPress} />

            {
              // only show repetitionInfo on create and editmode if it has repetition
              showRepetitionInfo && <RepetitionInput value={repetition} onChange={setRepetition} />
            }

            {
              // delivery date not needed when creating repetition, edit is okay tho
              showDeliveryDateInput && (
                <>
                  <Label>{i18n.t('DELIVERY_DATE')}</Label>
                  <InputContainer style={styles.deliveryDate}>
                    <InputDateTime
                      mode="datetime"
                      value={deliveryDate}
                      onConfirm={date => setDeliveryDate(date)}
                      onDelete={() => setDeliveryDate(undefined)}
                    />
                  </InputContainer>
                </>
              )
            }

            <Label>{i18n.t('ROOM')}</Label>
            <InputContainer>
              <IM.Input spacing={'bottom'} value={room} onChangeText={setRoom} />
            </InputContainer>

            <DepartmentSelector values={selectedDepartments} onChange={setSelectedDepartments} />

            {/* TODO only get collaborators of department */}
            <CollaboratorSelector values={collaborators} onChange={setCollaborators} />

            <IM.View spacing={'vertical'}>
              <Label>{i18n.t('MEDIA')}</Label>

              <MediaView
                spacing={'top'}
                assets={assetsToShow}
                onAssetPressed={handleAssetPressed}
                align={'left'}
                loading={mediaLoading}
                loadingSkeleton={<MediaSkeleton mediaCount={assetsToShow.length > 0 ? assetsToShow.length : editMode ? 1 : 0} align={'left'} />}
                editMode={true}
                onDeleteAsset={handleDeleteAsset}
                assetsBeingDeleted={deletingMedia}
                mediaBackgroundColor={theme.card.background}
              />
              <MediaButtons round assets={newAssets} setAssets={setNewAssets} />
            </IM.View>
          </IM.View>
        </ScrollView>

        <IM.View spacing={['horizontal', 'top']}>
          <IM.Button
            onPress={() => {
              if (editMode) {
                modifyController.show()
              } else {
                createTask().catch(console.error)
              }
            }}
            style={[Styles.saveButton, { backgroundColor: theme.primary }]}
            title={detail ? i18n.t('UPDATE') : i18n.t('SAVE')}
            disabled={loading}
          />
        </IM.View>
      </IM.View>
      <IM.LoadingSpinnerModal isVisible={loading} />
      {/* Popup for edit handling of a task */}
      <ModifyRepetitionPopup
        controller={modifyController}
        handleSubmit={(modifyAllTasks?: boolean) => {
          createTask(modifyAllTasks).catch(console.error)
        }}
        message={i18n.t('MODIFY_TASK')}
        isRepetition={hasRepetition}
        onCancel={() => setLoading(false)}
      />

      <DisplayAssetModal
        assets={assetsToShow}
        controller={showAssetModal}
        onDeleteAsset={assetsToDelete => handleDeleteAsset(assetsToDelete)}
        allowAssetDeletion={true}
      />
    </Screen>
  )
}
const styles = StyleSheet.create({
  inputFields: { marginBottom: 20 },
  description: { paddingTop: 12 },
  deliveryDate: { marginBottom: 10 },
})
