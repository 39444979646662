import { baseUrl, LicenseGlobals } from '../constants/Api'
import LoginStorage from '../utils/LoginStorage'

interface LoginRequest {
  username: string
  password: string
  language?: string
  code?: string
}

type Response = {
  token: string
  expiration: Date
}

type ErrorResponse = {
  Message: string
  Code: number
}

export async function loginApi(username: string, password: string, langStr?: string) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  // TODO const request: LoginRequest = { username: username, password: base64.encode(password) }
  const request: LoginRequest = { username: username, password: password }
  if (langStr !== undefined) request.language = langStr

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(request),
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new Promise<any>((resolve, reject) => {
    console.debug(baseUrl + '/api/authenticateApps/login/apps')
    fetch(baseUrl + '/api/authenticateApps/login/apps', requestOptions)
      .then(response => {
        if (response.status === 200 || response.status === 401) {
          return response.json()
        } else {
          console.error(response)
          throw new Error(response.statusText)
        }
      })
      .then((result: Response | ErrorResponse) => {
        if (IsErrorResponse(result)) {
          throw new Error(`${result.Code} - ${result.Message}`)
        } else {
          resolve(`Bearer ${result.token}`)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const reauthenticate = async (langStr?: string): Promise<string | undefined> => {
  return new Promise<string | undefined>((resolve, reject) => {
    LoginStorage.load()
      .then(storageResult => {
        if (storageResult != null) {
          loginApi(storageResult.username, storageResult.password, langStr)
            .then(loginResult => {
              LicenseGlobals.token = loginResult as string
              resolve(loginResult as string)
            })
            .catch(err => {
              console.error(err)
              reject(err)
            })
        } else {
          throw new Error('No saved Username or Password found!')
        }
      })
      .catch((error: unknown) => {
        reject(error)
        console.error(error)
      })
  })
}
export async function updateToken(langStr: string) {
  return reauthenticate(langStr)
}

function IsErrorResponse(response: Response | ErrorResponse): response is ErrorResponse {
  if ((response as ErrorResponse).Message) {
    return true
  }

  return false
}
