import { IM, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useContext, useEffect, useState } from 'react'

import api from '../../apis/apiCalls'
import Header from '../../components/Header'
import NewButton from '../../components/NewButton/NewButton'
import Screen from '../../components/Screen'
import { UserContext } from '../../contexts/UserContext'
import FilterModal, { Filter } from '../../modals/FilterModal'
import FilterUtils from '../../utils/FilterUtils'
import TasksList, { TaskListItem } from '../../views/TasksList'

export default function TasksScreen() {
  const navigation = useNavigation()
  const { i18n } = useLanguage()

  const { user } = useContext(UserContext)

  const [data, setData] = useState<TaskListItem[]>([])
  const [filteredData, setFilteredData] = useState<TaskListItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [filterModalVisible, setFilterModalVisible] = useState<boolean>(false)
  const [filterOptions, setFilterOptions] = useState<Filter>({
    departments: [],
    collaborators: [],
    dateFrom: undefined,
  })
  const [filter, setFilter] = useState<Filter>({
    departments: [],
    collaborators: [],
    dateFrom: undefined,
  })

  async function load() {
    if (user?.id) {
      setLoading(true)
      return api
        .getHomeTasks({
          userId: user?.id,
          departmentsIds:
            user?.collaborator && user?.collaborator?.departments && user?.collaborator?.departments?.length > 0
              ? user?.collaborator?.departments?.map(item => item.id)
              : [],
        })
        .then(res => {
          const result: TaskListItem[] = []
          if (res?.myTasks?.length > 0) {
            result.push({ title: i18n.t('MY_TASKS'), data: res.myTasks })
          }
          if (res?.departmentTasks?.length > 0) {
            result.push({ title: i18n.t('DEPARTMENT'), data: res.departmentTasks })
          }
          if (res?.otherCollaboratorsTasks?.length > 0) {
            result.push({ title: i18n.t('OTHER_COLLABORATORS'), data: res.otherCollaboratorsTasks })
          }
          if (res?.allTasks?.length > 0) {
            result.push({ title: i18n.t('ALL_TASKS'), data: res.allTasks })
          }

          setFilterOptions(
            FilterUtils.findFilterOptions([
              ...(res.allTasks ?? []),
              ...(res.departmentTasks ?? []),
              ...(res.otherCollaboratorsTasks ?? []),
              ...(res.allTasks ?? []),
            ])
          )

          setData([...result])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    setFilteredData(FilterUtils.filterData(filter, data))
  }, [data, filter])

  useEffect(() => {
    load().catch(console.error)
  }, [])

  useEffect(() => {
    const focusHandler = navigation.addListener('focus', () => {
      load().catch(console.error)
    })
    return focusHandler
  }, [navigation])

  return (
    <Screen
      spacing="none"
      screenHeader={
        <Header
          onRefreshPress={() => {
            setLoading(true)
            load()
              .catch(console.error)
              .finally(() => {
                setLoading(false)
              })
          }}
          onFilterPress={() => {
            setFilterModalVisible(true)
          }}
        />
      }
      hasRoundCorners>
      <TasksList
        data={filteredData}
        onPress={item => navigation.navigate('DetailTask', { detail: item })}
        onRefresh={() => {
          load().catch(console.error)
        }}
        isLoading={loading}
      />
      <NewButton visible onPress={() => navigation.navigate('NewTask')} />
      <IM.LoadingSpinnerModal isVisible={loading} />
      <FilterModal
        visible={filterModalVisible}
        onClose={() => setFilterModalVisible(false)}
        filter={filter}
        isHistory={false}
        filterOptions={filterOptions}
        onFilterChange={filters => {
          setFilter({ ...filters })
        }}
      />
    </Screen>
  )
}
