import { useApi } from '@infominds/react-api'
import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { FlatList, RefreshControl } from 'react-native'

import api from '../../../apis/apiCalls'
import UserCard from '../../../cards/UserCard'
import Header from '../../../components/Header'
import NewButton from '../../../components/NewButton/NewButton'
import Screen from '../../../components/Screen'
import Styles from '../../../constants/Styles'
import type { User } from '../../../types'

export default function UsersScreen() {
  const navigation = useNavigation()
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  const [users, loadUsers, loadingUsers] = useApi(api.getUsers, [])

  useEffect(() => {
    const focusHandler = navigation.addListener('focus', () => {
      loadUsers().catch(console.error)
    })
    return focusHandler
  }, [navigation])

  function renderItem({ item }: { item: User }) {
    return <UserCard item={item} onPress={() => navigation.navigate('UsersDetail', item)} />
  }

  return (
    <Screen spacing="none" screenHeader={<Header onBackPress={() => navigation.goBack()} title={i18n.t('USERS')} />} hasRoundCorners>
      <IM.View style={IMLayout.flex.f1} spacing="horizontal">
        <FlatList
          style={[
            Styles.flatListBorderRadius,
            {
              paddingTop: IMStyle.layout.horizontalMargin * 2,
            },
            Styles.fullHeight,
          ]}
          data={users}
          renderItem={renderItem}
          refreshing={loadingUsers}
          refreshControl={
            <RefreshControl
              colors={[theme.primary]}
              refreshing={loadingUsers}
              onRefresh={() => {
                loadUsers().catch(console.error)
              }}
            />
          }
        />
      </IM.View>

      <NewButton visible onPress={() => navigation.navigate('UsersNew')} />
    </Screen>
  )
}
