import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'

import type { Role } from '../types'

export default function RoleCard({ item, onPress }: { item: Role; onPress: (item: Role) => void }) {
  const { themeUtils } = useTheme()

  return (
    <IM.Card
      spacing={'bottom'}
      style={{ marginHorizontal: IMStyle.layout.horizontalMargin }}
      head={{
        icon: ['fal', 'user-group-crown'],
        backGroundColor: themeUtils.getRandomColorFromTheme(item?.code ?? ''),
      }}
      defaultContent={{
        texts: [{ text: item?.code, primary: true }],
      }}
      onPress={() => onPress(item)}
    />
  )
}
