import { DateTimePicker, IM, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Keyboard, Platform, Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import DateUtils from '../utils/DateUtils'
import DateTimePickerWeb from './DateTimePicker'

export default function InputDateTime(props: {
  onConfirm?: (date: Date) => void
  onDelete?: () => void
  mode: 'time' | 'date' | 'datetime'
  text?: string
  disabled?: boolean
  value?: Date
  style?: StyleProp<ViewStyle>
  dontShowYear?: boolean
}) {
  const { theme } = useTheme()
  const [isTimePickerVisible, setTimePickerVisible] = useState(false)
  const [date, setDate] = useState<string>('')

  useEffect(() => {
    if (props.value === undefined) {
      setDate('')
    } else if (props.value && !isNaN(new Date(props?.value).getTime())) {
      handleTimeConfirm(props.value)
    } else {
      setDate('')
    }
  }, [props.value])

  const convertDateFromInputFormat = (dateToFormat: string) => {
    const [d, m, y] = dateToFormat.split(/[T :\-/.,]/g)
    return new Date(`${y}-${m}-${d}`)
  }

  function handleTimeConfirm(selectedDate: Date) {
    const noYearFormatString = 'dd/MM' // day and month
    const dateFormatString = 'dd/MM/yyyy' // date
    const timeFormatString = 'HH:mm' //time

    let formatString = ''

    switch (props.mode) {
      case 'date':
        formatString = props.dontShowYear ? noYearFormatString : dateFormatString
        break
      case 'time':
        formatString = timeFormatString
        break
      case 'datetime':
        formatString = dateFormatString + ' ' + timeFormatString
        break
    }
    const dateString = DateUtils.formatDate(selectedDate, formatString)

    setDate(dateString)
    setTimePickerVisible(false)
  }

  return (
    <View>
      <Pressable
        onPress={() => {
          Keyboard.dismiss()
          !props.disabled && setTimePickerVisible(true)
        }}
        style={[
          !props.disabled && {
            backgroundColor: theme.input.background,
            borderColor: theme.input.border,
          },
          !props.disabled && styles.button,
          props.style,
        ]}>
        {!date ? (
          <IM.Text style={[{ color: props.text ? theme.text : theme.textPlaceholder }, styles.margin0]}>{props.text ?? ''}</IM.Text>
        ) : (
          <IM.Text style={[{ color: theme.text }, styles.margin0]}>
            {props?.text} {date}
          </IM.Text>
        )}

        {date ? (
          <Pressable
            onPress={() => {
              setDate('')
              props.onDelete?.()
            }}
            style={styles.XIcon}>
            <IM.Icon icon={['fal', 'x']} size={15} color={theme.text} />
          </Pressable>
        ) : (
          <>
            {!props.disabled && (
              <IM.Icon style={styles.TimeIcon} icon={props.mode === 'time' ? ['fal', 'clock'] : ['fal', 'calendar']} size={22} color={theme.text} />
            )}
          </>
        )}
      </Pressable>

      {Platform.OS === 'web' ? (
        <>
          <DateTimePickerWeb
            date={date?.length === 10 ? convertDateFromInputFormat(date) : new Date()}
            setDate={(newDate: Date) => {
              handleTimeConfirm(newDate)
              props.onConfirm && props.onConfirm(newDate)
            }}
            show={isTimePickerVisible}
            setShow={setTimePickerVisible}
            mode={'date'}
          />
        </>
      ) : (
        <DateTimePicker
          mode={props.mode}
          style={{}}
          isVisible={isTimePickerVisible}
          onCancel={() => setTimePickerVisible(false)}
          onConfirm={(newDate: Date) => {
            handleTimeConfirm(newDate)
            props.onConfirm && props.onConfirm(newDate)
          }}
          close={() => setTimePickerVisible(false)}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    padding: 12,
    borderWidth: 1,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  XIcon: { padding: 5, marginLeft: 0, margin: -10, alignSelf: 'center' },
  margin0: { margin: 0 },
  TimeIcon: {
    marginLeft: 8,
    marginVertical: Platform.OS === 'ios' ? -5 : 0,
  },
})
