import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'

type Props = {
  title: string
  startValue?: boolean
  onSetDay: () => void
  style?: StyleProp<ViewStyle>
  disabled?: boolean
}

export default function WeekDayButtons(props: Props) {
  const { theme } = useTheme()

  const [isSelected, setIsSelected] = useState<boolean>(props.startValue ?? false)

  return (
    <IM.View
      style={[
        styles.main,
        styles.weekday,
        {
          backgroundColor: isSelected ? theme.primary : theme.card.background,
        },
        IMLayout.shadow,
        props.style,
      ]}>
      <Pressable
        style={[styles.press, styles.main]}
        onPress={() => {
          setIsSelected(!isSelected)
          props.onSetDay()
        }}
        disabled={props.disabled}>
        <IM.Text
          style={[
            styles.text,
            {
              color: isSelected ? theme.card.background : theme.text,
            },
          ]}>
          {props.title.charAt(0)}
        </IM.Text>
      </Pressable>
    </IM.View>
  )
}
const styles = StyleSheet.create({
  main: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  press: {
    width: '100%',
    height: '100%',
  },
  weekday: { width: 30, height: 30, borderRadius: 30 },
  text: { fontSize: 15 },
})
