import { IM, useLanguage } from '@infominds/react-native-components'
import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useContext, useEffect, useState } from 'react'
import { ScrollView } from 'react-native'

import api from '../../../apis/apiCalls'
import DeleteAlert from '../../../components/DeleteAlert'
import DetailText from '../../../components/DetailText'
import FormSeparator from '../../../components/FormSeparator'
import Header from '../../../components/Header'
import Label from '../../../components/Label'
import Screen from '../../../components/Screen'
import { CollaboratorSelector } from '../../../components/Selectors/CollaboratorSelector'
import { DepartmentSelector } from '../../../components/Selectors/DepartmentSelector'
import Styles from '../../../constants/Styles'
import { UserContext } from '../../../contexts/UserContext'
import type { Department } from '../../../types'

export default function DepartmentsDetailScreen() {
  const navigation = useNavigation()
  const { i18n } = useLanguage()

  const { user } = useContext(UserContext)

  const department: Department | undefined = useRoute()?.params as Department

  const [parentDepartment, setParentDepartment] = useState<Department>()

  function deleteDepartment() {
    DeleteAlert({
      onPress: () => {
        api
          .deleteDepartment({ id: department?.id ?? '' })
          .then(() => navigation.goBack())
          .catch(console.error)
      },
      i18n,
    })
  }

  useEffect(() => {
    if (department && department.parentDepartmentId) {
      api
        .getDepartment(department.parentDepartmentId)
        .then(res => {
          if (res && res.length > 0) setParentDepartment(res[0])
        })
        .catch(console.error)
    }
  }, [])

  return (
    <Screen
      spacing="none"
      screenHeader={
        <Header
          title={i18n.t('DEPARTMENT_DETAIL')}
          onBackPress={() => {
            navigation.goBack()
          }}
          showEdit={user?.role?.is_Admin === 1}
          onEditPress={() => {
            navigation.navigate('DepartmentsNew', department)
          }}
          onDeletePress={() => deleteDepartment()}
        />
      }
      hasRoundCorners>
      <IM.View spacing={'all'} style={Styles.detailContainer}>
        <ScrollView>
          <IM.View spacing={'all'} spacingType={'padding'}>
            <Label>{i18n.t('NAME')}</Label>
            <DetailText>{department.name}</DetailText>

            <FormSeparator />

            <Label>{i18n.t('NOTE')}</Label>
            <DetailText>{department?.note ?? ''}</DetailText>

            <FormSeparator />

            <CollaboratorSelector readonly values={department?.collaborators} />

            <DepartmentSelector isParent readonly value={parentDepartment} />
          </IM.View>
        </ScrollView>
      </IM.View>
    </Screen>
  )
}
