import { IM, IMLayout, useLanguage, useTheme, ViewProps } from '@infominds/react-native-components'
import { LiteAsset } from '@infominds/react-native-media-lite'
import React, { ReactNode, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import { CONSTANTS } from '../../constants/Constants'
import useMediaView from '../../hooks/media/useMediaView'
import { ForceLayoutType } from '../../hooks/useLayout'
import { LoadingType } from '../../types'
import Pressable from '../Pressable'
import MediaViewAsset from './MediaViewAsset'

type MediaDisplayMode = 'compact' | 'full'
export type MediaViewImageSizeInfo = { size: number; displayCount: number; itemsPerRow: number; lastSmall?: boolean }

export type MediaViewProps = {
  assets: LiteAsset[]
  onAssetPressed: (asset: LiteAsset) => void
  forceLayout?: ForceLayoutType
  align?: 'left' | 'right'
  imageSizeSmallDevice?: number
  imageSizeLargeDevice?: number
  imageSpacing?: number
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  loading: LoadingType | boolean
  loadingSkeleton?: ReactNode
  editMode?: boolean
  onDeleteAsset?: (assetsToDelete: LiteAsset[]) => void
  assetsBeingDeleted: string[]
  showLoadingSpinner?: boolean
  mediaBackgroundColor?: string
  alwaysShowFull?: boolean
} & Omit<ViewProps, 'align'>

export default function MediaView({
  assets,
  onAssetPressed,
  style,
  forceLayout,
  align = 'left',
  onLayout: externalOnLayout,
  loading,
  loadingSkeleton,
  editMode,
  onDeleteAsset,
  assetsBeingDeleted,
  showLoadingSpinner,
  mediaBackgroundColor,
  alwaysShowFull,
  ...viewProps
}: MediaViewProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()
  const [displayMode, setDisplayMode] = useState<MediaDisplayMode>(alwaysShowFull ? 'full' : 'compact')
  const { imageSize, rows, layout, onLayout } = useMediaView({ mediaCount: assets.length, displayMode, forceLayout })

  const isLoading = loading === 'reloading' || loading === true || !layout

  useEffect(() => {
    if (displayMode !== 'full' || !imageSize || alwaysShowFull) return
    if (assets.length <= imageSize.itemsPerRow) setDisplayMode('compact')
  }, [assets, imageSize])

  return (
    <IM.View style={style}>
      <IM.View
        style={[IMLayout.flex.row]}
        onLayout={e => {
          onLayout(e)
          externalOnLayout?.(e)
        }}
        {...viewProps}>
        {isLoading && layout && loadingSkeleton}

        {!isLoading && !!imageSize && (
          <>
            {align === 'right' && <IM.View style={IMLayout.flex.f1} />}
            <IM.View>
              {!!rows &&
                Array(rows)
                  .fill(0)
                  .map((_, i) => i)
                  .map(row => (
                    <IM.View key={`MediaViewRow${row}`} style={[IMLayout.flex.row]}>
                      {align === 'right' && <IM.View style={IMLayout.flex.f1} />}

                      {assets.slice(row * imageSize.itemsPerRow, row * imageSize.itemsPerRow + imageSize.itemsPerRow).map((asset, index) => (
                        <React.Fragment key={asset.id}>
                          {index < imageSize.displayCount && (
                            <IM.View style={index > 0 && { marginLeft: CONSTANTS.imageSpacing }}>
                              <MediaViewAsset
                                asset={asset}
                                onAssetPressed={onAssetPressed}
                                onDelete={editMode && onDeleteAsset && (() => onDeleteAsset([asset]))}
                                isBeingDeleted={assetsBeingDeleted.includes(asset.id)}
                                size={
                                  imageSize.lastSmall && imageSize.displayCount - 1 === index
                                    ? imageSize.size * 0.5 - CONSTANTS.imageSpacing / 2
                                    : imageSize.size
                                }
                                style={{ marginBottom: CONSTANTS.imageSpacing }}
                                showLoadingSpinner={showLoadingSpinner}
                                backgroundColor={mediaBackgroundColor}
                              />
                              {imageSize.displayCount !== assets.length &&
                                imageSize.displayCount - 1 === index &&
                                row * imageSize.itemsPerRow + index + 1 < assets.length && (
                                  <MediaViewAsset
                                    asset={assets[row * imageSize.itemsPerRow + index + 1]}
                                    onAssetPressed={() => setDisplayMode('full')}
                                    size={imageSize.size * 0.5 - CONSTANTS.imageSpacing / 2}
                                    blurred
                                    overlayText={`+${assets.length - imageSize.displayCount}`}
                                    showLoadingSpinner={showLoadingSpinner}
                                    backgroundColor={mediaBackgroundColor}
                                  />
                                )}
                            </IM.View>
                          )}
                        </React.Fragment>
                      ))}
                    </IM.View>
                  ))}
            </IM.View>
          </>
        )}
      </IM.View>
      {displayMode === 'full' && !alwaysShowFull && (
        <Pressable style={[IMLayout.flex.row, styles.showLessPressable]} onPress={() => setDisplayMode('compact')}>
          <IM.Text secondary>{i18n.t('SHOW_LESS')}</IM.Text>
          <IM.Icon icon={['fal', 'chevron-up']} color={theme.textDetail} size={16} />
        </Pressable>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  showLessPressable: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: IMLayout.horizontalMargin,
  },
})
