/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useContext, useEffect, useState } from 'react'
import { Image, StyleSheet } from 'react-native'

import api from '../apis/apiCalls'
import { loginApi } from '../apis/useLoginApi'
import { ApiTokenContext } from '../App'
import Screen from '../components/Screen'
import { LicenseGlobals } from '../constants/Api'
import { Layout } from '../constants/layout'
import { UserContext } from '../contexts/UserContext'
import useKeyPress from '../hooks/useKeyPress'
import type { User } from '../types'
import LoginStorage from '../utils/LoginStorage'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('./../assets/img/app_icon_round.png')

export default function LoginScreen() {
  const lowDeviceHeight = Layout.window.height < 650
  const len = Layout.isSmallDevice || lowDeviceHeight ? 133 : 200

  const { setToken } = useContext(ApiTokenContext)
  const { i18n } = useLanguage()

  const [loading, setLoading] = useState(true)

  const navigation = useNavigation()
  const { theme } = useTheme()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const { setUser, setIsAdmin } = useContext(UserContext)

  const [error, setError] = useState(false)

  useKeyPress(
    {
      key: ['Enter'],
      cb: () => loginPress(),
    },
    []
  )

  useEffect(() => {
    LoginStorage.load()
      .then(result => {
        if (result != null) loginPress(result?.username, result?.password)
        else setLoading(false)
      })
      .catch(console.error)
  }, [])

  function loginPress(user?: string, pass?: string) {
    setLoading(true)
    loginApi(user ?? username, pass ?? password)
      .then(async result => {
        LicenseGlobals.token = result
        setToken(result as string)
        LoginStorage.save(user ?? username, pass ?? password).catch(console.error)

        const tmpUsername = user ?? username
        const userInfoArray = await api.getUsersByName(tmpUsername)
        let userInfo: User | undefined
        if (userInfoArray.length > 0) userInfo = userInfoArray[0]

        setUser(userInfo)

        //reset values after successful login
        setError(false)
        setUsername('')
        setPassword('')

        setIsAdmin(userInfo ? userInfo?.role?.is_Admin === 1 : false)
        navigation.navigate('Root')
      })
      .catch(err => {
        console.error(err)
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (loading) return <IM.LoadingSpinner isVisible={loading} />
  return (
    <Screen>
      <IM.View spacing={'all'} style={styles.container}>
        <IM.View spacing={'all'} style={styles.innerContainer}>
          <IM.View style={styles.ImageContainer}>
            <Image style={[{ width: len, height: len }, lowDeviceHeight && styles.imageLowHeight]} source={logo} />
          </IM.View>
          <IM.View spacing={'all'} style={styles.InputContainer}>
            <IM.Input
              value={username}
              placeholder={i18n.t('USERNAME')}
              underlineColorAndroid="transparent"
              onChangeText={setUsername}
              error={error}
              autoCapitalize="none"
              onSubmitEditing={() => loginPress()}
            />
          </IM.View>
          <IM.View spacing={'all'} style={styles.InputContainer}>
            <IM.Input
              secureTextEntry={true}
              value={password}
              placeholder={i18n.t('PASSWORD')}
              underlineColorAndroid="transparent"
              onChangeText={setPassword}
              error={error}
              autoCapitalize="none"
              onSubmitEditing={() => loginPress()}
            />
          </IM.View>

          {error && (
            <IM.View spacing={'all'}>
              <IM.View spacing={'all'} style={[styles.loginError, { backgroundColor: theme.error }]}>
                <IM.Text>{i18n.t('LOGIN_ERROR')}</IM.Text>
              </IM.View>
            </IM.View>
          )}

          <IM.View spacing={'all'} style={styles.InputContainer}>
            <IM.Button style={styles.loginButton} title={i18n.t('LOGIN')} onPress={() => loginPress()} />
          </IM.View>
        </IM.View>
      </IM.View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  imageLowHeight: {
    marginTop: -35,
    marginBottom: 25,
  },
  InputContainer: {
    width: '100%',
  },
  ImageContainer: {
    marginBottom: 35,
  },
  container: { height: '100%', justifyContent: 'center' },
  innerContainer: { width: IMStyle.layout.isLargeDevice ? '80%' : 300, alignSelf: 'center', alignItems: 'center' },
  loginError: { borderRadius: 10, justifyContent: 'center' },
  loginButton: { marginTop: 20 },
})
