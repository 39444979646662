import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { TextInput as DefaultTextInput, Platform, Pressable, StyleSheet } from 'react-native'

type Props = {
  containerStyle?: StyleProp<ViewStyle>
  iconColor?: string
  iconSize?: number
  textEditOnPress?: () => void
  postFixText?: string
  onInputPress?: () => void
  disabled?: boolean
  inputRef?: React.LegacyRef<DefaultTextInput>
}

export type InputProps = Props & DefaultTextInput['props']

export default function IntervalInput(props: InputProps) {
  const { theme } = useTheme()
  const { style, containerStyle, textEditOnPress, value, postFixText, onInputPress, editable = true, disabled, inputRef, ...otherProps } = props

  return (
    <IM.View style={[containerStyle]}>
      <Pressable
        onPress={onInputPress}
        disabled={disabled}
        style={[
          styles.button,
          {
            backgroundColor: theme.input.background,
            borderColor: theme.input.border,
          },
        ]}>
        <IM.View style={[IMLayout.flex.row]}>
          <IM.View spacing={['all']} style={styles.justifyCenter}>
            <FontAwesomeIcon size={20} color={theme.textPlaceholder} icon={['fal', 'repeat']} />
          </IM.View>
          <IM.View style={[styles.separator, { backgroundColor: theme.textPlaceholder }]} />
        </IM.View>

        {(!!editable || Platform.OS !== 'ios') && (
          // Hide textInput if !enabled for ios and show a simple text, because TextInput interferes with the <Pressable>
          <DefaultTextInput
            ref={inputRef}
            style={[styles.textInput, Platform.select({ web: styles.textInputWeb }), { color: theme.text }, style]}
            placeholderTextColor={theme.textPlaceholder}
            value={value}
            editable={editable && !disabled}
            inputMode="numeric"
            {...otherProps}
          />
        )}
        {!editable && Platform.OS === 'ios' && (
          <IM.Text style={[styles.textInput, Platform.select({ web: styles.textInputWeb }), { color: theme.text }, styles.textUneditable, style]}>
            {value}
          </IM.Text>
        )}

        {value === '' && (
          <Pressable style={styles.rightComponent} onPress={textEditOnPress} disabled={disabled}>
            <IM.View spacing={['all']} style={styles.justifyCenter}>
              <FontAwesomeIcon size={20} color={theme.textPlaceholder} icon={['fal', 'x']} />
            </IM.View>
          </Pressable>
        )}
        {postFixText && (
          <IM.View style={styles.textField}>
            <IM.Text style={[styles.postFixText, { color: theme.textPlaceholder }]}>{postFixText}</IM.Text>
          </IM.View>
        )}
      </Pressable>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  button: {
    borderWidth: 1,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  separator: {
    width: 1,
    borderRadius: 10,
  },
  textInput: {
    paddingHorizontal: 10,
    paddingVertical: 12,
    flex: 1,
    fontSize: 14,
  },
  textUneditable: {
    paddingVertical: 14,
  },
  textInputWeb: {
    //@ts-ignore only for web
    outlineStyle: 'none', //web
  },
  rightComponent: {
    justifyContent: 'center',
  },
  postFixText: {
    alignSelf: 'center',
    marginLeft: 7,
    fontSize: 14,
  },
  textField: {
    justifyContent: 'center',
    paddingRight: '5%',
  },
  justifyCenter: { justifyContent: 'center' },
})
