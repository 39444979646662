import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome'
import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { GestureResponderEvent, StyleProp, StyleSheet, TextStyle, TouchableOpacity, ViewStyle } from 'react-native'

type Props = {
  icon: IconProp
  size?: number
  color?: string
  style?: StyleProp<ViewStyle>
  iconStyle?: FontAwesomeIconStyle
  leftText?: string
  rightText?: string
  spacing?: number
  textStyle?: StyleProp<TextStyle>
  onPress: ((event: GestureResponderEvent) => void) | undefined
}

export type PressableTextIconProps = TouchableOpacity['props'] & Props

export default function PressableTextIcon({
  icon,
  size = 30,
  color,
  style,
  iconStyle,
  leftText,
  rightText,
  spacing = 5,
  onPress,
  textStyle,
  ...optherProps
}: PressableTextIconProps) {
  const { theme } = useTheme()

  return (
    <IM.View>
      <TouchableOpacity onPress={onPress} style={[styles.container, style]} {...optherProps}>
        <IM.View style={[styles.details]}>
          {leftText && <IM.Text style={[{ marginRight: spacing }, textStyle]}>{leftText}</IM.Text>}
          <IM.Icon size={size} icon={icon} color={color ?? theme.text} iconStyle={iconStyle} />
          {rightText && <IM.Text style={[{ marginLeft: spacing }, textStyle]}>{rightText}</IM.Text>}
        </IM.View>
      </TouchableOpacity>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { alignSelf: 'flex-start' },
  details: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
})
