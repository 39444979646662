import type { ToastOptions } from 'react-native-toast-notifications/lib/typescript/toast'

const error: ToastOptions = {
  type: 'danger',
  placement: 'bottom',
  duration: 4000,
}

export default {
  error: error,
}
