import { IM, IMStyle, ThemeSelectorRef, useLanguage, useTheme } from '@infominds/react-native-components'
import { useNotification } from '@infominds/react-native-messaging'
import { useNavigation } from '@react-navigation/native'
import React, { createRef, useContext, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'
import DeviceInfo from 'react-native-device-info'

import en from '../assets/languages/resources/en.json'
import DropdownArrowDown from '../components/DropdownArrowDown'
import DropdownArrowUp from '../components/DropdownArrowUp'
import DropdownCheck from '../components/DropdownCheck'
import LanguageSelector, { LanguageSelectorRef } from '../components/LanguageSelector'
import SettingsRow from '../components/SettingsRow'
import SettingsRowContainer from '../components/SettingsRowContainer'
import { CONSTANTS } from '../constants/Constants'
import { UserContext } from '../contexts/UserContext'
import NotificationOption from '../notifications/NotificationOption'

export default function SettingsView() {
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const navigation = useNavigation()

  const { user } = useContext(UserContext)
  const { notificationEnabledApi } = useNotification()

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)
  const [themeDropdownOpen, setThemeDropdownOpen] = useState(false)
  const themeRef = createRef<ThemeSelectorRef>()
  const languageRef = createRef<LanguageSelectorRef>()

  const languageZIndex = languageDropdownOpen ? 2 : 0
  const themeZIndex = themeDropdownOpen ? 2 : 0

  function resetApp() {
    navigation.reset({
      routes: [
        {
          name: 'Root',
          state: {
            routes: [{ name: 'Settings', state: { routes: [{ name: 'SettingsScreen' }] } }],
          },
        },
      ],
    })

    Platform.OS === 'web' && window.location.reload() // Force backend token revalidation
  }

  return (
    <IM.View spacing="all" style={{ backgroundColor: theme.background, marginHorizontal: IMStyle.layout.horizontalMargin }}>
      <SettingsRowContainer title={i18n.t('APP')} spacing="vertical" style={styles.zIndex1}>
        <SettingsRow title={i18n.t('SETTINGS_THEME', { defaultValue: en.SETTINGS_THEME })} style={{ zIndex: themeZIndex }}>
          <IM.ThemeSelector
            ref={themeRef}
            onOpen={() => {
              setLanguageDropdownOpen(false)
              setThemeDropdownOpen(true)
            }}
            TickIconComponent={DropdownCheck}
            ArrowDownIconComponent={DropdownArrowDown}
            ArrowUpIconComponent={DropdownArrowUp}
          />
        </SettingsRow>

        <SettingsRow title={i18n.t('SETTINGS_LANGUAGE', { defaultValue: en.SETTINGS_LANGUAGE })} style={{ zIndex: languageZIndex }}>
          <LanguageSelector
            ref={languageRef}
            onOpen={() => {
              setThemeDropdownOpen(false)
              setLanguageDropdownOpen(true)
            }}
            onReLogIn={resetApp}
          />
        </SettingsRow>

        {notificationEnabledApi && (
          <SettingsRow title={i18n.t('SETTINGS_NOTIFICATIONS', { defaultValue: en.SETTINGS_NOTIFICATIONS })} style={{ zIndex: languageZIndex }}>
            <NotificationOption />
          </SettingsRow>
        )}
      </SettingsRowContainer>

      <SettingsRowContainer title={i18n.t('INFO')} spacing="vertical" style={styles.zIndex0}>
        <SettingsRow title={i18n.t('SETTINGS_VERSION', { defaultValue: en.SETTINGS_VERSION })} compact style={styles.zIndex0}>
          <IM.Text secondary selectable style={styles.text}>
            {Platform.OS === 'web' ? CONSTANTS.appName : DeviceInfo.getApplicationName()} v
            {Platform.OS === 'web' ? process.env.VERSION : DeviceInfo.getVersion()}
            {(Platform.OS === 'android' || Platform.OS === 'ios') && ` (${DeviceInfo.getBuildNumber()})`}
          </IM.Text>
        </SettingsRow>
        <SettingsRow title={i18n.t('USERNAME', { defaultValue: en.USERNAME })} compact style={styles.zIndex0}>
          <IM.Text secondary selectable style={styles.text}>
            {user?.username}
          </IM.Text>
        </SettingsRow>
      </SettingsRowContainer>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  text: { flex: 1, textAlign: 'right', justifyContent: 'flex-end', zIndex: 0 },
  zIndex1: { zIndex: 1 },
  zIndex0: { zIndex: 0 },
})
