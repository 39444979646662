import { IM, useTheme } from '@infominds/react-native-components'
import { useNotification } from '@infominds/react-native-messaging'
import React from 'react'

export default function NotificationOption() {
  const { notificationEnabledApi, notificationSwitchEnabled, isLoading, setAppNotification } = useNotification()
  const { theme } = useTheme()
  return (
    <IM.View>
      {notificationEnabledApi &&
        (!isLoading ? (
          <IM.Switch onColor={theme.primary} value={notificationSwitchEnabled} onValueChange={setAppNotification} />
        ) : (
          <IM.View>
            <IM.LoadingSpinner isVisible size="small" />
          </IM.View>
        ))}
    </IM.View>
  )
}
