import { Colors } from '@infominds/react-native-components'

import { EPriority } from '../../components/Selectors/PrioritySelector'

export default function (priority: EPriority) {
  let priorityColor = Colors.grey
  switch (priority) {
    case EPriority.HIGH:
      priorityColor = Colors.modern.red
      break
    case EPriority.NORMAL:
      priorityColor = Colors.yellow
      break
    case EPriority.LOW:
      priorityColor = Colors.grey
      break

    default:
      break
  }

  return {
    style: { width: 3, margin: 0, padding: 4 },
    backGroundColor: priorityColor,
  }
}
