const CONSTANTS = {
  appName: 'Smart Todo',
  modalWidth: 550,
  modalWidthWeb: 650,
  imageSizeSmallDevice: 180,
  imageSizeLargeDevice: 160,
  imageSpacing: 4,
}

const APP_CONSTANTS = {
  messagingLicenseId: 'SMARTTODO',
  projectCode: 'APP-SMARTTD',
}

export { APP_CONSTANTS, CONSTANTS }
