import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet, View } from 'react-native'

export default function FormSeparator() {
  const { theme } = useTheme()
  return <View style={[styles.container, { backgroundColor: theme.primary }]} />
}

const styles = StyleSheet.create({
  container: {
    height: 2,
    opacity: 0.2,
    marginVertical: 10,
    marginBottom: 20,
  },
})
