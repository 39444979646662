import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { FlatList, GestureResponderEvent, Pressable, StyleSheet } from 'react-native'

type Props<T> = {
  label?: string
  value?: T
  values?: T[]
  onChangeValues?: (items: T[]) => void
  onChangeValue?: (item: T) => void
  renderString: (item: T) => string
  labelMinWidth?: number
  spacing?: SpacingProps
  rightIcon?: IconProp
  rightIconColor?: string
  rightOnPress?: (event: GestureResponderEvent) => void
  error?: boolean
  errorMessag?: string
  info?: boolean
  infoMessage?: string
  onPressIn?: () => void
  readonly?: boolean
  keyExtractor: (item: T) => string
}

export type InputProps<T> = Props<T>

export function removeItemOnce<T>(renderString: (item: T) => string, arr?: T[], value?: T) {
  if (arr === undefined || value === undefined) return []
  const index = arr.map(renderString).indexOf(renderString(value))
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}

export default function MultiSelectInput<T>(props: InputProps<T>) {
  const { theme } = useTheme()
  const { values, value, onChangeValues, onChangeValue, spacing, label, error, info, onPressIn, renderString } = props

  const borderColor = error ? theme.error : info ? theme.info : theme.input.border

  return (
    <IM.View
      style={[
        !props.readonly ? styles.input : styles.readonly,
        !props.readonly && {
          backgroundColor: theme.input.background,
          borderColor: borderColor,
        },
        styles.minHeight,
      ]}
      spacing={spacing}
      spacingType="margin">
      <Pressable onPress={onPressIn} style={[IMLayout.flex.f1, styles.button]}>
        <>
          {label && (
            <>
              <IM.Text style={[styles.marginRight, { color: theme.textPlaceholder }, !!props.labelMinWidth && { minWidth: props.labelMinWidth }]}>
                {label}
              </IM.Text>
              <IM.View style={[styles.marginRight, styles.separator, { backgroundColor: theme.textPlaceholder }]} />
            </>
          )}

          {values && (
            <FlatList
              keyboardShouldPersistTaps="always"
              horizontal
              data={values}
              contentContainerStyle={styles.flatListContainer}
              renderItem={({ item }) => (
                <Pressable
                  key={props.keyExtractor(item)}
                  onPress={() => {
                    if (onChangeValues) onChangeValues([...removeItemOnce(renderString, values, item)])
                    else if (onChangeValue) onChangeValue(item)
                  }}>
                  <IM.View style={[styles.inputContainer, { backgroundColor: theme.secondary, borderColor: theme.primary }]}>
                    <IM.View style={styles.inputWrapper}>
                      <IM.Text style={styles.text}>{renderString(item)}</IM.Text>

                      {!props.readonly ? (
                        <IM.Icon style={styles.xIcon} size={12} color="#fff" icon={['fal', 'x']} />
                      ) : (
                        <IM.View style={styles.emptyView} />
                      )}
                    </IM.View>
                  </IM.View>
                </Pressable>
              )}
            />
          )}

          {value && (
            <IM.View style={[styles.inputContainer, { backgroundColor: theme.secondary, borderColor: theme.primary }]}>
              <IM.View style={styles.inputWrapper}>
                <IM.Text style={styles.text}>{renderString(value)}</IM.Text>

                {!props.readonly ? <IM.Icon style={styles.xIcon} size={12} color="#fff" icon={['fal', 'x']} /> : <IM.View style={styles.emptyView} />}
              </IM.View>
            </IM.View>
          )}
        </>
      </Pressable>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: IMStyle.layout.borderRadius,
    //padding: 8,
    flexDirection: 'row',
    //@ts-ignore only for web
    outlineStyle: 'none', //web
  },
  marginRight: {
    marginRight: 10,
  },
  separator: {
    width: 1,
    borderRadius: 10,
  },
  readonly: { marginHorizontal: 10, color: '#fff' },
  minHeight: {
    minHeight: 43,
  },
  button: {
    padding: 12,
  },
  flatListContainer: {
    flexDirection: 'row',
    //flexWrap: 'wrap',
  },
  inputContainer: {
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
    marginRight: 5,
    flexDirection: 'row',
  },
  inputWrapper: { padding: 3, paddingLeft: 6, flexDirection: 'row' },
  text: { color: '#fff', paddingLeft: 4 },
  xIcon: { alignSelf: 'center', marginLeft: 10 },
  emptyView: { width: 8 },
})
