import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'

import type { Collaborator } from '../types'

export default function CollaboratorCard({ item, onPress }: { item: Collaborator; onPress: (item: Collaborator) => void }) {
  const { themeUtils } = useTheme()

  // const departments = item.department.map(item => item.name).join(' ')

  return (
    <IM.Card
      spacing={'bottom'}
      style={{ marginHorizontal: IMStyle.layout.horizontalMargin }}
      head={{
        icon: ['fal', 'helmet-safety'],
        backGroundColor: themeUtils.getRandomColorFromTheme(item.name),
      }}
      defaultContent={{
        texts: [{ text: item.name, primary: true }, !!item.user && { text: item.user?.username, secondary: true, icon: ['fal', 'user'] }],
      }}
      onPress={() => onPress(item)}
    />
  )
}
