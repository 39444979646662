import { IM, IMLayout, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef } from 'react'
import { Dimensions, StyleSheet } from 'react-native'
import DefaultModal from 'react-native-modal'

type Props = {
  keyboardAvoiding?: boolean
  controller: ModalController
  handleSubmit: (modifyAll?: boolean) => void
  message: string
  buttonAbortText?: string
  buttonModifyAllText?: string
  buttonModifySingleText?: string
  isRepetition: boolean
  onCancel?: () => void
}

export default function ModifyRepetitionPopup({
  message,
  buttonAbortText,
  buttonModifyAllText,
  buttonModifySingleText,
  isRepetition,
  controller,
  handleSubmit,
  onCancel,
  ...otherProps
}: Props) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  const result = useRef<boolean | null>(null)

  useEffect(() => {
    if (controller.isShown) result.current = null
  }, [controller.isShown])

  function onSubmit(modifyAll?: boolean) {
    controller.close()
    result.current = !!modifyAll
  }

  // use this detour to make sure modal is closed when handleSubmit function is called. Otherwise LoadingSpinnerModals in ios may not show
  function onModalHide() {
    if (result.current !== null) handleSubmit(result.current)
  }

  return (
    <DefaultModal
      onSwipeComplete={controller.close}
      backdropOpacity={0.8}
      swipeDirection={['down']}
      style={[styles.view]}
      isVisible={controller.isShown}
      deviceHeight={Dimensions.get('screen').height}
      deviceWidth={Dimensions.get('screen').width}
      statusBarTranslucent
      onModalHide={onModalHide}
      {...otherProps}>
      <IM.View style={[styles.main, { backgroundColor: theme.background }]}>
        <IM.View spacing={['bottom']} style={[styles.header, IMLayout.flex.row]}>
          <IM.Text style={[styles.text, IMLayout.flex.f1]}>{message}</IM.Text>
        </IM.View>

        <IM.View style={[IMLayout.flex.row, styles.buttonView]}>
          <IM.Button
            type="info"
            title={buttonAbortText ?? i18n.t('CANCEL')}
            style={styles.button}
            onPress={() => {
              onCancel?.()
              controller.close()
            }}
          />
          <IM.Button
            type="danger"
            title={buttonModifyAllText ?? (isRepetition ? i18n.t('ALL_REPETITION_ELEMENTS') : i18n.t('CONFIRM'))}
            style={[styles.button, { backgroundColor: theme.primary }]}
            onPress={() => onSubmit(isRepetition)}
          />
          {isRepetition && (
            <IM.Button
              type="danger"
              title={buttonModifySingleText ?? i18n.t('ONLY_THIS_ELEMENT')}
              style={[styles.button, { backgroundColor: theme.primary }]}
              onPress={() => onSubmit()}
            />
          )}
        </IM.View>
      </IM.View>
    </DefaultModal>
  )
}
const styles = StyleSheet.create({
  view: {
    justifyContent: 'center',
  },
  main: {
    borderRadius: IMLayout.borderRadius,
    justifyContent: 'center',
  },

  header: {
    justifyContent: 'space-between',
  },
  text: {
    textAlign: 'center',
    fontSize: 15,
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop: 10,
  },
  buttonView: {
    justifyContent: 'space-between',
    gap: 10,
    paddingHorizontal: 10,
    paddingBottom: 10,
  },
  button: { flex: 1, justifyContent: 'center' },
})
