import { IM, IMLayout, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import type { ThemeColorExpanded } from '../types'

type Props = {
  title: string
  style?: StyleProp<ViewStyle>
  spacing?: SpacingProps
}

export default function SettingsRowContainer({ title, style, spacing, children }: PropsWithChildren<Props>) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View style={style} spacing={spacing}>
      <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin }}>
        <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{title.toUpperCase()}</IM.Text>
      </IM.View>
      <IM.View style={[styles.radius, { backgroundColor: theme.card.background }]} spacing="all">
        {children}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  radius: {
    borderRadius: 20,
  },
})
