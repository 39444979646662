import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate'
import { faBed } from '@fortawesome/pro-light-svg-icons/faBed'
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons/faCalendarDays'
import { faCamera } from '@fortawesome/pro-light-svg-icons/faCamera'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck'
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons/faCircleNotch'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit'
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile'
import { faFileCirclePlus } from '@fortawesome/pro-light-svg-icons/faFileCirclePlus'
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter'
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear'
import { faHardHat } from '@fortawesome/pro-light-svg-icons/faHardHat'
import { faHatWizard } from '@fortawesome/pro-light-svg-icons/faHatWizard'
import { faHelmetSafety } from '@fortawesome/pro-light-svg-icons/faHelmetSafety'
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome'
import { faPeopleGroup } from '@fortawesome/pro-light-svg-icons/faPeopleGroup'
import { faPersonToDoor } from '@fortawesome/pro-light-svg-icons/faPersonToDoor'
import { faPhotoFilmMusic } from '@fortawesome/pro-light-svg-icons/faPhotoFilmMusic'
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faRepeat } from '@fortawesome/pro-light-svg-icons/faRepeat'
import { faToolbox } from '@fortawesome/pro-light-svg-icons/faToolbox'
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faUserGroupCrown } from '@fortawesome/pro-light-svg-icons/faUserGroupCrown'
import { faVolume } from '@fortawesome/pro-light-svg-icons/faVolume'
import { faX } from '@fortawesome/pro-light-svg-icons/faX'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'

library.add(
  faPhotoFilmMusic,
  faHelmetSafety,
  faUser,
  faPeopleGroup,
  faHatWizard,
  faUserGroupCrown,
  faBed,
  faCheck,
  faCalendarDays,
  faEdit,
  faFileCirclePlus,
  faRepeat,
  faCircleCheck,
  faChevronDown,
  faChevronUp,
  faArrowLeft,
  faArrowsRotate,
  faFilter,
  faPersonToDoor,
  faTrash,
  faCalendar,
  faClock,
  faX,
  faPlus,
  faGear,
  faHardHat,
  faHome,
  faToolbox,
  faCamera,
  faVolume,
  faPlay,
  faFile,
  faCircleNotch
)
