import { DefaultTheme, InfomindsColors, ThemeType } from '@infominds/react-native-components'

import type { ThemeColorExpanded } from '../types'
import ColorUtils from '../utils/ColorUtils'
import { DefaultColors } from './Colors'

const dark = {
  primary: '#1E1E1E',
  secondary: '#252526',
  tertiary: '#333333',
}

export const Colors: ThemeType<ThemeColorExpanded> = {
  // tint: FamiliamusColors.primary,
  // grey: '#313B48',
  // white: '#FFFFFF',
  // red: '#FF6262',
  // yellow: '#FDCF57',
  // black: InfomindsColors.dark,
  // neutral: InfomindsColors.darker,
  light: {
    ...DefaultTheme.light,
    primary: DefaultColors.primary,
    secondary: DefaultColors.secondary,
    tertiary: DefaultColors.secondary,
    error: '#FF6262',
    info: '#FDCF57',
    icon: '#7C7C7D',
    text: InfomindsColors.text,
    textDetail: InfomindsColors.textDetail,
    textPlaceholder: '#6D776E',
    background: '#F5F5F5',
    input: {
      background: '#F6F6F6',
      border: '#E8E8E8',
    },
    button: {
      tertiaryButton: '#6D776E20',
      pressedOpacity: '#6D776E20',
    },
    card: {
      background: '#FFFFFF',
      secondaryBackground: '#FFFFFF',
      headBackground: '#333B47',
      highlight: DefaultColors.primary,
      selector: '#FDCF57',
    },
    chip: {
      background: '#FFFFFF',
      highlightedBackground: DefaultColors.primary,
      text: InfomindsColors.text,
      highlightedText: '#FFFFFF',
    },
    skeleton: {
      background: '#E1E9EE',
      highlight: '#F2F8FC',
    },
    modal: {
      background: '#F5F5F5',
      headBackground: '#333B47',
    },
    switch: {
      trackColor: {
        true: DefaultColors.primary,
        false: '#989898',
      },
    },
    checkbox: {
      tint: DefaultColors.primary,
    },
    tabNavigator: {
      border: '#E8E8E8',
      background: '#fff',
      focused: {
        icon: '#fff',
        background: DefaultColors.primary,
        iconBackground: ColorUtils.brightenColor(DefaultColors.primary, 0.5),
        text: '#fff',
        marker: DefaultColors.primary,
      },
      unFocused: { icon: '#828282' },
    },
    shadow: '#333',
    pressable: { disabled: '#666666' },
    randomColors: ['#0FCEAD', '#FF5858', '#FDCF57', '#579AFD', '#8C57FD', '#FD7557', '#57C1FD', '#5768FD'],
    dropdown: {
      arrow: '#4C4D4D',
    },
  },
  dark: {
    ...DefaultTheme.dark,
    primary: DefaultColors.primary,
    secondary: DefaultColors.secondary,
    tertiary: DefaultColors.secondary,
    error: '#FF6262',
    info: '#FDCF57',
    icon: '#7C7C7D',
    text: '#fff',
    textDetail: InfomindsColors.textDetail,
    textPlaceholder: '#999999',
    background: dark.primary,
    input: {
      background: '#1C1D1F',
      border: InfomindsColors.dark,
    },
    button: {
      tertiaryButton: '#6D776E20',
      pressedOpacity: '#7C7C7D30',
    },
    card: {
      background: dark.secondary,
      secondaryBackground: dark.tertiary,
      headBackground: '#505050',
      highlight: DefaultColors.primary,
      selector: '#FDCF57',
    },
    chip: {
      background: dark.tertiary,
      highlightedBackground: DefaultColors.primary,
      text: '#FFFFFF',
      highlightedText: '#FFFFFF',
    },
    skeleton: {
      background: InfomindsColors.dark,
      highlight: InfomindsColors.darker,
    },
    modal: {
      background: dark.primary,
      headBackground: dark.secondary,
    },
    switch: {
      trackColor: {
        true: DefaultColors.secondary,
        false: dark.tertiary,
      },
    },
    checkbox: {
      tint: DefaultColors.primary,
    },
    tabNavigator: {
      border: '#1C1D1F',
      background: dark.secondary,
      focused: {
        icon: '#fff',
        background: DefaultColors.primary,
        iconBackground: ColorUtils.brightenColor(DefaultColors.primary, 0.5),
        text: '#fff',
        marker: DefaultColors.primary,
      },
      unFocused: { icon: '#828282' },
    },
    shadow: 'transparent',
    pressable: { disabled: '#666666' },
    randomColors: ['#0FCEAD', '#FF5858', '#FDCF57', '#579AFD', '#8C57FD', '#FD7557', '#57C1FD', '#5768FD'],
    dropdown: {
      arrow: '#9CA2AD',
    },
  },
}

export default Colors
