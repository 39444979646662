import { createContext } from 'react'

import type { Tenant } from '../types'

export const TenantContext = createContext<{
  tenant: Tenant | undefined
  setTenant: (tenant: Tenant | undefined) => void
}>({
  tenant: undefined,
  setTenant: (_tenant: Tenant | undefined) => {
    // empty
  },
})
